import config from 'react-global-configuration';

import { Suspense } from 'react'

import { AuthContext } from "../../context/authContext";
import { useTranslation } from 'react-i18next';

import { useState, useContext } from "react";
import axios from 'axios';

import { ButtonMenu, Modal, DefaultLoader, Img } from "../../components"

export default function AssetSmallCard(props) {
  const { accessToken, isAuth } = useContext(AuthContext)
  const { t } = useTranslation();

  const { asset } = props

  // const [imageSrc, setImageSrc] = useState("")

  const [modalShow, setModalShow] = useState(false);
  const [modalText, setModalText] = useState("");
  const [modalOnSubmit, setModalOnSubmit] = useState(() => { });

  function toggleModal(show) {
    setModalShow(show);
  }

  // function getImageSrc() {
  //   if (asset.file)
  //     setImageSrc(asset.file.filename);
  //   // else {
  //   //   const callbackFunction = () => {
  //   //     let url = `${config.get('apiUrl')}/getfile`;
  //   //     let data = {
  //   //       id: asset.id
  //   //     }
  //   //     let headers = {
  //   //       'x-access-token': accessToken,
  //   //       timeout: config.get('apiCallTimeout')
  //   //     }
  //   //     axios.post(url, data, { headers })
  //   //       .then((response) => {
  //   //         setImageSrc(response.data.data);
  //   //       })
  //   //       .catch((error) => {
  //   //         console.error(error);
  //   //         setImageSrc(t("Not Found!"));
  //   //       })
  //   //       .then(function () {
  //   //         // always executed
  //   //       });
  //   //   }

  //   //   isAuth(callbackFunction)
  //   // }
  // }

  function unsubscribe() {
    const callbackFunction = () => {
      let url = `${config.get('apiUrl')}/unsubscribeasset`;
      let data = {
        asset_id: asset.id,
        plan_id: props.plan_id,
        payment_id: asset.payment ? asset.payment.id : null
      }
      let headers = {
        'x-access-token': accessToken,
        timeout: config.get('apiCallTimeout')
      }
      axios.post(url, data, { headers })
        .then((response) => {
          setModalText(response.data.message);
          setModalShow(true);

          setModalOnSubmit(() => () => {
            if (props.onSubscribeAsset)
              props.onSubscribeAsset()
          })
        })
        .catch((error) => {
          setModalText(error + " ");
          setModalShow(true);
        })
        .then(function () {
          // always executed
        });
    }

    isAuth(callbackFunction)
  }

  // useEffect(() => {
  //   getImageSrc();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <Suspense fallback={<DefaultLoader />}>
      <div className={"relative " + (props.className ? props.className : "bg-white p-3 w-auto h-auto") + " rounded-lg flex flex-row"}>
        <Modal
          id="modal"
          text={modalText}
          show={modalShow}
          toggleModal={toggleModal}

          onSubmit={modalOnSubmit}
          onClose={modalOnSubmit}
        />
        {props.hasMenu &&
          <div className='absolute right-2 top-1'>
            <ButtonMenu
              srOnlyTitle={t("Open options")}
              items={[
                {
                  label: t("unsubscribe"),
                  onClick: unsubscribe,
                }
              ]}
            />
          </div>
        }
        <Img
          className={"flex-none object-cover rounded-xl " + (props.imageClassName ? props.imageClassName : " h-16 w-14 2xl:h-24 2xl:w-23 min-w-14")}
          src={`${config.get('cdnUrl')}/${asset && asset.file && asset.file.filename}`}
          alt=""
        />
        <div className='flex flex-col w-full pl-3'>
          <h3 className="text-base 2xl:text-xl font-medium text-gray-900 mb-2">
            {asset.quantity !== undefined ? `${asset.quantity} × ` : ""}{asset.name}
          </h3>
          <div className="flex space-x-2 2xl:space-x-3">
            <p className="text-xs 2xl:text-base text-gray-500 whitespace-normal">
              {asset.description}
            </p>
          </div>
          <div className='text-xs 2xl:text-base text-right mt-auto'>
            {props.showPrice &&
              `${asset.price} € (${t("per month")})`
            }
            {!props.showPrice && props.comment &&
              props.comment
            }
          </div>
        </div>
      </div>
    </Suspense>
  )
}
