import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import { DefaultLoader } from "../../components"

export default function ChangeLanguageButton() {
  const { i18n } = useTranslation();

  // const languages defines because i18n.languages has variable length when language changes!!!
  const languages = [
    'en',
    'de'
  ]

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  function getLanguageButtons(languages, currentLanguage) {
    return languages.map((language) => (<button
      key={language}
      className={"px-2"
        + (language === currentLanguage ? " text-brand" : " text-white")}
      type="button" onClick={() => changeLanguage(language)}>
      {language.toUpperCase()}
    </button>
    ));
  }

  return (
    <Suspense fallback={<DefaultLoader />}>
      <div className="flex flex-row text-base uppercase divide-white divide-x-2">
        {getLanguageButtons(languages, i18n.language)}
      </div>
    </Suspense>
  );
}
