import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/tw-elements.css' ////////!!!!!!!!
import './assets/css/tailwind.css';
import App from './App';

import Configs from "./configs";
import config from 'react-global-configuration';

import 'tw-elements';

// import i18n (needs to be bundled ;))
import './i18n';

config.set(Configs);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);