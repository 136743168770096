/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'

import { Menu, Transition } from '@headlessui/react'
import { DotsHorizontalIcon } from '@heroicons/react/solid'

const generals = require("../../generals")

export default function ButtonMenu(props) {
  return (
    <Menu
      as="div"
      className={props.className ?
        props.className
        : "relative inline-block text-left"}>
      <div>
        <Menu.Button
          className={
            "max-w-xs rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
            + (props.button ? " hover:outline-none hover:ring-2 hover:ring-offset-2 hover:ring-offset-transparent hover:ring-gray-500" : "")
          }
        >
          <span className="sr-only">{props.srOnlyTitle}</span>
          {props.button
            ? props.button
            : <DotsHorizontalIcon className="h-5 w-5" aria-hidden="true" />
          }
        </Menu.Button>
      </div>

      {props.items && Array.isArray(props.items) && props.items.length > 0 &&
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {props.items.map((item, index) => {
                return <Menu.Item
                  key={index}
                >
                  {({ active }) => (
                    <a
                      href="."
                      className={generals.classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm'
                      )}
                      onClick={(e) => { e.preventDefault(); item.onClick && item.onClick() }}
                    >
                      {item.label}
                    </a>)}
                </Menu.Item>
              })
              }
            </div>
          </Menu.Items>
        </Transition>
      }
    </Menu >
  )
}
