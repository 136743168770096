/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/

import i18next from 'i18next';

import React, { Component } from 'react'

import { AssetVerticalCard } from '..';
import { PlaceHolder } from '..';

class AssetsList extends Component {
  constructor(props) {
    super(props)

    this.fieldRefs = [];

    this.state = {
      elements: [],
      // totalValid: true
    }
  }

  addElement = (field) => {
    let thisRef = React.createRef();
    this.fieldRefs.push(thisRef);
    let elements = this.state.elements;

    elements.push(
      <AssetVerticalCard
        key={field.id}

        ref={thisRef}

        asset={field}

        onChange={this.handleChange}

        value={this.props.selectedAssets    // value=true if asset is selected for shopping cart
          ? this.props.selectedAssets.filter(asset => asset.id === field.id).length > 0
          : false
        }
      />
    );
    this.setState({ elements: [...[...elements]] })
  }

  init = (items) => {
    this.fieldRefs = [];
    this.setState({ elements: [] })
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.elements = [];

    items.forEach((item) => {
      this.addElement(item);
    })
  }

  handleChange = () => {
    if (this.props.onChange)
      this.props.onChange(this.getValue())
  }

  getValue = () => {
    let value = [];
    this.fieldRefs.forEach(ref => {
      if (ref.current) {
        let thisValue = ref.current.getValue();
        if (thisValue)
          value.push(thisValue);
      }
    });

    return value;
  }

  // need when using props for initializing state
  componentWillReceiveProps(nextProps) {
    if (nextProps.assets && nextProps.assets.length !== this.props.assets.length) ////////!!!!!!!!
      this.init(nextProps.assets)
  }

  componentDidMount() {
    if (this.props.assets && Array.isArray(this.props.assets))
      this.props.assets.forEach((item) => {
        this.addElement(item);
      })
  }

  render() {
    return (
      <div className="bg-white">
        <div className="max-w-2xl mr-auto py-4 px-4 sm:py-6 sm:px-6 lg:px-8 lg:max-w-7xl">
          <h2 className="sr-only">{i18next.t("Assets")}</h2>

          {this.state.elements.length > 0
            ? <div className="flex flex-wrap">
              {this.state.elements.map((element, index) => (
                element
              ))}
            </div>
            : <PlaceHolder
              text={i18next.t("No assets available")}
            />
          }
        </div>
      </div>
    )
  }
}

export default AssetsList