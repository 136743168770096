import 'tw-elements';
/* This example requires Tailwind CSS & tw-elements */

import { createRef, useEffect, useState } from 'react';

export default function ChevronButton(props) {
  const buttonRef = createRef();

  const [collapsed, setCollapsed] = useState(props.collapsed !== undefined ? props.collapsed : false)

  useEffect(() => {
    setCollapsed(props.collapsed !== undefined ? props.collapsed : false)
  }, [props.collapsed])

  // eslint-disable-next-line no-unused-vars
  function triggerClick() {
    buttonRef.current.click();
  }

  // function handleClick(e) {
  //   if (e) {
  //     e.stopPropagation();
  //     e.preventDefault();
  //   }

  //   setCollapsed(!collapsed)

  //   if (props.onClick)
  //     props.onClick();

  //   if (props.onChange)
  //     props.onChange(!collapsed);
  // }

  return (
    <div
      // href="."

      // ref={(el) => (buttonRef.current = el)}
      // onClick={handleClick}

      className={(collapsed ? "collapsed " : "") + "ml-auto h-10 md:h-10 w-10 md:w-10 accordion-button relative flex items-center text-base text-gray-800 text-left bg-gray-200 border-0 transition focus:outline-none rounded-full"}
      // type="button"
    >
    </div>
  );
}