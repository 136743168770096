import config from 'react-global-configuration';

/* This example requires Tailwind CSS v2.0+ */

import i18next from 'i18next';

import { Component } from 'react'

import { Img } from ".."

import { CloseSquare } from 'react-iconly'

class AssetShoppingCartCard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      valid: true,
      value: 1
    }
  }

  handleChange = (e) => {
    let value = parseInt(e.target.value)
    this.setState({ value: value });

    // next line is needed while value of this.state.value before this.setState completed
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.value = value;

    if (this.props.onChange)
      this.props.onChange()

    this.validate(value)
  }

  getValue = () => {
    let value = this.state.value;

    return {
      asset_id: this.props.asset.id,
      quantity: value,
      plan_id: this.props.planId
    }
  }

  setValue = (value, forceValidate = false) => {
    // if (forceValidate) {
    //   if (this.validate(value))
    //     this.handleChange(value);
    // } else {
    //   this.handleChange(value);
    // }
  }

  init = () => {
    // if (this.props.field.initialValue !== undefined)
    //   this.setValue(this.props.field.initialValue)
  }

  reset = () => {
    // if (this.props.field.emptyValue !== undefined)
    //   this.setValue(this.props.field.emptyValue)
  }

  // function handleBlur(e) {
  //   validate()
  // }

  validate = (inputValue = null) => {
    let valid = true;

    return valid;
  }

  onItemDelete = () => {
    if (this.props.onItemDelete)
      this.props.onItemDelete(this.props.asset.id)
  }

  render() {
    let { asset } = this.props

    return (
      <li key={asset.id} className="relative flex bg-gray-100 rounded-lg p-3 m-8">
        <div
          className='absolute right-1 top-1 cursor-pointer'
          onClick={this.onItemDelete}
        >
          <CloseSquare set="bold" size={24} />
        </div>
        <div className="flex-shrink-0">
          <Img
            src={`${config.get('cdnUrl')}/${asset.file && asset.file.filename ? asset.file.filename : asset.file ? asset.file.src : ""}`}
            alt={asset.imageAlt}
            className="rounded-lg object-center object-cover w-24 h-24 sm:w-32 sm:h-32"
          />
        </div>

        <div className='flex flex-col w-full pl-3'>
          <h3 className="text-base 2xl:text-xl font-medium text-gray-900 mb-2">
            {asset.name}
          </h3>
          <div className="flex space-x-2 2xl:space-x-3">
            <p className="text-xs 2xl:text-base text-gray-500 whitespace-normal">
              {asset.description}
            </p>
          </div>

          <div className="mt-4 flex items-center">
            <label htmlFor={`quantity-${asset.id}`} className="sr-only">
              {i18next.t("Quantity")}, {asset.name}
            </label>
            <select
              id={`quantity-${asset.id}`}
              name={`quantity-${asset.id}`}
              className="block max-w-full rounded-md border border-gray-300 py-1.5 text-base leading-5 font-medium text-gray-700 text-left shadow-sm focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              onChange={this.handleChange}
            >
              {
                [...Array(parseInt(asset.available_stock))].map((_, i) =>
                  <option key={i} value={i + 1}>{i + 1}</option>
                )
              }
            </select>

            {/* <button
              type="button"
              className="ml-4 text-sm font-medium text-indigo-600 hover:text-indigo-500 sm:ml-0 sm:mt-3"
            >
              <span>Remove</span>
            </button> */}
          </div>

          <div className='text-xs 2xl:text-base font-medium text-gray-900 text-right mt-auto'>
            {`${asset.price} €`}
          </div>
        </div>
      </li>
    );
  }
}

export default AssetShoppingCartCard