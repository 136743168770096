/* This example requires Tailwind CSS v2.0+ */
import { Component } from 'react'
import { Switch } from '@headlessui/react'

const generals = require("../../generals")

class ToggleButton extends Component {
  constructor(props) {
    super(props);

    // eslint-disable-next-line no-unused-vars
    this.state = {
      valid: this.props.field.valid ? this.props.field.valid : true,
      value: this.props.field.value
        // eslint-disable-next-line eqeqeq
        ? (this.props.field.value == 1 ? true : false)
        : false
    }
  }

  handleChange = (checked) => {
    let value = checked;

    let prevValue = this.state.value;
    this.setState({ value: value });

    if (this.props.onChange && prevValue !== null)
      this.props.onChange(value)

    this.validate(value)
  }

  handleBlur = (e) => {
    this.validate()
  }

  getValue = () => {
    return this.state.value ? 1 : 0;
  }

  setValue = (value, forceValidate = false) => {
    if (forceValidate) {
      if (this.validate(value))
        this.handleChange(value);
    } else {
      this.handleChange(value);
    }
  }

  init = (initialValue = null) => {
    if (initialValue !== null)
      this.setValue(initialValue)
    else
      if (this.props.field.initialValue !== undefined)
        this.setValue(this.props.field.initialValue)
  }

  reset = () => {
    if (this.props.field.emptyValue !== undefined)
      this.setValue(this.props.field.emptyValue)
  }

  validate = () => {
    let valid = true;

    if (this.props.field.required)
      if (!this.state.value)
        valid = false;

    this.setState({ valid: valid });

    return valid;
  }

  // componentDidMount() {
  //   this.init();
  // }

  // need when using props for initializing state
  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.field) !== JSON.stringify(this.props.field)) {
      this.init(nextProps.field.initialValue);
    }
  }
  // static getDerivedStateFromProps(nextProps, prevState) {
  //   return {
  //     valid: nextProps.field.valid,
  //     value: nextProps.field.value
  //   };
  // }

  render() {
    return (
      <Switch.Group as="div" className={`flex items-center ${this.props.className ? this.props.className : ""}`}>
        {
          this.props.left &&
          <Switch.Label as="span" className="mr-3">
            <span className={
              "text-sm font-medium "
              + (this.props.disabled ? " text-gray-500" : "text-gray-900")
            }
            >
              {this.props.left}
            </span>
          </Switch.Label>
        }
        <Switch
          checked={this.state.value}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          className={
            generals.classNames(
              this.state.value ? 'bg-forth' : 'bg-gray-100',
              'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
              this.props.disabled ? " bg-gray-300" : "",
              this.props.switchClassName ? this.props.switchClassName : ""
            )
          }
          disabled={this.props.disabled}
        >
          <span
            aria-hidden="true"
            className={generals.classNames(
              this.state.value ? 'translate-x-5 bg-white' : 'translate-x-0 bg-forth',
              'pointer-events-none inline-block h-5 w-5 rounded-full shadow transform ring-0 transition ease-in-out duration-200'
            )}
          />
        </Switch >
        {
          this.props.right &&
          <Switch.Label as="span" className="ml-3">
            <span className={
              "text-sm font-medium "
              + (this.props.disabled ? " text-gray-500" : "text-gray-900")
            }
            >
              {this.props.right}
            </span>
          </Switch.Label>
        }
      </Switch.Group >
    )
  }
}

export default ToggleButton;