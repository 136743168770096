// contexts
import config from 'react-global-configuration';
import { AuthContext } from '../../context/authContext';
import { useTranslation } from 'react-i18next';

import { useState, useEffect, useContext } from 'react'
import axios from 'axios';

import { Dashboard, ShoppingCartButton, Back, PlanSmallCard } from '../../components'
import {
  MyOfficeNav,
  BookingHistoryNav,
  SettingsNav
} from '../../implementedComponents'

import { Bag2, TickSquare, Setting } from 'react-iconly'

// const generals = require("../../generals")

export default function CoworkerDashboard(props) {
  const { accessToken, isAuth, user, setUser } = useContext(AuthContext)

  const { t } = useTranslation();

  const [currentNav, setCurrentNav] = useState(props.currentNav ? props.currentNav : "");
  const [currentMyOfficeTab, setCurrentMyOfficeTab] = useState(props.currentTab ? props.currentTab : "");

  const [selectedAssets, setSelectedAssets] = useState([]);
  const [shoppingCartShow, setShoppingCartShow] = useState(false);

  const [currentPlan, setCurrentPlan] = useState(null);
  // useEffect(() => {
  //   loadUserByAccessToken();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  function loadUserByAccessToken() {
    const callbackFunction = () => {
      let url;
      url = `${config.get('apiUrl')}/getuserbytoken`;

      let configs = {
        headers: {
          'x-access-token': accessToken,
          timeout: config.get('apiCallTimeout')
        },
        params: {
          // page: f + 1
        },
      }
      axios.get(url, configs)
        .then((response) => {
          const user = response.data.data;

          setUser(user);
        })
        .catch((error) => {
          console.error(error);
        })
        .then(function () {
          // always executed
        });
    }

    isAuth(callbackFunction)
  }

  function onUserDataChanged() {
    loadUserByAccessToken()
  }

  // function onSelectedAssetsChange(value) {
  //   setSelectedAssets(value);

  //   if (value.length < 1)
  //     setShoppingCartShow(false)
  // }
  useEffect(() => {
    if (selectedAssets.length < 1)
      setShoppingCartShow(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssets])


  function toggleShopppingCartShow(show) {
    setShoppingCartShow(show);

    setCurrentNav('myOffice')
    setCurrentMyOfficeTab('shop')
  }

  function onShoppingCartButtonClick() {
    toggleShopppingCartShow(true)
  }

  const navigation = {
    myOffice: {
      key: 'myOffice',
      title: t('My Office'),
      href: '#',
      icon: Bag2,
      content: <MyOfficeNav
        currentTab={currentMyOfficeTab}

        currentPlan={currentPlan}
        setCurrentPlan={setCurrentPlan}

        selectedLocationId={props.selectedLocationId}
        // currentLocationId={currentLocationId}
        // setCurrentLocationId={setCurrentLocationId}

        selectedAssets={selectedAssets}
        setSelectedAssets={setSelectedAssets}
        // onSelectedAssetsChange={onSelectedAssetsChange}

        // plans={plans}
        // assets={assets}
        // assetCategories={assetCategories}
        // assignedAssets={assignedAssets}
        // boughtAssets={boughtAssets}

        shoppingCartShow={shoppingCartShow}

        toggleShopppingCartShow={toggleShopppingCartShow}
      />
    },
    bookingHistory: {
      key: 'bookingHistory',
      title: t('Booking History'),
      href: '#',
      icon: TickSquare,
      content: <BookingHistoryNav />
    },
    settings: {
      key: 'settings',
      title: t('Settings'),
      href: '#',
      icon: Setting,
      content: user
        ? <SettingsNav
          onUserDataChanged={onUserDataChanged}
        />
        : ""
    },
  }

  return accessToken && user
    ? <Dashboard
      navigation={navigation}
      currentNav={currentNav}

      statusIcon={selectedAssets.length > 0 &&
        <ShoppingCartButton
          selectedAssetsCount={selectedAssets.length}

          onClick={onShoppingCartButtonClick}
        />
      }

      card={currentPlan &&
        <>
          <h3 className='text-sm 2xl:text-base font-medium text-gray-500 mb-2 mx-2 pl-3 w-auto h-auto rounded-lg mt-28'>My Plan</h3>
          <PlanSmallCard
            plan={currentPlan}
          />
        </>
      }

      back={shoppingCartShow && <Back
        className="text-lg 2xl:text-xl"
        caption="Cart"
        subCaption=""

        onClick={() => {
          setShoppingCartShow(false)
        }}
      />
      }
    />
    : ""
}