// import config from 'react-global-configuration';

/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ....
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

import { useTranslation } from 'react-i18next';

import { useState } from 'react'
import Cookie from 'js-cookie'

// const cookieCurrentTabWildCard = `orangery-${config.get('env')}-current-tab`;
const cookieCurrentTabWildCard = `orangery-app-current-tab`;

const generals = require("../../generals")

function readCookie(cookieWildCard) {
  var thisCookieValue = null;

  var allCookies = Cookie.get();
  if (allCookies[cookieWildCard])
    thisCookieValue = allCookies[cookieWildCard];

  try {
    return JSON.parse(thisCookieValue)
  } catch (err) {
    return thisCookieValue
  }
}

export default function Tabs(props) {
  const { t } = useTranslation();

  const tabFromCookie = readCookie(cookieCurrentTabWildCard);
  let firstTabWithContent = Object.values(props.tabs).filter(item => item.content !== "")[0];
  let tempCurrentTab =
    props.currentTab && props.tabs[props.currentTab] !== undefined && props.tabs[props.currentTab].content !== ""
      ? props.currentTab
      : tabFromCookie && props.tabs[tabFromCookie] && props.tabs[tabFromCookie].content !== ""
        ? tabFromCookie
        : firstTabWithContent ? firstTabWithContent.key : ""

  const [currentTab, setCurrentTab] = useState(tempCurrentTab)

  function switchTab(currentTab) {
    setCurrentTab(currentTab)
    Cookie.set(cookieCurrentTabWildCard, currentTab)
  }

  return (
    props.tabs[currentTab] ?
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            {t("Select a tab")}
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full focus:outline-none focus:ring-none border-transparent border-black border-b-2"
            defaultValue={props.tabs[currentTab].title}
            onChange={(e) => { switchTab(e.target.value) }}
          >
            {Object.values(props.tabs).map((tab) => (
              tab.content && tab.content !== ""
                ? <option
                  key={tab.key}
                  value={tab.key}
                // selected={tab.key === currentTab}
                >
                  {tab.title}
                </option>
                : ""
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-border">
            <nav className="-mb-px flex space-x-4" aria-label="Tabs">
              {Object.values(props.tabs).map((tab) => (
                tab.content && tab.content !== ""
                  ? <a
                    key={tab.key}
                    href={tab.href}
                    className={generals.classNames(
                      tab.key === currentTab
                        ? 'border-black text-black'
                        : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-border',
                      'group inline-flex items-center pb-3 pt-0 px-.05 border-b-2 font-medium text-sm'
                    )}
                    aria-current={tab.key === currentTab ? 'page' : undefined}
                    onClick={(e) => { e.preventDefault(); switchTab(tab.key) }}
                  >
                    {tab.icon && <div
                      className='-ml-0.5 mr-2 h-5 w-5 shrink-0 place-items-stretch'
                      aria-hidden="true"
                    >
                      <tab.icon size={20} />
                    </div>
                    }
                    <span>{tab.title}</span>
                  </a>
                  : ""
              ))}
            </nav>
          </div>
        </div>
        {props.tabs[currentTab].content && props.tabs[currentTab].content !== ""
          ? <div className='flex flex-col mt-5 2xl:mt-10'>
            {props.tabs[currentTab].content}
          </div>
          : ""
        }
      </div>
      : ""
  )
}