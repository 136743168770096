// contexts
import config from 'react-global-configuration';
import { AuthContext } from '../../context/authContext';
import { useTranslation } from 'react-i18next';

import { createRef, useContext } from 'react'
import axios from 'axios'

import { Img, DropZone } from "../../components"
import { User } from 'react-iconly';

export default function Avatar(props) {
  const { accessToken, isAuth } = useContext(AuthContext)
  const { t } = useTranslation();

  let src = props.filename
    ? `${config.get('cdnUrl')}/${props.filename}`
    : props.src;

  function handleClick(e) {
    if (e)
      e.stopPropagation();

    if (props.onClick)
      props.onClick();
  }

  function validate(inputFile = null) {
    let valid = true;

    return valid;
  }

  function handleChange(file, forceValidate = false) {
    if (!forceValidate || validate(file)) {
      const callbackFunction = () => {
        let data = new FormData()
        data.append("file", file);

        let url = config.get('apiUrl') + `/updateprofile`;

        let headers = {
          'x-access-token': accessToken,
          timeout: config.get('apiCallTimeout')
        }
        headers["accept"] = "*/*";
        headers["Content-Type"] = "multipart/form-data;"

        axios.post(url, data, { headers })
          .then(res => {
            if (res.data.result) {
              if (props.onChange)
                props.onChange(file)
            } else {
            }
          })
          .catch(err => {
          });
      }

      isAuth(callbackFunction)
    } else {
    }
  }

  let dropZoneRef = createRef();

  return (
    <div className='relative'>
      {src
        ? <Img
          className={`flex-none object-cover rounded-full ${props.className}`}
          src={src}
          alt=""
          onClick={handleClick}
        />
        : <div
          className={`flex rounded-full bg-gray-200 text-gray-500 align-middle items-center ${props.className}`}
          onClick={handleClick}
        >
          <div className='flex place-items-center mx-auto my-auto w-fit h-fit'>
            <User set="light" size={props.size} />
          </div>
        </div>
      }
      {props.changeable
        ? <DropZone
          ref={el => { dropZoneRef.current = el; }}

          className={`absolute left-0 top-0 opacity-0 hover:opacity-80 rounded-full ${props.className}`}

          minimal={true}

          field={{
            label: "",
            id: "file",
            name: "file",
            type: "file",
            errorMessage: t("Choose an image"),

            valid: true,
            value: null,
            initialValue: null,

            key: "file",
            trimmable: false,
            required: true,
          }}

          onChange={handleChange}
        />
        : ""}
    </div>
  );
}
