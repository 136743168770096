import { Component } from 'react';
import { IconContext } from 'react-icons';
import { BiArrowBack } from 'react-icons/bi';

class Back extends Component {
  render() {
    return (
      <div className='flex flex-col hover:cursor-pointer' onClick={this.props.onClick}>
        <div className='flex flex-row'>
          <div>
            <div className="align-top pt-1 w-6">
              {!this.props.noArrow &&
                <IconContext.Provider value={{ size: 22 }}>
                  <BiArrowBack />
                </IconContext.Provider>
              }
            </div>
          </div>
          <div className={"font-medium text-xl " + this.props.className}>
            {this.props.caption ? this.props.caption : "Back"}
          </div>
        </div>
        {this.props.subCaption &&
          <div className='flex flex-row'>
            <div className='flex w-6'>
            </div>
            <div className="font-normal text-base text-gray-400">
              {this.props.subCaption}
            </div>
          </div >
        }
      </div >
    );
  }
}

export default Back;
