import config from 'react-global-configuration';

// import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { SplitScreen, A, JustifyBetween, VerticalForm, Logo } from '../components';

import backgroundImage from '../assets/images/login background.jpeg'

const generals = require("../generals")

export default function ResetPassword(props) {
  const { t } = useTranslation();

  let urlObj = new URL(window.location.href);
  let key = urlObj.searchParams.get("key");

  // const history = useHistory();

  if (!key)
    generals.goTo(config.get('homeUrl') + `/login`, 0);

  let fields = {
    password: {
      label: t("New Password") + " *",
      id: "password",
      name: "password",
      type: "password",
      autocomplete: "current-password",
      placeholder: t("Min. 6 characters"),
      errorMessage: t("Password is not valid"),

      valid: true,
      value: "",

      key: "password",
      trimmable: false,
      required: true,

      className: "w-full 2xl:w-112 space-y-1 2xl:space-y-3",
      inputClassName: "h-10 2xl:h-16 text-base 2xl:text-xl",
      labelClassName: "font-medium",
    },
    key: {
      id: "key",
      name: "key",
      type: "hidden",

      valid: true,
      value: key,

      key: "key",
      trimmable: false,
      required: true,
    },
  }

  function onSubmit(e) {
    generals.goTo(config.get('homeUrl') + `/login`, 0);
  }

  return (
    <SplitScreen
      content={
        <div className="col-span-11 md:col-span-5">
          <JustifyBetween
            className="p-6"
            elementsClassName="min-h-8"
            left=""
            right={
              <A href={`${config.get('homeUrl')}/login`} preText={t("Use current password?")} text={t("Login")} />
            }
          />
          <div className="py-12 mx-auto w-3/5 2xl:w-112">
            <VerticalForm
              logo={<Logo />}
              title={"Reset Password"}
              subTitle={t("Reset password to access your workspace")}

              fields={fields}

              submitButtonText={t("Reset Password")}
              submitApiUrl={config.get('apiUrl') + `/reset_confirm`}

              onSubmit={onSubmit}

              hasFile={false}

              noCheckToken={true}
            />
          </div>
        </div>
      }

      imageSrc={backgroundImage}
      imageAlt={t("orangery Workspace")}
    />
  );
}