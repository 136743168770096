import { Component } from 'react';

class JustifyBetween extends Component {
  render() {
    return (
      <div className={"flex justify-between" + (this.props.className ? " " + this.props.className : "")}>
        <div className={this.props.elementsClassName}>
          {this.props.left}
        </div>
        <div className={this.props.elementsClassName}>
          {this.props.right}
        </div>
      </div>
    );
  }
}

export default JustifyBetween;