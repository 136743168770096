import { Component } from 'react';

export default class Hidden extends Component {
  constructor(props) {
    super(props);

    this.state = {
      valid: this.props.field.valid ? this.props.field.valid : true,
      value: this.props.field.value ? this.props.field.value : false,
    }
  }

  getValue = () => {
    return this.state.value;
  }

  setValue = (value, forceValidate = false) => {
    if (forceValidate) {
      if (this.validate(value))
        this.handleChange(value);
    } else {
      this.handleChange(value);
    }
  }

  init = () => {
    if (this.props.field.initialValue !== undefined)
      this.setValue(this.props.field.initialValue)
  }

  reset = () => {
    if (this.props.field.emptyValue !== undefined)
      this.setValue(this.props.field.emptyValue)
  }

  handleChange = (value) => {
    this.setState({ value: value });

    if (this.props.onChange)
      this.props.onChange(this.props.field.name, value)

    this.validate(value)
  }

  handleBlur = (e) => {
    this.validate()
  }

  validate = (inputValue = null) => {
    let value = inputValue != null ? inputValue : this.state.value
    let valid = true;

    if (this.props.field.required) {
      if (value === null)
        valid = false;
    }

    this.setState({ valid: valid });

    return valid;
  }

  // need when using props for initializing state
  componentWillReceiveProps(nextProps) {
    if (nextProps.field.valid !== this.props.field.valid)
      this.setState({ valid: nextProps.field.valid });

    if (nextProps.field.value !== this.props.field.value)
      this.setState({ value: nextProps.field.value });
  }
  // static getDerivedStateFromProps(nextProps, prevState) {
  //   return {
  //     valid: nextProps.field.valid,
  //     value: nextProps.field.value
  //   };
  // }

  render() {
    let { field } = this.props

    return (
      <>
        <input
          id={field.id}
          name={field.name}
          type={field.type ? field.type : "checkbox"}
          required={field.required ? "required" : ""}
          className={
            "form-checkbox xl:h-4 xl:w-4 2xl:h-5p 2xl:w-5p rounded text-black border " +
            (!this.state.valid ? " border-red-500" : " border-border focus:border-black")
          }
        />
      </>
    );
  }
}