/* This example requires Tailwind CSS v2.0+ */

import { Component } from 'react'

const generals = require("../../generals")

export default class SelectableBadge extends Component {
  constructor(props) {
    super(props)

    this.state = {
      valid: true,
      value: false
    }
  }

  handleChange = (value) => {
    this.setState({ value: value });

    // next line is needed while value of this.state.value before this.setState completed
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.value = value;

    if (this.props.onChange)
      this.props.onChange()

    this.validate(value)
  }

  getValue = () => {
    let value = this.state.value;

    if (this.state.value) {
      if (this.props.field.exportLabel)
        return (this.props.field.item.label ? this.props.field.item.label : value);
      else
        return (this.props.field.item.value ? this.props.field.item.value : value);
    }
    else
      return "";
  }

  setValue = (value, forceValidate = false) => {
    if (forceValidate) {
      if (this.validate(value))
        this.handleChange(value);
    } else {
      this.handleChange(value);
    }
  }

  init = () => {
    // if (this.props.field.initialValue !== undefined)
    //   this.setValue(this.props.field.initialValue)
  }

  reset = () => {
    // if (this.props.field.emptyValue !== undefined)
    //   this.setValue(this.props.field.emptyValue)
  }

  // function handleBlur(e) {
  //   validate()
  // }

  validate = (inputValue = null) => {
    let value = inputValue != null ? inputValue : this.state.value
    let valid = true;

    if (this.props.field.required)
      if (value === false)
        valid = false;

    this.setState({ valid: valid });

    return valid;
  }

  render() {
    let { field } = this.props

    return (
      <div
        className={
          generals.classNames("select-none flex"
            , this.props.className
              ? this.props.className
              : "w-fit rounded-full text-sm 2xl:text-base 3xl:text-base font-normal py-1.5 mr-3 px-3"
            , this.state.value
              ? "bg-fifth text-white border border-fifth cursor-pointer"
              : this.props.disabled
                ? "bg-gray-300 text-white border border-gray-300 cursor-default"
                : "bg-white text-fifth border border-border cursor-pointer"
          )
        }
        onClick={(e) => { if (e) e.stopPropagation(); if (!this.props.disabled) this.handleChange(!this.state.value) }}
      >
        <span className='my-auto self-center place-self-center mx-auto'>{field.item.label}</span>
      </div>
    );
  }
}