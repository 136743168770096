import { Component } from 'react';

import DatePicker from 'sassy-datepicker';
import moment from 'moment';

class MyDatePicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      valid: this.props.field && this.props.field.valid ? this.props.field.valid : true,
      value: this.props.field && this.props.field.value ? this.props.field.value : new Date(),
    }
  }

  // eslint-disable-next-line no-unused-vars
  getValue = (value = null) => {
    if (value === null)
      return moment(this.state.value, 'YYYY-MM-DD').format("YYYY-MM-DD");
    else
      return moment(value, 'YYYY-MM-DD').format("YYYY-MM-DD");
  }

  setValue = (value, forceValidate = false) => {
    if (forceValidate) {
      if (this.validate(value))
        this.handleChange(value);
    } else {
      this.handleChange(value);
    }
  }

  init = () => {
    // if (this.props.field.initialValue !== undefined)
    //   this.setValue(this.props.field.initialValue)
  }

  reset = () => {
    // if (this.props.field.emptyValue !== undefined)
    //   this.setValue(this.props.field.emptyValue)
  }

  // need when using props for initializing state
  componentWillReceiveProps(nextProps) {
    if (nextProps.field.valid !== this.props.field.valid)
      this.setState({ valid: nextProps.field.valid });

    if (nextProps.field.value !== this.props.field.value)
      this.setState({ value: nextProps.field.value });
  }
  // static getDerivedStateFromProps(nextProps, prevState) {
  //   return {
  //     valid: nextProps.field.valid,
  //     value: nextProps.field.value
  //   };
  // }

  handleChange = (value) => {
    this.setState({ value: value });

    if (this.props.onChange)
      this.props.onChange(this.getValue(value))

    this.validate(value)
  }

  handleBlur = (e) => {
    this.validate()
  }

  validate = (inputValue = null) => {
    let value = inputValue != null ? inputValue : this.state.value
    let valid = true;

    if (this.props.field.required) {
      if (value === false)
        valid = false;
    }

    this.setState({ valid: valid });

    return valid;
  }

  render() {
    return (
      <DatePicker
        className='w-full mx-auto'
        onChange={this.handleChange}

        selected={new Date(this.state.value) }
      />
    );
  }
}

export default MyDatePicker;