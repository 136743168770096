// import config from 'react-global-configuration';

/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ....
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

// configs
import config from 'react-global-configuration';

// contexts
import { AuthContext } from '../../context/authContext';
import { useTranslation } from 'react-i18next';

// dependencies
import { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Calendar, PaperDownload } from 'react-iconly'

// my components
// import { Tabs, Table, StatusBadge, CreditCardCard, DefaultLoader, PlaceHolder } from '../../components'
import { Tabs, Table, StatusBadge, DefaultLoader } from '../../components'

// assets
// import creditCardSvg from '../../assets/images/icons/credit-card 1.svg'

export default function BookingHistoryTabs(props) {
  const { accessToken, isAuth } = useContext(AuthContext)
  const { t } = useTranslation();

  const [bookingHistoryList, setBookingHistoryList] = useState(null);
  // const [cardDetails, setCardDetails] = useState([]);

  function loadBookingHistory() {
    const data = {
      header: {
        className: 'px-2 2xl:px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider',
        cols: [
          {
            key: 1,
            content: t('Invoice'),
          },
          {
            key: 2,
            content: t('Amount'),
          },
          {
            key: 3,
            content: t('Status'),
          },
          {
            key: 4,
            content: t('Date'),
          },
          {
            key: 5,
            content: t('Payment Method'),
          },
          {
            key: 6,
            content: <span className="sr-only">{t("Edit")}</span>,
            className: 'relative px-6 py-3'
          }
        ]
      },
    }

    const callbackFunction = () => {
      // let url = `${config.get('apiUrl')}/getbookinghistory?token=${accessToken}`; ////////!!!!!!!!
      let url = `${config.get('apiUrl')}/getbookinghistory`;
      let configs = {
        headers: {
          'x-access-token': accessToken,
          timeout: config.get('apiCallTimeout')
        },
        params: {
          // page: f + 1
        },
      }
      axios.get(url, configs)
        .then((response) => {
          data.rows = response.data.data.map((item, index) => (
            {
              key: item.id ? item.id : index,
              cols: {
                1: item.invoice,
                2: `${item.amount} €`,
                3: <StatusBadge
                  status={item.status}
                />,
                4: item.date,
                5: item.payment_method,
                6: <div className="space-y-2">
                  {item.download_link && Array.isArray(item.download_link)
                    ? item.download_link.map(link => (
                      <a className="text-gray-400 hover:text-gray-700 space-x-1"
                        href={link.link}
                        target="_blank"
                        rel="noreferrer"
                        role="button"
                      >
                        <PaperDownload set="light" size={22} />{" "}{link.title ? link.title : ""}
                      </a>
                    ))
                    : ""
                  }
                </div>
              }
            }
          ))

          setBookingHistoryList(data);
        })
        .catch((error) => {
          console.error(error);
          setBookingHistoryList(data);
        })
        .then(function () {
          // always executed
        });
    }

    isAuth(callbackFunction)
  }

  // function loadCardDetails() {
  //   const callbackFunction = () => {
  //     let url = `${config.get('apiUrl')}/getcards`;
  //     let configs = {
  //       headers: {
  //         'x-access-token': accessToken,
  //         timeout: config.get('apiCallTimeout')
  //       },
  //       params: {
  //         // page: f + 1
  //       },
  //     }
  //     axios.get(url, configs)
  //       .then((response) => {
  //         setCardDetails(response.data.data);
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //         setCardDetails([]);
  //       })
  //       .then(function () {
  //         // always executed
  //       });
  //   }

  //   isAuth(callbackFunction)
  // }

  useEffect(() => {
    loadBookingHistory();
    // loadCardDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const tabs = {
    bookingHistory: {
      key: 'bookingHistory',
      title: 'Booking history & invoices',
      href: '#',
      icon: Calendar,
      content: <>
        {bookingHistoryList
          ? <Table
            data={bookingHistoryList}
          />
          : <div className='text-gray-200 h-20 w-auto'>
            <DefaultLoader />
          </div>
        }
      </>
    },
    // cardDetails: {
    //   key: 'cardDetails',
    //   title: 'Card details',
    //   href: '#',
    //   icon: TimeSquare,
    //   content: <>
    //     <div className='flex flex-row items-center space-x-3 mt-5'>
    //       <Img
    //         className='h-8 w-auto'
    //         src={creditCardSvg}
    //         alt="Credit cards"
    //       />
    //       <p className='font-semibold'>
    //         Credit cards
    //       </p>
    //     </div>
    //     {Array.isArray(cardDetails) && cardDetails.length > 0
    //       ? cardDetails.map((cardDetail) => (
    //         <CreditCardCard
    //           key={cardDetail.id}
    //           creditCard={cardDetail}
    //         />
    //       ))
    //       : <>
    //         <div className='w-full text-center align-middle'>
    //           <PlaceHolder
    //             text="No cards available"
    //           />
    //         </div>
    //         {/* <CreditCardCard
    //           key={1}
    //           creditCard={{}}
    //         /> */}
    //       </>
    //     }
    //   </>
    // },
  }

  return (
    <Tabs
      tabs={tabs}
      currentTab="bookingHistory"
    />
  )
}