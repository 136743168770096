import { Img } from "../../../components"

export default function SplitScreen(props) {
  return (
    <>
      <div className="h-full bg-white grid grid-cols-11">
        <div className="col-span-11 md:col-span-5 h-full max-h-full overflow-y-scroll">
          {props.content}
        </div>
        <div className="hidden md:block h-full col-span-6 p-0 m-0">
          <Img className="object-cover w-full h-full" src={props.imageSrc} alt={props.imageAlt} />
        </div>
      </div>
    </>
  );
}