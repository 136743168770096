/* This example requires Tailwind CSS v2.0+ */

import React, { Component } from 'react'

import { SelectableBadge } from '../../components'
export default class SelectableBadges extends Component {
  constructor(props) {
    super(props)

    this.fieldRefs = [];

    this.state = {
      elements: [],
      // totalValid: true
    }
  }

  addElement = (field) => {
    let thisRef = React.createRef();
    this.fieldRefs.push(thisRef);
    let elements = this.state.elements;
    elements.push(
      <SelectableBadge
        key={elements.length}

        ref={thisRef}

        field={field}

        onChange={this.handleChange}

        className={this.props.badgeClassName}

        disabled={this.props.disables && Array.isArray(this.props.disables) ? this.props.disables.some(item => item === field.item.value) : false}
      />
    );
    this.setState({ elements: [...[...elements]] })
  }

  handleChange = () => {
    if (this.props.onChange)
      this.props.onChange(this.getValue())
  }

  getValue = () => {
    let value = [];
    this.fieldRefs.forEach(ref => {
      let thisValue = ref.current.getValue();
      if (thisValue)
        value.push(thisValue);
    });

    return value;
  }

  validate = () => {
    let valid = true;

    // if (this.fieldRefs.length < 1) {
    //   this.setState({ totalValid: false })

    //   valid = false;
    // } else {
    //   this.setState({ totalValid: true })

    this.fieldRefs.forEach(ref => {
      valid = ref.current.validate() && valid;
    });
    // }

    return valid;
  }

  // need when using props for initializing state
  componentWillReceiveProps(nextProps) {
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.elements = [];
    this.fieldRefs = [];
    // if (nextProps.items.length !== this.props.items.length) ////////!!!!!!!!
    nextProps.items.forEach((item) => {
      this.addElement({
        item: item.value !== undefined
          ? item
          : { label: item, value: item },
        exportLabel: true
      });
    })
  }

  // shouldComponentUpdate() {
  //   return true;
  // }

  componentDidMount() {
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.elements = [];
    this.fieldRefs = [];
    // if (nextProps.items.length !== this.props.items.length) ////////!!!!!!!!
    this.props.items.forEach((item) => {
      this.addElement({
        item: item.value !== undefined
          ? item
          : { label: item, value: item },
        exportLabel: true
      });
    })
  }

  render() {
    return (
      <div
        className={this.props.className
          ? this.props.className
          : "w-full scrollbar-default sm:scrollbar-hide flex overflow-x-auto whitespace-nowrap p-4"
        }
      >
        {this.state.elements.map((element, index) => (
          element
        ))}
      </div>
    );
  }
}