const generals = require("../../../../../generals")

export default function NavButton(props) {
  return (
    <a
      key={props.item.key}
      href={props.item.href}
      className={
        generals.classNames(
          props.active
            ? 'bg-gray-100 text-black'
            : 'text-gray-700 hover:bg-gray-50 hover:text-gray-800',
          'group rounded flex items-center py-2 px-2 text-base font-medium md:px-3 2xl:px-4 md:py-3 md:text-base 2xl:text-xlp m-0',
          props.className
        )}
      onClick={props.onClick}
    >
      <div
        className='mr-3 h-6 w-6 shrink-0 place-items-stretch'
        aria-hidden="true"
      >
        <props.item.icon />
      </div>
      {props.item.title}
    </a>
  )
}
