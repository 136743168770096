/* This example requires Tailwind CSS v2.0+ */
import { Component } from 'react'
import { Menu, Transition } from '@headlessui/react'

import { ChevronButton } from '../../components'

export default class DropDownContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      show: this.props.show
    }
  }

  // setShow = (show) => {
  //   this.setState({ show: show })
  //   // if (this.props.setShow)
  //   //   this.props.setShow(show)
  // }

  // handleMenuButtonClick = () => {
  //   this.setShow(!this.state.show)
  // }

  // handleChevronButtonChange = (collapsed) => {
  //   this.setShow(!collapsed)
  // }

  componentWillReceiveProps(nextProps) {
    if (nextProps.show !== this.props.show) {
      this.setState({ show: nextProps.show })
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.show = nextProps.show
    }
  }

  render() {
    let { field } = this.props

    return (
      <div className={`align-baseline`}>
        {field.label &&
          <label
            htmlFor={field.id}
            className={
              `block text-sm 3xl:text-base text-gray-700 ${field.labelClassName ? field.labelClassName : ""}`
              + (this.props.disabled ? " text-gray-500" : "")
            }
          >
            {field.label}
          </label>
        }
        <Menu
          as="div"
          className={`relative text-left ${field.menuClassName ? field.menuClassName : ""}`}
        >
          {({ open }) => {
            if (this.props.setShow) {
              this.props.setShow(open)
            }

            return (
              <div>
                <Menu.Button
                  className={
                    `relative w-full text-left shadow-sm px-3 text-sm font-normal hover:bg-gray-50 focus:outline-none overflow-hidden ${field.inputClassName ? field.inputClassName : "border border-border rounded px-3"}`
                    + (this.props.disabled ? " disabled:bg-gray-300 disabled:text-gray-500" : " bg-white text-gray-700")
                  }

                  disabled={this.props.disabled}

                // onClick={this.handleMenuButtonClick}
                >
                  <div className='flex flex-row items-center'>
                    {
                      this.props.icon ?
                        <div className='mr-2'>{this.props.icon}</div>
                        : ""
                    }
                    <span className='whitespace-nowrap max-w-full'>{field.placeholder}</span>
                    <div className='ml-auto'>
                      <ChevronButton
                        collapsed={!this.state.show}

                      // onChange={this.handleChevronButtonChange}
                      />
                    </div>
                  </div>
                </Menu.Button>
                <Transition
                  show={this.state.show}
                  // as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    className="origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-10"
                    static
                  >
                    <div className="relative">
                      {this.props.children}
                    </div>
                  </Menu.Items>
                </Transition>
              </div>
            )
          }}
        </Menu>
      </div>
    )
  }
}