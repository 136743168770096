/*
  This example requires Tailwind CSS v2.0+ 
*/

// contexts
import config from 'react-global-configuration';
import { AuthContext } from '../../context/authContext';
import { useTranslation } from 'react-i18next';

import { Fragment, useContext } from 'react'
import { Popover, Transition } from '@headlessui/react'

import {
  Logo,
  ChangeLanguageButton, ButtonMenu, Avatar,
} from '../../components'

import {
  // MenuIcon,
  XIcon,
} from '@heroicons/react/outline'

const generals = require("../../generals")

const navigation = [
  // { name: t('Coworking'), href: '#' },
  // { name: t('Meeting room'), href: '#' },
  // { name: t('Blog'), href: '#' },
  // { name: t('Contact'), href: '#' },
]

export default function Header(props) {
  const { user } = useContext(AuthContext)
  const { t } = useTranslation();

  return (
    <>
      {!props.noRightButtons
        ? <div className='flex place-items-center fixed top-2 right-1 sm:top-0 sm:right-5 z-30 py-3 px-6 sm:px-6 space-x-2'>
          <div>
            <ChangeLanguageButton mode="white" />
          </div>
          <ButtonMenu
            className="ml-3 relative"
            srOnlyTitle={t("Open user menu")}
            button={<Avatar
              className="h-10 md:h-14 w-10 md:w-14 focus:outline-none"
              // src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
              src={user && user.file && user.file.filename
                ? `${config.get('cdnUrl')}/${user.file.filename}`
                : ""
              }
              size={32}
              onClick={() => {
                generals.goTo(`${config.get('appUrl')}/dashboard`, 0)
              }}
            />
            }
          />
        </div>
        : ""}

      <Popover as="header" className="w-full bg-sixth fixed top-0 z-10">
        <div className="">
          <nav
            className="w-full sm:mx-auto sm:max-w-7xl relative flex items-center justify-between py-6 px-6 sm:px-6"
            aria-label="Global"
          >
            <div className="relative flex items-center flex-1">
              <div className="flex items-center justify-between w-full md:w-auto">
                <a href=".">
                  <span className="sr-only">orangery.</span>
                  <Logo white={true} />
                </a>
                {/* <div className="-mr-2 flex items-center md:hidden">
                  <Popover.Button className="bg-gray-900 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-800 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div> */}
              </div>
              <div className="hidden space-x-8 md:flex md:ml-10">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="text-base font-medium text-white hover:text-gray-300"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </nav>
        </div>

        <Transition
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel focus className="absolute top-0 inset-x-0 p-2 transition transform origin-top md:hidden">
            <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="px-5 pt-4 flex items-center justify-between">
                <div>
                  <Logo />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-600">
                    <span className="sr-only">{t("Close menu")}</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="pt-5 pb-6">
                <div className="px-2 space-y-1">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="mt-6 px-5">
                  {/* <a
                        href="."
                        className="block text-center w-full py-3 px-4 rounded-md shadow bg-gradient-to-r from-teal-500 to-cyan-600 text-white font-medium hover:from-teal-600 hover:to-cyan-700"
                      >
                        Start free trial
                      </a> */}
                </div>
                <div className="mt-6 px-5">
                  <p className="text-center text-base font-medium text-gray-500">
                    {/* Existing customer?{' '}
                        <a href="." className="text-gray-900 hover:underline">
                          Login
                        </a> */}
                  </p>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </>
  )
}
