import React from "react"
import ContentLoader from "react-content-loader"

export default function DefaultLoader(props) {
  return <ContentLoader
    speed={2}
    width={40}
    height={40}
    viewBox="0 0 40 40"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="40" rx="2" ry="2" width="40" height="40" />
  </ContentLoader>
}