/* This example requires Tailwind CSS v2.0+ */

// // Table sample Data:
// import passImage from "../../assets/images/offices/solingen8 1.png"
// import arrowDownSquareSvg from "../../assets/images/icons/Arrow - Down Square.svg"

// // eslint-disable-next-line no-unused-vars
// const tempData = {
//   header: {
//     className: 'px-2 2xl:px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider',
//     cols: [
//       {
//         key: 1,
//         content: 'Name',
//       },
//       {
//         key: 2,
//         content: 'Type',
//       },
//       {
//         key: 3,
//         content: 'Duration',
//       },
//       {
//         key: 4,
//         content: 'Location',
//       },
//       {
//         key: 5,
//         content: 'Capacity',
//       },
//       {
//         key: 6,
//         content: 'Quantity',
//       },
//       {
//         key: 7,
//         content: 'Price',
//       },
//       {
//         key: 8,
//         content: <span className="sr-only">Edit</span>,
//         className: 'relative px-6 py-3'
//       }
//     ]
//   },
//   rows: [
//     {
//       key: 1,
//       cols: {
//         1: <div className="flex space-x-2 2xl:space-x-3">
//           <Img
//             className='2xl:flex-shrink-0 h-16 w-16 2xl:max-h-24 2xl:w-24 object-cover rounded-lg'
//             src={passImage}
//             alt=""
//           />
//           <div>
//             <p>
//               <span className="inline-flex items-center px-2 py-0.5 2xl:mb-3 rounded text-xs font-medium bg-gray-100 text-gray-800">
//                 Office id: 24875
//               </span>
//             </p>
//             <p className="text-sm 2xl:text-xl font-semibold">Monatspass Flex Desk</p>
//             <p className="text-xs 2xl:text-sm font-normal text-gray-700">24/7-Zugang möglich Car Sharing inklusive</p>
//           </div>
//         </div>,
//         2: 'Flex Desk',
//         3: 'Monthly',
//         4: 'hildesheim',
//         5: '4 Person',
//         6: '12',
//         7: '549 €',
//         8: <a href="." className="text-indigo-600 hover:text-indigo-900" >
//           <Img
//             className=''
//             src={arrowDownSquareSvg}
//             alt=""
//           />
//         </a>
//       }
//     },
//     {
//       key: 2,
//       cols: {
//         1: <div className="flex space-x-2 2xl:space-x-3">
//           <Img
//             className='2xl:flex-shrink-0 h-16 w-16 2xl:max-h-24 2xl:w-24 object-cover rounded-lg'
//             src={passImage}
//             alt=""
//           />
//           <div>
//             <p>
//               <span className="inline-flex items-center px-2 py-0.5 2xl:mb-3 rounded text-xs font-medium bg-gray-100 text-gray-800">
//                 Office id: 24875
//               </span>
//             </p>
//             <p className="text-sm 2xl:text-xl font-semibold">Monatspass Flex Desk</p>
//             <p className="text-xs 2xl:text-sm font-normal text-gray-700">24/7-Zugang möglich Car Sharing inklusive</p>
//           </div>
//         </div>,
//         2: 'Flex Desk',
//         3: 'Monthly',
//         4: 'hildesheim',
//         5: '4 Person',
//         6: '12',
//         7: '549 €',
//         8: < a href="." className="text-indigo-600 hover:text-indigo-900" >
//           <Img
//             className=''
//             src={arrowDownSquareSvg}
//             alt=""
//           />
//         </a >
//       }
//     },
//     {
//       key: 3,
//       cols: {
//         1: <div className="flex space-x-2 2xl:space-x-3">
//           <Img
//             className='2xl:flex-shrink-0 h-16 w-16 2xl:max-h-24 2xl:w-24 object-cover rounded-lg'
//             src={passImage}
//             alt=""
//           />
//           <div>
//             <p>
//               <span className="inline-flex items-center px-2 py-0.5 2xl:mb-3 rounded text-xs font-medium bg-gray-100 text-gray-800">
//                 Office id: 24875
//               </span>
//             </p>
//             <p className="text-sm 2xl:text-xl font-semibold">Monatspass Flex Desk</p>
//             <p className="text-xs 2xl:text-sm font-normal text-gray-700">24/7-Zugang möglich Car Sharing inklusive</p>
//           </div>
//         </div>,
//         2: 'Flex Desk',
//         3: 'Monthly',
//         4: 'hildesheim',
//         5: '4 Person',
//         6: '12',
//         7: '549 €',
//         8: < a href="." className="text-indigo-600 hover:text-indigo-900" >
//           <Img
//             className=''
//             src={arrowDownSquareSvg}
//             alt=""
//           />
//         </a >
//       }
//     },
//     {
//       key: 4,
//       cols: {
//         1: <div className="flex space-x-2 2xl:space-x-3">
//           <Img
//             className='2xl:flex-shrink-0 h-16 w-16 2xl:max-h-24 2xl:w-24 object-cover rounded-lg'
//             src={passImage}
//             alt=""
//           />
//           <div>
//             <p>
//               <span className="inline-flex items-center px-2 py-0.5 2xl:mb-3 rounded text-xs font-medium bg-gray-100 text-gray-800">
//                 Office id: 24875
//               </span>
//             </p>
//             <p className="text-sm 2xl:text-xl font-semibold">Monatspass Flex Desk</p>
//             <p className="text-xs 2xl:text-sm font-normal text-gray-700">24/7-Zugang möglich Car Sharing inklusive</p>
//           </div>
//         </div>,
//         2: 'Flex Desk',
//         3: 'Monthly',
//         4: 'hildesheim',
//         5: '4 Person',
//         6: '12',
//         7: '549 €',
//         8: < a href="." className="text-indigo-600 hover:text-indigo-900" >
//           <Img
//             className=''
//             src={arrowDownSquareSvg}
//             alt=""
//           />
//         </a >
//       }
//     },
//   ]
// }

import { PlaceHolder } from "../../components"
// import { StackedList } from "../../components"

export default function Table(props) {
  let { data } = props;

  return (
    <>
      {data.header &&
        <table className="min-w-full border-separate">
          <thead className="bg-gray-100 rounded-lg">
            <tr className="">
              {data.header.cols.map((headerCol) => (
                <th key={headerCol.key} scope="col" className={headerCol.className ? headerCol.className : data.header.className}>
                  {headerCol.content}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="">
            {data.rows && Array.isArray(data.rows) ?
              data.rows.map((dataRow) => (
                <tr key={dataRow.key}>
                  {Object.values(dataRow.cols).map((dataCell, index) => (
                    <td key={index} className="px-2 2xl:px-6 py-4 text-sm 2xl:text-xl"> {/* whitespace-nowrap */}
                      {dataCell}
                    </td>
                  ))}
                </tr>
              ))
              : ""
            }
          </tbody>
        </table>
      }
      {!data.rows || !Array.isArray(data.rows) || (Array.isArray(data.rows) && data.rows.length === 0)
        ? <PlaceHolder
          text="No data available"
        />
        : ""
      }
      {/* <StackedList /> */}
    </>
  )
}
