/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

import config from 'react-global-configuration';

import { AuthContext } from "../../context/authContext";
import i18next from 'i18next';

import { Component, createRef } from 'react';
import axios from 'axios';

import { Button, Modal, AssetShoppingCartCard } from '..';

const generals = require("../../generals")

class ShoppingCart extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props);

    this.accessToken = context.accessToken;
    this.isAuth = context.isAuth;

    let tempRefs = {};
    Object.values(this.props.assets).forEach(asset => {
      tempRefs[asset.id] = createRef();
    });

    this.state = {
      modalShow: false,
      modalText: "",
      waiting: false,

      assets: this.props.assets,
      fieldRefs: tempRefs,

      subTotal: 0,
    }
  }

  toggleModal = (show) => {
    this.setState({ modalShow: show });
  }

  handleSubmit = async (e = null) => {
    if (e !== null)
      e.preventDefault();

    if (this.validateForm()) {
      const callbackFunction = () => {
        let data = this.getValue();
        this.setState({ waiting: true });

        let url = `${config.get('apiUrl')}/sendcart`;

        // const res = await fetch(url, {
        //   method: 'POST',
        //   headers: {
        //     "Content-Type": 'application/json'
        //   }
        // })
        // const body = await res.json()
        // generals.goTo(body.url, 0)

        let headers = {
          "Content-Type": 'application/json',
          'x-access-token': this.accessToken,
          timeout: config.get('apiCallTimeout')
        }
        axios.post(url, data, { headers })
          .then(res => {
            this.setState({ waiting: false });

            // this.setState({ modalShow: true });

            // if (res.data.result)
            //   generals.goTo(res.data.data.url, 0)
            if (res.data && res.data.data && res.data.data.url && (typeof res.data.data.url === 'string' || res.data.data.url instanceof String))
              generals.goTo(res.data.data.url, 0)
            else {
              this.setState({ waiting: false });

              this.setState({ modalText: res.data.message });
              this.setState({ modalShow: true });
            }
            if (!res.data.result && !res.data.data)
              generals.goTo(`${config.get('homeUrl')}/login`, 0)
            // if (res.data.result) {
            //   this.setState({ modalText: res.data.message });

            //   // this.init();

            //   if (this.props.onSubmit) {
            //     this.props.onSubmit();
            //   }
            // } else {
            //   this.setState({ modalText: res.data.message });
            // }
          })
          .catch(err => {
            this.setState({ waiting: false });

            this.setState({ modalText: err + " " });
            this.setState({ modalShow: true });
          });
      }

      this.isAuth(callbackFunction)
    } else {
    }
  }

  // eslint-disable-next-line no-unused-vars
  onChange = () => {
    let subTotal = 0;
    Object.values(this.state.fieldRefs).forEach(ref => {
      let thisValue = ref.current.getValue();
      let thisAsset = this.props.assets.filter(asset => parseInt(asset.id) === parseInt(thisValue.asset_id))[0];
      subTotal += parseInt(thisAsset.price) * parseInt(thisValue.quantity);
    });

    this.setState({ subTotal: subTotal })
  }

  onItemDelete = (itemId) => {    ////////!!!!!!!! should better to be itemKey
    if (this.props.onItemDelete)
      this.props.onItemDelete(itemId);
  }

  validateForm = () => {
    let valid = true;

    return valid;
  }

  getValue = () => {
    let value = {};

    // value.token = this.accessToken
    let returnUrl = `${config.get('homeUrl')}/payment_result`;
    value.url = returnUrl

    value.cartObjects = []
    Object.values(this.state.fieldRefs).forEach(ref => {
      value.cartObjects.push(ref.current.getValue());
    });

    value.cartObjects = JSON.stringify(value.cartObjects);

    return value;
  }

  // need when using props for initializing state
  componentWillReceiveProps(nextProps) {
    if (nextProps.assets.length !== this.props.assets.length) { ////////!!!!!!!!
      let tempRefs = {};
      Object.values(this.props.assets).forEach(asset => {
        tempRefs[asset.id] = createRef();
      });

      this.setState({ fieldRefs: tempRefs });
    }
  }

  componentDidMount() {
    this.onChange();
  }

  render() {
    let assets = this.props.assets;

    return (
      <div>
        <Modal
          id="modal"
          text={this.state.modalText}
          show={this.state.modalShow}
          toggleModal={this.toggleModal}
        />
        <div className="bg-white w-full">
          <h1 className="text-xl font-medium tracking-tight text-gray-900">{i18next.t("Shopping Cart")}</h1>

          <div className="w-full max-w-5xl mx-auto">
            <form className="mt-2 flex flex-col sm:flex-row">
              <div className='basis-full sm:basis-1/2'>
                <h2 className="sr-only">{i18next.t("Items in your shopping cart")}</h2>

                <ul className="">
                  {Array.isArray(assets) && assets.map((asset) => (
                    <AssetShoppingCartCard
                      ref={this.state.fieldRefs[asset.id]}

                      planId={this.props.planId}

                      key={asset.id}

                      asset={asset}

                      onChange={this.onChange}

                      onItemDelete={this.onItemDelete}
                    />
                  ))}
                </ul>
              </div>

              {/* Order summary */}
              <div className="bg-gray-100 sm:place-self-end basis-full sm:basis-1/2 mt-20 sm:mt-0 p-6 rounded-lg">
                <div className="px-4 py-6 sm:p-6 lg:p-8">
                  <h2 className="sr-only">{i18next.t("Order summary")}</h2>

                  <div className="flow-root">
                    <dl className="-my-4 text-sm divide-y divide-gray-200">
                      <div className="py-4 flex items-center justify-between">
                        <dt className="font-medium text-gray-600">{i18next.t("Subtotal")}</dt>
                        <dd className="font-medium text-gray-900">{`${this.state.subTotal} €`}</dd>
                      </div>
                    </dl>
                  </div>
                </div>
                <div className="mt-3">
                  <Button
                    onClick={this.handleSubmit}
                    className="w-full bg-brand border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-black hover:bg-brand-900 focus:outline-none"
                    text="Pay"
                    waitingText={"Please wait..."}
                    waiting={this.state.waiting}
                  />
                </div>
                <div className="mt-6 text-right">
                  <p className='text-sm text-gray-600'>
                    {i18next.t("Powered by")} <span className='font-medium'>Stripe</span>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default ShoppingCart