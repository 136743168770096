import config from 'react-global-configuration';

import { AuthContext } from "../../context/authContext";
import { useTranslation } from 'react-i18next';

import { useState, useContext, useEffect } from "react";
import axios from 'axios';
import moment from 'moment';

import { JustifyBetween, Modal, Img, Button } from '../../components';

export default function PlanLargeVerticalCard(props) {
  const { accessToken, isAuth } = useContext(AuthContext);
  const { t } = useTranslation();

  const [active, setActive] = useState(props.plan && props.plan.active);
  useEffect(() => {
    setActive(props.plan && props.plan.active);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.plan && props.plan.active])


  const [waiting, setWaiting] = useState(false);

  const [modalShow, setModalShow] = useState(false);
  const [modalText, setModalText] = useState("");
  const [modalOnSubmit, setModalOnSubmit] = useState(() => { });
  const [modalSubmitButtonText, setModalSubmitButtonText] = useState("OK");

  function toggleModal(show) {
    setModalShow(show);
  }

  function modalOnClose() {
    setModalText("");
    setModalShow(false);
    setModalSubmitButtonText("OK");
    setModalOnSubmit(() => { })
  }

  function onTogglePlanActivate(value) {
    if (props.plan.id) {

      setModalText(t("Are you share to deactive your subscription?"));
      setModalShow(true);
      setModalSubmitButtonText("yes");

      // setActive(active)  // commented due to side effects
      setModalOnSubmit(() => () => {
        setModalShow(false);
        setModalSubmitButtonText("OK");
        setModalOnSubmit(() => { })

        let prevActive = props.plan.active

        if (!value) { // onnly deactivation is supported yet, so only if !value === true should be accepted
          const callbackFunction = () => {
            setWaiting(true)

            let url = `${config.get('apiUrl')}/plantogleactivation`;
            let data = {
              plan_id: props.plan.id,
              active: value
            }
            let headers = {
              'x-access-token': accessToken,
              timeout: config.get('apiCallTimeout')
            }
            axios.post(url, data, { headers })
              .then((response) => {
                let active = response.data.data.active

                setWaiting(false)

                if (!active) {
                  setModalText(response.data.message);
                  setModalShow(true);

                  // setActive(active)  // commented due to side effects
                  setModalOnSubmit(() => () => {
                    if (props.onTogglePlanActivate)
                      props.onTogglePlanActivate(active)
                  })
                }
              })
              .catch((error) => {
                setWaiting(false)

                setModalText(error + " ");
                setModalShow(true);

                setModalOnSubmit(() => () => {
                  if (props.onTogglePlanActivate)
                    props.onTogglePlanActivate(prevActive)
                })
              })
              .then(function () {
                // always executed
              });
          }

          isAuth(callbackFunction)
        }
      })
    }
  }

  return (
    props.plan.officeplan
      ? <div className="relative">
        <Modal
          id="modal"
          text={modalText}
          show={modalShow}
          toggleModal={toggleModal}

          modalSubmitButtonText={modalSubmitButtonText}

          onSubmit={modalOnSubmit}
          onClose={modalOnClose}
        />
        <div className='absolute bg-brand -right-2 -top-2 w-2/3 h-1/2 z-0 rounded-lg'></div>
        <div className={"relative bg-gray-200 w-auto h-auto rounded-lg flex flex-col p-4"}>
          <Img
            className='w-full h-60 object-cover rounded-xl min-w-14'
            src={props.plan.officeplan.file && props.plan.officeplan.file.filename
              ? `${config.get('cdnUrl')}/${props.plan.officeplan.file.filename}`
              : props.plan.officeplan && props.plan.officeplan.file
                ? props.plan.officeplan.file.src
                : ""}
            alt=""
          />
          <div className='flex flex-col mt-6'>
            <JustifyBetween
              left={
                <div className='justify-items-start'>
                  <p className='text-sm text-gray-500'>{t("Plan Name")}</p>
                  <h3 className="text-base 2xl:text-xl font-medium text-gray-900 mb-2">
                    {props.plan.officeplan.name}
                    <p className="text-xs 2xl:text-base text-gray-500">
                      {props.plan.officeplan.location && props.plan.officeplan.location.name}
                    </p>
                  </h3>
                </div>
              }

              right={
                <div className='text-right'>
                  <p className='text-sm text-gray-500'>{t("Price")}</p>
                  <h3 className="text-base 2xl:text-xl font-medium text-gray-900 mb-2 ml-auto">
                    {`${props.plan.officeplan.price} €`}
                  </h3>
                </div>
              }
            />
            <div className="flex space-x-2 2xl:space-x-3">
              <p className="text-xs 2xl:text-base text-gray-500">
                {props.plan.officeplan.description}
              </p>
            </div>
          </div>
          <div className='flex flex-col mt-10'>
            <JustifyBetween
              left={
                <div className='justify-items-start'>
                  <p className='text-sm text-gray-500'>{t("Starting date")}</p>
                  <h3 className="text-base 2xl:text-xl font-medium text-gray-900 mb-2">
                    {props.plan.started_at ? moment.utc(props.plan.started_at, 'YYYY-MM-DD').local().format("YYYY-MM-DD") : "-"}
                  </h3>
                  <p className='text-sm text-gray-500'>{t("Expiration date")}</p>
                  <h3 className="text-base 2xl:text-xl font-medium text-gray-900 mb-2">
                    {props.plan.expired_at ? moment.utc(props.plan.expired_at, 'YYYY-MM-DD').local().format("YYYY-MM-DD") : "-"}
                  </h3>
                </div>
              }

              right={
                props.plan && props.plan.officeplan && props.plan.officeplan.duration === "monthly"
                  ? <div className='justify-items-end text-right'>
                    <p className='text-sm text-gray-500'>{" "}</p>
                    <h3 className="text-base 2xl:text-xl font-medium text-gray-900 mb-2">
                      {" "}
                    </h3>
                    <p className='text-sm text-gray-500'>{t("Plan Status")}{active ? ": active " : ""}</p>
                    {!props.plan.officeplan.sales
                      && <Button
                        onClick={() => { onTogglePlanActivate(!active) }}
                        className={`px-2 py-0 rounded-full text-base ${active ? "bg-forth" : "bg-gray-300 cursor-not-allowed"}`}
                        text={active ? "click to deactive" : "deactive"}

                        disabled={!active}

                        waiting={waiting}
                      />
                    }
                    {/* <ToggleButton
                      field={{
                        value: active,
                        initialValue: active
                      }}

                      className="ml-auto"
                      switchClassName="bg-white ring-1 ring-forth"

                      onChange={(value) => { onTogglePlanActivate(value) }}

                      left={""}
                      right={""}

                      disabled={!active}
                    /> */}
                  </div>
                  : ""
              }
            />
          </div>
        </div>
      </div>
      : ""
  )
}
