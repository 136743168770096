import config from 'react-global-configuration';

import React, { useState, useEffect, createContext } from "react";
import axios from "axios";
import Cookie from 'js-cookie';

const cookieAccessTokenWildCard = `orangery-${process.env.REACT_APP_ENVIRONMENT}-access-token`;
const localRefreshTokenWildCard = `orangery-${process.env.REACT_APP_ENVIRONMENT}-refresh-token`;
const localRememberMeWildCard = `orangery-${process.env.REACT_APP_ENVIRONMENT}-remember-me`;

function readCookie(cookieWildCard) {
  var thisCookieValue = null;

  var allCookies = Cookie.get();
  if (allCookies[cookieWildCard])
    thisCookieValue = allCookies[cookieWildCard];

  try {
    return JSON.parse(thisCookieValue)
  } catch (err) {
    return thisCookieValue
  }
}

const generals = require("../generals")

const AuthContext = createContext();
export { AuthContext };

const AuthContextProvider = (props) => {
  // const [accessToken, setAccessToken] = useState(null);
  const [accessToken, setAccessToken] = useState(readCookie(cookieAccessTokenWildCard));  ////////!!!!!!!! readCookie(cookieAccessTokenWildCard)
  const [user, setUser] = useState(null)

  function writeAccessToken(accessToken) {
    setAccessToken(accessToken)
    Cookie.set(cookieAccessTokenWildCard, accessToken, { domain: config.get('mainDomain') });
  }

  function writeRefreshToken(refreshToken) {
    localStorage.setItem(localRefreshTokenWildCard, refreshToken);
  }

  function writeRememberMe(rememberMe) {
    localStorage.setItem(localRememberMeWildCard, rememberMe);
  }

  function getUser(accessToken) {
    let url = `${config.get('apiUrl')}/getuserbytoken`;

    let configs = {
      headers: {
        'x-access-token': accessToken,
        timeout: config.get('apiCallTimeout')
      },
      params: {
        // page: f + 1
      },
    }
    axios.get(url, configs)
      .then((response) => {
        if (response.data.result) {
          const user = response.data.data;

          setUser(user);
        }
      })
      .catch((error) => {
        console.error(error);

        setUser(null);
      })
      .then(function () {
        // always executed
      });
  }

  function logout(redirectToLogin = true) {
    writeRefreshToken(null);
    writeAccessToken(null);
    writeRememberMe(false);

    ////////!!!!!!!!
    if (redirectToLogin
      && window.location.href.indexOf("/login") === -1
      && window.location.href.indexOf("/payment_result") === -1
      && window.location.href.indexOf("/reset_password") === -1
      && window.location.href.indexOf("/confirm_email") === -1
      && window.location.href.indexOf("/signup") === -1
    )
      generals.goTo(`${config.get('homeUrl')}/login`);
  }

  function login(accessToken, refreshToken, rememberMe, shouldGetUser, callbackFunction = null) {
    writeAccessToken(accessToken)

    if (refreshToken !== null)
      writeRefreshToken(refreshToken)

    if (rememberMe !== null)
      writeRememberMe(rememberMe)

    if (accessToken && shouldGetUser)
      getUser(accessToken)

    if (callbackFunction !== null) {
      callbackFunction()
    }
  }

  function isAuth(callbackFunction = null) {
    let url = `${config.get('apiUrl')}/istokenvalid`;
    let data = {}
    let headers = {
      'x-access-token': accessToken,
      timeout: config.get('apiCallTimeout')
    }
    axios.post(url, data, { headers })
      .then((response) => {
        if (response.data.result) {
          if (callbackFunction !== null) {
            callbackFunction()
          }
        } else {
          let url = `${config.get('apiUrl')}/newtoken`;
          let data = {}
          let headers = {
            'x-refresh-token': localStorage.getItem(localRefreshTokenWildCard),
            timeout: config.get('apiCallTimeout')
          }
          axios.post(url, data, { headers })
            .then((response) => {
              if (response.data.result) {
                writeAccessToken(response.data.data.access_token)

                if (callbackFunction !== null)
                  callbackFunction()
              } else {
                logout(true)
              }
            })
            .catch((error) => {
              console.error(error);
              logout(true)
            })
        }
      })
      .catch((error) => {
        console.error(error);
        logout(true)
      })
  }

  useEffect(() => {
    const callbackFunction = () => {
      if (window.location.href.indexOf("/dashboard") === -1
        && window.location.href.indexOf("/checkout") === -1
      )
        generals.goTo(`${config.get('homeUrl')}/dashboard`);
    }
    isAuth(() => { login(readCookie(cookieAccessTokenWildCard), null, null, true, callbackFunction); })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        accessToken: accessToken,
        user: user,
        setUser: setUser,
        login,
        logout,
        isAuth
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
