import config from 'react-global-configuration';

/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ....
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

// contexts
import { AuthContext } from '../../context/authContext';

import { useTranslation } from 'react-i18next';

import { useState, useContext, useEffect } from 'react'
import axios from 'axios';

import {
  Folder,
  Bag,
  Calendar,
  // Buy,
  // TimeSquare
} from 'react-iconly'

import {
  SelectableBadges, Tabs, AssetsList,
  ShoppingCart, PlanLargeVerticalCard,
  PlanMediumCard, AssetSmallCard, PlaceHolder,
  // OfficePlanCard,
  // DropDown,
  // Ribbon
} from '../../components'
// import {
//   BookAMeetingRoomForm
// } from '../../implementedComponents'

const generals = require("../../generals")

export default function MyOfficeNav(props) {
  const { accessToken, isAuth } = useContext(AuthContext)
  const { t } = useTranslation();

  const [plans, setPlans] = useState([]);
  // let plans = props.plans.length > 0 ? props.plans.slice(1) : [];

  let { currentPlan, setCurrentPlan } = props

  let { selectedAssets, setSelectedAssets } = props

  // const [assets, setAssets] = useState(props.assets);
  const [assets, setAssets] = useState([]);
  const [assetCategories, setAssetCategories] = useState([]);

  // const [assignedAssets, setAssignedAssets] = useState(props.assignedAssets ? props.assignedAssets : []);
  // const [boughtAssets, setBoughtAssets] = useState(props.boughtAssets ? props.boughtAssets : []);
  const [assignedAssets, setAssignedAssets] = useState([]);
  const [boughtAssets, setBoughtAssets] = useState([]);

  const [isMonthlyPrivatePlanActive, setIsMonthlyPrivatePlanActive] = useState(
    currentPlan
      && currentPlan.officeplan
      && currentPlan.officeplan.duration === "monthly"
      && currentPlan.officeplan.office_type === "private"
      && currentPlan.active !== undefined
      ? currentPlan.active
      : false
  );

  // console.log({ currentPlan: currentPlan })
  // console.log({ isMonthlyPrivatePlanActive: isMonthlyPrivatePlanActive })

  const [currentLocationId, setCurrentLocationId] = useState(props.selectedLocationId ? props.selectedLocationId : null);

  useEffect(() => {
    let tempPlans = [];
    let tempPlan = null;
    if (plans && Array.isArray(plans) && plans.length > 0) {
      tempPlans = plans.filter(plan => plan.officeplan && plan.officeplan.office_type === "private" && plan.officeplan.duration === "monthly" && plan.active)
      if (tempPlans.length > 0)
        tempPlan = tempPlans[0]
      else {
        tempPlans = plans.filter(plan => plan && plan.officeplan)

        if (tempPlans.length > 0)
          tempPlan = tempPlans[0]
        else { }
      }
    }

    setCurrentPlan(tempPlan);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plans])

  useEffect(() => {
    if (currentPlan) {
      loadBoughtAssets(currentPlan.id);

      if (currentPlan.officeplan) {
        loadAssignedAssets(currentPlan.officeplan.id);
        setCurrentLocationId(currentPlan.officeplan.location_id)
        // setSelectedLocationId(currentPlan.officeplan.location_id)

        setIsMonthlyPrivatePlanActive(
          currentPlan.officeplan.duration === "monthly"
            && currentPlan.officeplan.office_type === "private"
            && currentPlan.active !== undefined
            ? currentPlan.active
            : false
        );
      }
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPlan])

  useEffect(() => {
    loadAssets(currentLocationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocationId])

  function loadPlansByAccessToken() {
    const callbackFunction = () => {
      let url;
      url = `${config.get('apiUrl')}/getplansbytoken`;

      let configs = {
        headers: {
          'x-access-token': accessToken,
          timeout: config.get('apiCallTimeout')
        },
        params: {
          // page: f + 1
        },
      }
      axios.get(url, configs)
        .then((response) => {
          if (response.data.result) {
            const plans = response.data.data;

            if (Array.isArray(plans) && plans.length > 0)
              setPlans(plans);
            // else
            //   generals.goTo(`${config.get('landingUrl')}/home`, 0)
          } else {
            ////////!!!!!!!!
          }
        })
        .catch((error) => {
          console.error(error);
          setPlans([]);
        })
        .then(function () {
          // always executed
        });
    }

    isAuth(callbackFunction)
  }


  // eslint-disable-next-line no-unused-vars
  // const [selectedAssetCategories, setSelectedAssetCategories] = useState([]);
  // const [selectedAssets, setSelectedAssets] = useState(props.selectedAssets && Array.isArray(props.selectedAssets)
  //   ? props.selectedAssets
  //   : []
  // );

  const [filteredAssetsList, setfilteredAssetsList] = useState(null);

  // const [officePlanCardsActive, setOfficePlanCardsActive] = useState(true)
  // const [locationInfo, setLocationInfo] = useState(null);

  // const [locations, setLocations] = useState(null);

  useEffect(() => {
    loadAssetCategories()
    loadPlansByAccessToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // useEffect(() => {
  //   setBoughtAssets(props.boughtAssets)
  // }, [props.boughtAssets])

  // useEffect(() => {
  //   setAssignedAssets(props.assignedAssets)
  // }, [props.assignedAssets])

  // useEffect(() => {
  //   setAssets(props.assets)
  //   setfilteredAssetsList(props.assets)
  // }, [props.assets])

  useEffect(() => {
    setfilteredAssetsList(assets)
  }, [assets])

  // useEffect(() => {
  //   setCurrentPlan(props.currentPlan ? props.currentPlan : null);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.currentPlan])

  // useEffect(() => {
  //   setPrivatePlanActive(currentPlan && currentPlan.active !== undefined ? currentPlan.active : false);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentPlan && currentPlan.active])

  function onTogglePlanActivate(value) {
    loadPlansByAccessToken();

    // setIsMonthlyPrivatePlanActive(value);
    // loadAssignedAssets();
    // loadBoughtAssets(currentPlan.id);
  }

  function onSubscribeAsset() {
    loadBoughtAssets(currentPlan.id);
    // loadLocationInfo(props.locationId)
    loadAssets(props.locationId)
  }

  function onChangeCurrentPlan(plan) {
    setCurrentPlan(plan)
  }

  function loadAssets(locationId = null) {
    if (locationId && locationId !== -1) {
      const callbackFunction = () => {
        let url = `${config.get('apiUrl')}/getassetsbylocation?location_id=${locationId}`;

        let configs = {
          headers: {
            'x-access-token': accessToken,
            timeout: config.get('apiCallTimeout')
          },
          params: {
            // page: f + 1
          },
        }

        axios.get(url, configs)
          .then((response) => {
            const data = response.data.data;

            setAssets(data);
          })
          .catch((error) => {
            console.error(error);
            setAssets([]);
          })
          .then(function () {
            // always executed
          });
      }

      isAuth(callbackFunction)
    }
  }

  function loadAssetCategories() {
    const callbackFunction = () => {
      let url = `${config.get('apiUrl')}/getassetcategories`;
      let configs = {
        headers: {
          'x-access-token': accessToken,
          timeout: config.get('apiCallTimeout')
        },
        params: {
          // page: f + 1
        },
      }
      axios.get(url, configs)
        .then((response) => {
          let data = response.data.data;
          if (response.data.result) {
            let tempList = generals.toList(data, "id", "type");
            setAssetCategories(tempList);
          }
        })
        .catch((error) => {
          console.error(error);
          setAssetCategories([]);
        })
        .then(function () {
          // always executed
        });
    }

    isAuth(callbackFunction)

    // let data = [
    //   {
    //     id: 1,
    //     type: "seating",
    //   },
    //   {
    //     id: 2,
    //     type: "lighting"
    //   }
    // ];
    // let tempList = generals.toList(data, "id", "type");
    // setAssetCategories(tempList);
  }

  function loadAssignedAssets(officePlanId) {
    if (officePlanId && officePlanId !== -1) {
      const callbackFunction = () => {
        let url = `${config.get('apiUrl')}/getassignedassets?officeplan_id=${officePlanId}`;
        // let url = `${config.get('apiUrl')}/getassets`;  ////////!!!!!!!!
        let configs = {
          headers: {
            'x-access-token': accessToken,
            timeout: config.get('apiCallTimeout')
          },
          params: {
            // page: f + 1
          },
        }
        axios.get(url, configs)
          .then((response) => {
            let data = response.data.data;
            if (response.data.result) {
              setAssignedAssets(data);
            }
          })
          .catch((error) => {
            console.error(error);
            setAssignedAssets([]);
          })
          .then(function () {
            // always executed
          });
      }

      isAuth(callbackFunction)
    }
  }

  function loadBoughtAssets(planId) {
    if (planId && planId !== -1) {
      const callbackFunction = () => {
        let url = `${config.get('apiUrl')}/getboughtassets?plan_id=${planId}`;
        // let url = `${config.get('apiUrl')}/getassets`;  ////////!!!!!!!!
        let configs = {
          headers: {
            'x-access-token': accessToken,
            timeout: config.get('apiCallTimeout')
          },
          params: {
            // page: f + 1
          },
        }
        axios.get(url, configs)
          .then((response) => {
            let data = response.data.data;
            if (response.data.result) {
              setBoughtAssets(data);
            }
          })
          .catch((error) => {
            console.error(error);
            setBoughtAssets([]);
          })
          .then(function () {
            // always executed
          });
      }

      isAuth(callbackFunction)
    }
  }

  // function onOfficePlanSubmitting(value) {
  //   setOfficePlanCardsActive(!value)
  // }

  // function loadLocationInfo(locationId) {
  //   let getRequested = false;
  //   if (!getRequested) {
  //     getRequested = true;

  //     const callbackFunction = () => {
  //       let url = `${config.get('apiUrl')}/getlocationinfo?location_id=${locationId}`;
  //       let configs = {
  //         headers: {
  //           'x-access-token': accessToken,
  //           timeout: config.get('apiCallTimeout')
  //         },
  //         params: {
  //           // page: f + 1
  //         },
  //       }
  //       axios.get(url, configs)
  //         .then((response) => {
  //           let data = response.data.data;
  //           // data.communitymanager.description = "Your contact person in Hildesheim"; ////////!!!!!!!!
  //           // data.communitymanager.image = managerImageSrc; ////////!!!!!!!!
  //           // data.officeplans = [  ////////!!!!!!!!
  //           //   {
  //           //     id: 1,
  //           //     price: 123,
  //           //     name: "test office",
  //           //   },
  //           //   {
  //           //     id: 2,
  //           //     price: 1234,
  //           //     name: "test office plan 2",
  //           //   }
  //           // ]

  //           setLocationInfo(data);
  //         })
  //         .catch((error) => {
  //         })
  //         .then(function () {
  //           // always executed
  //         });
  //     }

  //     isAuth(callbackFunction)
  //   }
  // }

  // function loadLocations() {
  //   let getRequested = false;
  //   if (!getRequested) {
  //     getRequested = true;

  //     const callbackFunction = () => {
  //       let url = `${config.get('apiUrl')}/getlocations`;
  //       let configs = {
  //         headers: {
  //           'x-access-token': accessToken,
  //           timeout: config.get('apiCallTimeout')
  //         },
  //         params: {
  //           // page: f + 1
  //         },
  //       }
  //       axios.get(url, configs)
  //         .then((response) => {
  //           let data = response.data.data;
  //           if (response.data.result) {
  //             let tempList = generals.toList(data, "id", "name");
  //             setLocations(tempList);
  //             // config.set({ locations: tempList }, { freeze: false, environment: 'notFreeze' })
  //             // config.setEnvironment('notFreeze');
  //           }
  //         })
  //         // .catch((error) => {
  //         //   config.set("locations", [])
  //         // })
  //         .then(function () {
  //           // always executed
  //         });
  //     }

  //     isAuth(callbackFunction)
  //   }
  // }

  // useEffect(() => {
  //   loadLocations()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  // const [selectedLocationId, setSelectedLocationId] = useState(currentLocationId !== null
  //   ? currentLocationId
  //   : locations && Array.isArray(locations) && locations.length > 0
  //     ? locations[0].value
  //     : undefined
  // )
  // useEffect(() => {
  //   if (selectedLocationId)
  //     loadLocationInfo(selectedLocationId)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedLocationId])

  // const [selectedLocation, setSelectedLocation] = useState(null)
  // useEffect(() => {
  //   if (!selectedLocationId)
  //     setSelectedLocationId(
  //       locations && Array.isArray(locations) && locations.length > 0
  //         ? locations[0].value
  //         : undefined
  //     )

  //   let tempLocations = null;
  //   if (locations) {
  //     tempLocations = locations.filter(location => location.value === selectedLocationId)
  //     setSelectedLocation(tempLocations.length > 0 ? tempLocations[0] : null)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [locations, selectedLocationId])


  // function onSelectedLocationChange(fieldName, value) {
  //   setLocationInfo(null);
  //   setSelectedLocationId(value);
  // }

  // useEffect(() => {
  //   setSelectedLocationId(props.locationId);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props.locationId])

  const tabs = {
    myAssets: {
      key: 'myAssets',
      title: t('My Assets'),
      href: '#',
      icon: Folder,
      content:
        isMonthlyPrivatePlanActive
          ? <>
            <h3 className="text-base 2xl:text-xl font-medium text-gray-900 mb-2">
              <span className='font-normal'>{t("Assets are for this plan")}:</span>{" "}{currentPlan && currentPlan.officeplan && currentPlan.officeplan.name}{" "}<span className="text-gray-600 text-sm font-medium">({t("change from Subscription tab")})</span>
            </h3>
            {
              <div className='grid grid-cols-1 space-y-10'>
                <div className='col-span-1'>
                  <p className='text-base 2xl:text-xl mb-4'>{t("My office items")}</p>
                  <ul className="w-full scrollbar-default sm:scrollbar-hide flex overflow-x-auto whitespace-nowrap p-4">
                    {assignedAssets.length < 1 &&
                      <div className='w-full text-center align-middle'>
                        <PlaceHolder
                          text={t("No assets available")}
                        />
                      </div>
                    }
                    {assignedAssets.map((asset, index) => (
                      <li
                        key={index}
                      >
                        <AssetSmallCard
                          className="w-72 2xl:w-96 bg-yellow-100 p-3 mr-6 ml-0"
                          imageClassName="w-28 h-28 bg-white"

                          asset={asset}

                          showPrice={false}
                          comment={t("Including in your plan")}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
                <div className='col-span-1'>
                  <p className='text-base 2xl:text-xl mb-4'>{t("Additional items")}</p>
                  <ul className="w-full scrollbar-default sm:scrollbar-hide flex overflow-x-auto whitespace-nowrap p-4">
                    {boughtAssets.length < 1 &&
                      <div className='w-full text-center align-middle'>
                        <PlaceHolder
                          text={t("No assets available")}
                        />
                      </div>
                    }
                    {boughtAssets.map((asset, index) => (
                      <li
                        key={(asset.product_id ? asset.product_id : "") + "-" + asset.id + "-" + Math.random()}
                      >
                        <AssetSmallCard
                          className="w-72 2xl:w-96 bg-yellow-100 p-3 mr-6 ml-0"
                          imageClassName="w-28 h-28 bg-white"

                          asset={asset}

                          plan_id={currentPlan.id}
                          hasMenu={true}

                          onSubscribeAsset={onSubscribeAsset}

                          showPrice={true}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            }
          </>
          : ""
    },
    shop: {
      key: 'shop',
      title: t('Shop'),
      href: '#',
      icon: Bag,
      content:
        isMonthlyPrivatePlanActive && filteredAssetsList !== null
          ? <>
            <h3 className="text-base 2xl:text-xl font-medium text-gray-900 mb-2">
              <span className='font-normal'>{t("You are buying assets for this plan")}:</span>{" "}{currentPlan && currentPlan.officeplan && currentPlan.officeplan.name}{" "}<span className="text-gray-600 text-sm font-medium">({t("change from Subscription tab")})</span>
            </h3>
            {!props.shoppingCartShow &&
              <>
                <SelectableBadges
                  items={assetCategories}

                  onChange={onAssetCategoriesChange}
                />
                <AssetsList
                  assets={filteredAssetsList}
                  selectedAssets={selectedAssets}

                  onChange={onSelectedAssetsChange}
                />
              </>
            }

            {props.shoppingCartShow &&
              <ShoppingCart
                planId={currentPlan.id}

                assets={selectedAssets}

                toggleShow={props.toggleShopppingCartShow}

                onItemDelete={onItemDelete}
              />
            }
          </>
          : ""
    },
    subscription: {
      key: 'subscription',
      title: t('Subscription'),
      href: '#',
      icon: Calendar,
      content: currentPlan && <>
        {
          <div className='grid grid-cols-1 lg:grid-cols-2 space-x-0 lg:space-x-10 space-y-10 lg:space-y-0'>
            <div className='col-span-1'>
              <p className='text-base 2xl:text-xl mb-4'>{t("My current plan")}</p>
              {currentPlan && currentPlan.id
                ? <PlanLargeVerticalCard
                  plan={currentPlan}
                  // active={hasActiveMonthlyPrivatePlan}

                  onTogglePlanActivate={onTogglePlanActivate}
                />
                : ""
              }
            </div>
            <div className='col-span-1'>
              <p className='text-base 2xl:text-xl mb-4'>{t("History")}</p>
              <div className='space-y-4'>
                {plans.length > 0
                  ? plans.map(plan => (
                    <PlanMediumCard
                      key={plan.id}
                      plan={plan}

                      isCurrentPlan={plan.id === currentPlan.id}

                      onChangeCurrentPlan={onChangeCurrentPlan}
                    />))
                  : <div className='w-full text-center align-middle'>
                    <PlaceHolder
                      text={t("No history available")}
                    />
                  </div>
                }
              </div>
            </div>
          </div>
        }
      </>
    },
    // bookAPlan: {
    //   key: 'bookAPlan',
    //   title: t('Book a Plan'),
    //   href: '#',
    //   icon: Buy,
    //   content: <div className='flex flex-col'>
    //     <div className='mx-4 mb-3'>
    //       <DropDown
    //         field={{
    //           label: "",
    //           id: "location",
    //           name: "location",
    //           type: "select",
    //           placeholder: t("Select location"),

    //           valid: true,
    //           value: selectedLocationId ? selectedLocationId : "",
    //           initialValue: selectedLocationId ? selectedLocationId : "",

    //           items: locations ? locations : [],

    //           key: "location",
    //           trimmable: false,
    //           required: false,

    //           inputClassName: 'text-left w-72 h-12',
    //           // w-32 2xl:w-44
    //           className: "w-72 h-12",
    //         }}

    //         onChange={onSelectedLocationChange}
    //       />
    //     </div>
    //     {selectedLocationId && locationInfo && locationInfo.officeplans && Array.isArray(locationInfo.officeplans) && locationInfo.officeplans.length > 0
    //       ? <div className="w-full flex flex-wrap">
    //         {locationInfo.officeplans.map((officePlan) => (
    //           <div
    //             key={officePlan.id}
    //             className='basis-full md:basis-1/2 lg:basis-1/3 2xl:basis-1/3 px-3 py-3'
    //           >
    //             <OfficePlanCard
    //               officePlan={officePlan}

    //               ribbon={officePlan.salse
    //                 ? <Ribbon text={t("Angebot")} />
    //                 : ""
    //               }

    //               accessToken={accessToken}
    //               locationId={selectedLocationId}

    //               onSubmitting={onOfficePlanSubmitting}
    //               active={officePlanCardsActive}

    //             // disabled={currentPlan && currentPlan.officeplan
    //             //   && currentPlan.officeplan.office_type === "private"
    //             //   && currentPlan.officeplan.duration === "monthly"
    //             //   && currentPlan.active
    //             //   && officePlan.office_type === "private"
    //             //   && officePlan.duration === "monthly"
    //             // }
    //             />
    //           </div>
    //         ))}
    //       </div>
    //       : <PlaceHolder
    //         text={t("No plans available")}
    //       />
    //     }
    //   </div>
    // },
    // bookAMeetingRoom: {
    //   key: 'bookAMeetingRoom',
    //   title: t('Book a Meeting Room'),
    //   href: '#',
    //   icon: TimeSquare,
    //   content: selectedLocationId && <div className='flex flex-col'>
    //     <div className='mx-4 mb-3'>
    //       <DropDown
    //         field={{
    //           label: "",
    //           id: "location",
    //           name: "location",
    //           type: "select",
    //           placeholder: t("Select location"),

    //           valid: true,
    //           value: selectedLocationId ? selectedLocationId : "",
    //           initialValue: selectedLocationId ? selectedLocationId : "",

    //           items: locations ? locations : [],

    //           key: "location",
    //           trimmable: false,
    //           required: false,

    //           inputClassName: 'text-left w-72 h-12',
    //           // w-32 2xl:w-44
    //           className: "w-72 h-12",
    //         }}

    //         onChange={onSelectedLocationChange}
    //       />
    //     </div>
    //     {selectedLocationId && locationInfo && locationInfo.meetingrooms && Array.isArray(locationInfo.meetingrooms) && locationInfo.meetingrooms.length > 0
    //       ? <div className='relative flex flex-col md:w-200 sm:w-112 max-w-full h-full max-h-screen'>
    //         <div className=''>
    //           <BookAMeetingRoomForm
    //             meetingRooms={locationInfo.meetingrooms}
    //             locationId={selectedLocationId}
    //             locationName={selectedLocation ? selectedLocation.label : ""}
    //           />
    //         </div>
    //       </div>

    //       : <PlaceHolder
    //         text={t("No meeting rooms available")}
    //       />
    //     }
    //   </div>
    // }
  }

  function onAssetCategoriesChange(values) {
    // setSelectedAssetCategories(values);
    let filteredAssets;
    if (values.length > 0 && assets && Array.isArray(assets))
      // filteredAssets = props.assets.filter(item => values.map(value => JSON.stringify(item).toLowerCase().includes(value.toLowerCase()).length > 0));
      filteredAssets = assets.filter(item => values.filter(value => value.toLowerCase() === item.assetcat.type.toLowerCase()).length > 0);
    else
      filteredAssets = assets;

    setfilteredAssetsList(filteredAssets)
  }

  function onSelectedAssetsChange(value) {
    setSelectedAssets(value);

    if (props.onSelectedAssetsChange)
      props.onSelectedAssetsChange(value)
  }

  function onItemDelete(itemId) {    ////////!!!!!!!! itemId should better to be itemKey
    onSelectedAssetsChange(selectedAssets.filter(item => item.id !== parseInt(itemId)))
  }

  return (
    <Tabs
      tabs={tabs}

      currentTab={props.currentTab ? props.currentTab : ""}
    />
  )
}
