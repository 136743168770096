/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";

import PlaceHolderImage from "../../assets/images/placeholder/placeholder-image.jpg";
// import PlaceHolderErrorImage from "../../assets/images/placeholder/placeholder-error-image.jpg";

// const { DefaultLoader } = require("../../components")

export default function Img({ src, placeholderImg, errorImg, alt, ...props }) {
  const [imgSrc, setImgSrc] = useState(null);
  const [imgStatus, setImgStatus] = useState("notLoaded");

  if (!placeholderImg)
    placeholderImg = PlaceHolderImage;

  if (!errorImg)
    errorImg = PlaceHolderImage;
  // errorImg = PlaceHolderErrorImage;

  useEffect(() => {
    const img = new Image();
    img.src = src;

    img.addEventListener("load", () => {
      setImgSrc(src);
      setImgStatus("loaded")
    });

    img.addEventListener("error", () => {
      setImgSrc(null);
      setImgStatus("notFound")
    });
  }, [src]);

  return (<>
    {src && imgStatus === "loaded"
      ? <img {...props} alt={alt ? alt : "orangery"} src={imgSrc} />
      : <img {...props} alt={alt ? alt : "orangery"} src={placeholderImg} />
      // : <DefaultLoader />
    }
  </>
  );
  // <img
  //   className={props.className}
  //   src={props.image}
  //   alt={props.alt}
  //   aria-hidden={props['aria-hidden']}
  // />
};
