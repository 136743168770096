// contexts
import config from 'react-global-configuration';
import { AuthContext } from '../../../../context/authContext';
import { useTranslation } from 'react-i18next';

import { Fragment, useState, useEffect, useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Cookie from 'js-cookie'

import NavButton from './components/navButton'
import {
  Logo, Back, Avatar,
  ButtonMenu
} from '../../../../components'

import { Upload } from 'react-iconly'
import {
  HiMenuAlt2,
  HiX,
  // HiOutlineSearch
} from 'react-icons/hi'

// const cookieCurrentNavWildCard = `orangery-${config.get('env')}-current-nav`;
const cookieCurrentNavWildCard = `orangery-app-current-nav`;

function readCookie(cookieWildCard) {
  var thisCookieValue = null;

  var allCookies = Cookie.get();
  if (allCookies[cookieWildCard])
    thisCookieValue = allCookies[cookieWildCard];

  try {
    return JSON.parse(thisCookieValue)
  } catch (err) {
    return thisCookieValue
  }
}

export default function Dashboard(props) {
  const { user, logout } = useContext(AuthContext)
  const { t } = useTranslation();

  const [sidebarOpen, setSidebarOpen] = useState(false)

  const navFromCookie = readCookie(cookieCurrentNavWildCard);
  let firstNavWithContent = Object.values(props.navigation).filter(item => item.content !== "")[0];
  let tempCurrentNav =
    props.currentNav && props.navigation[props.currentNav] !== undefined && props.navigation[props.currentNav].content !== ""
      ? props.currentNav
      : navFromCookie && props.navigation[navFromCookie] && props.navigation[navFromCookie].content !== ""
        ? navFromCookie
        : firstNavWithContent ? firstNavWithContent.key : ""
  const [currentNav, setCurrentNav] = useState(tempCurrentNav)

  function switchNav(currentNav) {
    setCurrentNav(currentNav)
    Cookie.set(cookieCurrentNavWildCard, currentNav)
  }

  useEffect(() => {
    switchNav(tempCurrentNav)
  }, [tempCurrentNav])  ////////!!!!!!!! not working!


  const logoutButton = <NavButton
    item={{
      key: 'logOut',
      title: 'Log Out',
      href: '#',
      sticky: 'bottom',
      icon: Upload,
    }}
    onClick={(e) => { if (e) e.preventDefault(); logout() }}
  />

  return (
    <>
      {/*
        Requires updating your template:

        ```
        <html class="h-full">
        <body class="h-full">
        ```
      */}
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="fixed inset-0 z-40 flex md:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative max-w-xs w-full bg-white pt-5 pb-4 flex-1 flex flex-col">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <span className="h-6 w-6 text-white" aria-hidden="true">
                        <HiX size={28} />
                      </span>
                    </button>
                  </div>
                </Transition.Child>
                <div className="shrink-0 px-4 flex items-center">
                  <Logo />
                </div>
                <div className="mt-5 flex-1 h-0 overflow-y-auto">
                  <nav className="px-2 space-y-1">
                    {Object.values(props.navigation).map((item) => (
                      <NavButton
                        key={item.key}
                        item={item}
                        active={item.key === currentNav}
                        onClick={(e) => { e.preventDefault(); switchNav(item.key); setSidebarOpen(false) }}
                      />
                    ))}
                  </nav>
                  <div className="px-2 space-y-1">
                    {logoutButton}
                  </div>
                </div>
              </div>
            </Transition.Child>
            <div className="shrink-0 w-14">{/* Dummy element to force sidebar to shrink to fit close icon */}</div>
          </Dialog>
        </Transition.Root>

        <div className="flex flex-row h-screen">
          {/* Static sidebar for desktop */}
          <div className="shrink-0 hidden md:flex md:flex-col basis-1/5">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="border-r border-gray-200 flex flex-col grow bg-white overflow-y-auto">
              <div className="shrink-0 px-4 flex items-center text-center">
                <Logo className="mx-auto mt-12 mb-0" />
              </div>
              <div className="grow mt-16 2xl:mt-25">
                <nav className="flex-1 px-3 xl:pl-4 2xl:pl-10 space-y-2 2xl:space-y-6">
                  {Object.values(props.navigation).map((item) => (
                    <NavButton
                      key={item.key}
                      item={item}
                      active={item.key === currentNav}
                      onClick={(e) => { e.preventDefault(); switchNav(item.key) }}
                    />
                  ))}
                </nav>
                {props.card}
                <div className="flex-1 px-3 xl:pl-4 2xl:pl-10 space-y-2 2xl:space-y-6 mt-10">
                  {logoutButton}
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col grow">
            <div className="sticky top-0 z-10 flex-shrink-0 bg-white border-b border-gray-200 flex h-16 md:h-20 2xl:h-32">
              <button
                type="button"
                className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">{t("Open sidebar")}</span>
                <HiMenuAlt2 className="h-6 w-6" aria-hidden="true" />
              </button>
              <div className="flex-1 flex justify-between px-2 md:px-5 2xl:px-10 py-2 md:py-3 2xl:py-7">
                <div className="hidden md:flex-1 md:flex">
                  {props.back
                    ? props.back
                    : <Back
                      className="text-lg 2xl:text-xl"
                      caption={props.navigation[currentNav].title}
                      subCaption=""

                      noArrow={true}
                    />
                  }
                </div>
                <div className="ml-auto flex items-center md:ml-6">
                  {props.statusIcon && <div className='mr-5'>{props.statusIcon}</div>}

                  <ButtonMenu
                    className="ml-3 relative"
                    srOnlyTitle={t("Open user menu")}
                    button={<Avatar
                      className="h-10 md:h-14 2xl:h-20 w-10 md:w-14 2xl:w-20 focus:outline-none"
                      // src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      src={user && user.file && user.file.filename
                        ? `${config.get('cdnUrl')}/${user.file.filename}`
                        : ""
                      }
                      size={32}
                    />
                    }
                    items={[
                      {
                        label: t("logout"),
                        onClick: logout ? logout : () => { },
                      }
                    ]}
                  />
                </div>
              </div>
            </div>

            <main className="flex flex-col grow bg-gray-100 overflow-y-hidden">
              <div className="min-h-full overflow-y-auto p-8 2xl:p-12 mx-0 rounded-none md:mx-3 lg:mx-4 xl:mx-5 2xl:mx-10 my-2 md:my-3 lg:my-4 xl:my-5 2xl:my-10 bg-white sm:rounded md:rounded-2xl">
                {props.navigation[currentNav].content}
              </div>
            </main>
          </div>
        </div>
      </div>
    </>
  )
}
