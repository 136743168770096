import config from 'react-global-configuration';

import { AuthContext } from "../../context/authContext";

import React, { Component, createRef } from 'react';
import axios from 'axios';
import { EditSquare } from 'react-iconly'

import { DropDown, TextBox, CheckBox, Modal, Button, Hidden, MyDatePicker } from '../../components';

// transition in uncompleted
// ref: https://www.youtube.com/watch?v=ztvNwFV0Ai0
// ref: https://www.w3schools.com/css/css3_transitions.asp
// ref: https://tailwindcss.com/docs/transition-property
// ref: http://reactcommunity.org/react-transition-group/css-transition
// ref: https://reactjs.org/docs/animation.html
// ref: https://www.youtube.com/watch?v=8E6J0ZfeyBU
// const styles = {
//   transition: 'all 10s ease-out 10s'
// }


export default class HorizontalForm extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props);

    this.accessToken = context.accessToken;
    this.isAuth = context.isAuth;

    let tempRefs = {};
    Object.keys(this.props.fields).forEach(fieldName => {
      tempRefs[fieldName] = createRef();
    });

    this.state = {
      waiting: false,

      modalShow: false,
      modalText: "",
      modalOnSubmit: () => { },

      fields: this.props.fields,
      fieldRefs: tempRefs,

      disabled: this.props.disabled,
    }
  }

  // need when using props for initializing state
  // static getDerivedStateFromProps(nextProps, prevState) {
  //   return {
  //     fields: nextProps.fields
  //   };
  // }

  // eslint-disable-next-line no-unused-vars
  onChange = (fieldName, value) => {
  }

  onEditButtonClick = () => {
    this.setState({ disabled: false })
  }

  validateForm = () => {
    let valid = true;

    Object.keys(this.state.fields).forEach(fieldName => {
      if (this.state.fields[fieldName].required) {
        valid = this.state.fieldRefs[fieldName].current.validate() && valid
      }
    });

    // if (valid)
    //   return data;
    // else
    //   return false;
    return valid;
  }

  getValue = () => {
    let value = null;

    if (this.props.hasFile) {
      value = new FormData()

      Object.values(this.state.fieldRefs).forEach(ref => {
        value.append(ref.current.props.field.name, ref.current.getValue());
      });
    } else {
      value = {}

      Object.values(this.state.fieldRefs).forEach(ref => {
        value[ref.current.props.field.name] = ref.current.getValue();
      });
    }

    return value;
  }

  getField = (field) => {
    switch (field.type) {
      case "email":
      case "password":
      case "text":
        return <TextBox
          // eslint-disable-next-line react/no-direct-mutation-state
          ref={el => { this.state.fieldRefs[field.name].current = el; }}

          key={field.key}

          field={field}

          disabled={this.state.disabled}
        />

      case "checkbox":
        return <CheckBox
          ref={this.state.fieldRefs[field.name]}
          key={field.key}

          field={field}

          disabled={this.state.disabled}
        />

      case "hidden":
        return <Hidden
          // eslint-disable-next-line react/no-direct-mutation-state
          ref={el => { this.state.fieldRefs[field.name].current = el; }}
          key={field.key}

          field={field}

          disabled={this.state.disabled}
        />

      case "select":
        return <DropDown
          ref={this.state.fieldRefs[field.name]}
          key={field.key}

          field={field}

          disabled={this.state.disabled}
        />

      case "date":
        return <MyDatePicker
          key={field.key}
          // eslint-disable-next-line react/no-direct-mutation-state
          ref={el => { this.state.fieldRefs[field.name].current = el; }}

          field={field}
        // field={{
        //   value: this.state.pickedDate
        // }}
        />

      default:
        return field.key;
    }
  }

  init = (forceValidate = false) => {
    Object.values(this.state.fieldRefs).forEach(ref => {
      if (ref.current) {
        ref.current.init(forceValidate);
      }
    });

    this.setState({ disabled: this.props.disabled })
  }

  // eslint-disable-next-line no-unused-vars
  reset = () => {
    Object.values(this.state.fieldRefs).forEach(ref => {
      ref.current.reset();
    });
  }

  toggleModal = (show) => {
    this.setState({ modalShow: show });
  }

  handleCancel = (e = null) => {
    if (e !== null)
      e.preventDefault();

    this.forceUpdate()

    this.init(false);

    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  handleSubmit = (e = null) => {
    if (e !== null)
      e.preventDefault();

    if (this.validateForm()) {
      let data = this.getValue();
      this.setState({ waiting: true });

      if (this.props.submitApiUrl !== "") {
        const callbackFunction = () => {
          let url = this.props.submitApiUrl;
          let headers = {
            'x-access-token': this.accessToken,
            timeout: config.get('apiCallTimeout')
          }
          if (this.props.hasFile) {
            headers["accept"] = "*/*";
            headers["Content-Type"] = "multipart/form-data;"
          }
          axios.post(url, data, { headers })
            .then(res => {
              this.setState({ waiting: false });

              this.setState({ modalShow: true });
              if (res.data.result) {
                this.setState({ modalText: res.data.message });

                this.init();

                this.setState({
                  modalOnSubmit: () => {
                    if (this.props.onSubmit) {
                      this.props.onSubmit(res.data.data);
                    }
                  }
                })
              } else {
                this.setState({ modalText: res.data.message });
              }
            })
            .catch(err => {
              this.setState({ waiting: false });

              this.setState({ modalText: err + " " });
              this.setState({ modalShow: true });
            });
        }

        this.isAuth(callbackFunction)
      } else {
        // if (this.props.onSubmit)
        //   this.props.onSubmit();
      }
    } else {
    }
  }

  componentDidMount() {
    this.init();
  }

  // need when using props for initializing state
  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.fields) !== JSON.stringify(this.props.fields)) {
      let tempRefs = {};
      Object.keys(nextProps.fields).forEach(fieldName => {
        tempRefs[fieldName] = createRef();
      });

      this.setState({
        fields: nextProps.fields,
        fieldRefs: tempRefs
      });
    }
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   return {
  //     valid: nextProps.field.valid,
  //     value: nextProps.field.value
  //   };
  // }

  render() {
    return (
      // className='transition-all font-body' style={{ ...styles, opacity: opacity }}>
      <div className={'relative' + (this.props.className ? " " + this.props.className : "")}>
        <Modal
          id="modal"
          text={this.state.modalText}
          show={this.state.modalShow}

          toggleModal={this.toggleModal}

          onSubmit={this.state.modalOnSubmit}
        />
        {!this.state.disabled
          ? ""
          : <div className='basis-full mt-3'>
            <Button
              onClick={this.onEditButtonClick}
              className="absolute -top-1 -right-1 w-8 h-8 rounded-full bg-gray-100"
              text={<EditSquare set="light" size={22} />}
            />
          </div>
        }
        {this.props.miniTitle &&
          <h2 className="mt-2 text-lg font-semibold text-gray-900">
            {this.props.miniTitle}
          </h2>
        }
        {this.props.title &&
          <div>
            {this.props.logo}
            {this.props.title &&
              <h2 className="mt-6 text-4xl font-bold text-gray-900">
                {this.props.title}
              </h2>
            }
            {this.props.subTitle &&
              <p className="mt-2 text-sm text-black">
                {this.props.subTitle}
              </p>
            }
          </div>
        }

        <div name='container' className="mt-6 2xl:mt-12">
          <form action="#" method="POST" className="flex flex-wrap">
            {Object.values(this.state.fields).map((field) => {
              return this.getField(field);
            })}

            {this.props.otherFields}

            {this.state.disabled
              ? ""
              : <div className='basis-full mt-3 px-2 flex justify-end space-x-2'>
                {this.props.cancellable &&
                  <Button
                    onClick={this.handleCancel}
                    className="w-44 h-12 2xl:w-64 2xl:h-14 2xl:text-2xl text-white bg-fifth hover:bg-fifth-900 font-medium rounded"
                    text={this.props.cancelButtonText}
                  />
                }
                <Button
                  onClick={this.handleSubmit}
                  className="w-44 h-12 2xl:w-64 2xl:h-14 2xl:text-2xl text-black bg-brand hover:bg-brand-900 font-medium rounded"
                  text={this.props.submitButtonText}
                  waitingText={this.props.submitButtonWaitingText}
                  waiting={this.state.waiting}
                />
              </div>
            }
          </form>
        </div>
      </div>
    )
  }
}