import config from 'react-global-configuration';

import { Component } from 'react';
import Dropzone from "react-dropzone";
// import axios from 'axios';

import { Img } from '../../components'

// import closeSquareSvg from "../assets/images/icons/Close Square.svg"

class DropZone extends Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
      valid: true,

      fileSrc: "",

      changeFile: false
    }
  }

  getValue = () => {
    let file = null;

    if (!this.props.field.initialValue || this.state.changeFile)
      file = this.state.file;

    return file;
  }

  validate = (inputFile = null) => {
    let file = inputFile != null ? inputFile : this.state.file
    let valid = true;

    if (this.props.field.initialValue && !this.state.changeFile)
      valid = true;
    else
      if (!file)
        valid = false;

    // else {
    //   // if (file.size > 000)
    //   //   valid = false
    //   // if (file.type != ... )
    //   //   valid = false
    // }

    this.setState({ valid: valid });

    return valid;
  }

  // need when using props for initializing state
  componentWillReceiveProps(nextProps) {
    if (nextProps.field.valid !== this.props.field.valid)
      this.setState({ valid: nextProps.field.valid });

    if (nextProps.field.value !== this.props.field.value)
      this.setState({ value: nextProps.field.value });
  }
  // static getDerivedStateFromProps(nextProps, prevState) {
  //   return {
  //     valid: nextProps.field.valid,
  //     value: nextProps.field.value
  //   };
  // }

  handleChange = (files, forceValidate = false) => {
    if (files.length > 0) {
      if (!forceValidate || this.validate(files[0])) {
        this.setState({
          file: files[0]
        });

        if (this.props.onChange)
          this.props.onChange(files[0])

        try {
          var reader = new FileReader();
          // eslint-disable-next-line no-unused-vars
          var url = reader.readAsDataURL(files[0]);

          reader.onloadend = (e) => {
            this.setState({ fileSrc: [reader.result] });
          };
        } catch (error) { }
      }
    }
  }

  handleChangeFile = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.setState({ changeFile: true })
  }

  setValue = (fileName, forceValidate = false) => {
    // not working
    // if (fileName) {
    //   const callbackFunction = () => {
    //     let url = `${config.get('cdnUrl')}/${fileName}`;
    //     axios.get(url, {
    //       responseType: 'blob'
    //     })
    //       .then((response) => response.blob())
    //       .then((blob) => {
    //         let file = blob;

    //         if (forceValidate) {
    //           if (this.validate(file))
    //             this.handleChange([file], forceValidate);
    //         } else {
    //           this.handleChange([file], forceValidate);
    //         }
    //       });
    //   }

    //   isAuth(callbackFunction)
    // }
  }

  init = (forceValidate = false) => {
    if (forceValidate === false)
      this.setState({ valid: true })

    if (this.props.field.initialValue !== undefined)
      this.setValue(this.props.field.initialValue, forceValidate)
  }

  reset = () => {
    if (this.props.field.emptyValue !== undefined)
      this.setValue(this.props.field.emptyValue, true)
  }

  render() {
    return (
      <>
        {this.props.field.initialValue && !this.state.changeFile && !this.props.minimal
          ? <section>
            <a
              href="."
              onClick={this.handleChangeFile}
              className={
                `dropzone flex flex-col justify-center  
                ${this.state.valid ? " border-border" : " border-red-500"} 
                items-center hover:bg-gray-100 place-content-center cursor-pointer 
                ${this.props.className ? this.props.className : " mt-1 p-2 2xl:p-3 3xl:p-4 border-2 border-dashed rounded-md self-stretch justify-self-stretch min-h-48"}`
              }
            >
              <Img
                className="rounded-lg grayscale-70 cursor-pointer justify-self-center max-h-20 w-full object-contain"
                src={`${config.get('cdnUrl')}/${this.props.field.initialValue}`}
              />
              <div className="text-sm text-gray-600 text-center">
                <p className="pr-1">to change image</p>
                <label
                  htmlFor="file"
                  className="relative cursor-pointer rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none"
                >
                  <span>click here</span>
                </label>
              </div>
              <p className="h-3 flex items-center font-medium tracking-wide text-red-500 text-xs 3xl:text-base mt-1 ml-1">
                {""}
              </p>
            </a>
          </section>
          : <Dropzone
            onDrop={(files) => this.handleChange(files, true)}
            multiple={false}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps({
                  className:
                    `dropzone flex flex-col justify-center  
                    ${this.state.valid ? " border-border" : " border-red-500"} 
                    items-center hover:bg-gray-100 place-content-center cursor-pointer 
                    ${this.props.className ? this.props.className : " mt-1 p-2 2xl:p-3 3xl:p-4 border-2 border-dashed rounded-md self-stretch justify-self-stretch min-h-48"}`
                })}>
                  <input {...getInputProps()} />
                  {this.state.file && this.state.file.name && !this.props.minimal
                    ? <div className="selected-file flex flex-col justify-items-center items-center space-y-3">
                      {this.state.file && <img
                        className="rounded-lg grayscale-70 cursor-pointer justify-self-center max-h-20 w-full object-contain"
                        src={this.state.fileSrc}
                        alt=""
                      />}
                      <div className="text-sm text-gray-600 text-center">
                        <p className="pr-1">to change image drop another one or</p>
                        <label
                          htmlFor="file"
                          className="relative cursor-pointer rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none"
                        >
                          <span>click here</span>
                          {/* <input
                        id="file"
                        name="file"
                        type="file"
                        className="sr-only"
                      /> */}
                        </label>
                      </div>
                    </div>
                    : <div className="space-y-1 text-center">
                      {this.props.minimal
                        ? <svg
                          className="mx-auto h-12 w-12 text-gray-400 opacity-50"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 26 26"
                          aria-hidden="true"
                        >
                          <path fillRule="evenodd" clipRule="evenodd" d="M17.6378 7.1099V7.1099C17.2648 7.1099 16.9268 6.8949 16.7678 6.5589C16.4808 5.9509 16.1158 5.1739 15.8998 4.7509C15.5808 4.1219 15.0638 3.7559 14.3478 3.7509C14.3358 3.7499 9.66382 3.7499 9.65182 3.7509C8.93582 3.7559 8.41982 4.1219 8.09982 4.7509C7.88482 5.1739 7.51982 5.9509 7.23282 6.5589C7.07382 6.8949 6.73482 7.1099 6.36282 7.1099V7.1099C4.36682 7.1099 2.74982 8.7269 2.74982 10.7219V16.6579C2.74982 18.6519 4.36682 20.2699 6.36282 20.2699H17.6378C19.6328 20.2699 21.2498 18.6519 21.2498 16.6579V10.7219C21.2498 8.7269 19.6328 7.1099 17.6378 7.1099Z" stroke="#333333" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                          <path fillRule="evenodd" clipRule="evenodd" d="M8.82139 13.3345C8.82039 15.0875 10.2514 16.5215 12.0014 16.5205C13.7484 16.5185 15.1754 15.0905 15.1784 13.3415C15.1814 11.5855 13.7554 10.1545 12.0034 10.1525C10.2414 10.1505 8.80739 11.6055 8.82139 13.3345Z" stroke="#333333" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M16.9025 10.9951C16.8059 10.9852 16.7105 10.96 16.6036 10.9169C16.4847 10.8641 16.3818 10.7969 16.2823 10.6959C16.1035 10.5057 16 10.257 16 10.0005C16 9.86422 16.0279 9.72916 16.0823 9.60681C16.1368 9.48285 16.2014 9.37829 16.3182 9.26456C16.407 9.18719 16.498 9.12493 16.6224 9.07013C16.9913 8.92396 17.4301 9.0113 17.7043 9.28541C17.7865 9.36639 17.8557 9.46411 17.8941 9.54651L17.9169 9.60494C17.9721 9.72916 18 9.86422 18 10.0005C18 10.2622 17.8978 10.5043 17.7081 10.7058C17.5442 10.8708 17.3289 10.9723 17.0992 10.9951L17 11L16.9025 10.9951Z" fill="#333333" />
                        </svg>
                        : <svg
                          className="mx-auto h-12 w-12 text-gray-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      }
                      {!this.props.minimal &&
                        <div className="text-sm text-gray-600">
                          <p className="pr-1">drag image here or</p>
                          <label
                            htmlFor="file"
                            className="relative cursor-pointer rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none"
                          >
                            <span>browse</span>
                            {/* <input
                              id="file"
                              name="file"
                              type="file"
                              className="sr-only"
                            /> */}
                          </label>
                        </div>
                      }
                      {!this.props.minimal &&
                        <p className="text-xs text-gray-500">PNG, JPG, GIF up to 300KB</p>
                      }
                    </div>
                  }
                </div>
                <p className="h-3 flex items-center font-medium tracking-wide text-red-500 text-xs 3xl:text-base mt-1 ml-1">
                  {!this.state.valid ? this.props.field.errorMessage : ""}
                </p>
              </section>
            )}
          </Dropzone>
        }
      </>
    )
  }
}

export default DropZone;