import config from 'react-global-configuration';

import { Component } from 'react';

import logo from '../../assets/images/logo/orangery Office Logo-02.png'
import logoWhite from '../../assets/images/logo/orangery Office Logo-02 - white.png'

const generals = require("../../generals")

class Logo extends Component {
  render() {
    return (
      <img
        className={`cursor-pointer max-h-8 object-scale-down ${this.props.className ? this.props.className : ""}`}
        src={this.props.white ? logoWhite : logo}
        alt="orangery Office"
        onClick={(e) => {
          e.preventDefault();
          generals.goTo(`${config.get('landingUrl')}/home`, 0)
        }}
      />
    );
  }
}

export default Logo;