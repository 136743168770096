import { Component } from 'react';

class A extends Component {
  render() {
    return (
      <span className='hover:cursor-pointer'>
        {this.props.preText &&
          <span className="mr-2">{this.props.preText}</span>
        }
        <a
          href={this.props.href}
          className="font-medium text-third hover:text-indigo-900"
          onClick={this.props.onClick}
        >
          {this.props.text}
        </a>
      </span>
    );
  }
}

export default A;