import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

// Importing translation files
import translationEN from "./locales/en/translation.json";
import translationDE from "./locales/de/translation.json";

import Cookie from 'js-cookie';

//Creating object with the variables of imported translation files
const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
};

const cookieLanguageWildCard = `orangery-language`;

function readCookie(cookieWildCard) {
  var thisCookieValue = null;

  var allCookies = Cookie.get();
  if (allCookies[cookieWildCard])
    thisCookieValue = allCookies[cookieWildCard];

  try {
    return JSON.parse(thisCookieValue)
  } catch (err) {
    return thisCookieValue
  }
}

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,

    fallbackLng: readCookie(cookieLanguageWildCard)
      ? readCookie(cookieLanguageWildCard)
      : 'de',

    debug: true,

    // backend: {
    //   loadPath: () => {
    //     return (process.env.PUBLIC_URL === "." ? "" : process.env.PUBLIC_URL ) + '/public/locales/{{lng}}/translation.json';
    //   },
    // },

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    react: {
      useSuspense: false
    }
  });

export default i18n;



// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";

// // the translations
// // (tip move them in a JSON file and import them,
// // or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
// const resources = {
//   en: {
//     translation: {
//       "Welcome to React": "Welcome to React and react-i18next"
//     }
//   },
//   fr: {
//     translation: {
//       "Welcome to React": "Bienvenue à React et react-i18next"
//     }
//   }
// };

// i18n
//   .use(initReactI18next) // passes i18n down to react-i18next
//   .init({
//     resources,
//     lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
//     // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
//     // if you're using a language detector, do not define the lng option

//     interpolation: {
//       escapeValue: false // react already safes from xss
//     }
//   });

// export default i18n;
