import { Component } from 'react';
import { BiHide, BiShowAlt } from 'react-icons/bi';
import { SearchIcon } from '@heroicons/react/solid'

export default class TextBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      valid: this.props.field.valid,
      value: this.props.field.value !== undefined ? this.props.field.value : "",

      show: false,
    }
  }

  // need when using props for initializing state
  componentWillReceiveProps(nextProps) {
    if (nextProps.field.valid !== this.props.field.valid)
      this.setState({ valid: nextProps.field.valid });

    if (nextProps.field.value !== this.props.field.value)
      this.setState({ value: nextProps.field.value });
  }
  // static getDerivedStateFromProps(nextProps, prevState) {
  //   return {
  //     valid: nextProps.field.valid,
  //     value: nextProps.field.value
  //   };
  // }

  handleChange = (value, forceValidate = false) => {
    this.setState({ value: value });

    if (this.props.onChange)
      this.props.onChange(this.props.field.name, value)

    if (forceValidate)
      this.validate(value)
  }

  handleBlur = (e) => {
    this.validate()
  }

  getValue = () => {
    let value = this.state.value + "";
    if (value)
      if (this.props.field.trimmable)
        return value.trim();
      else
        return value;

    return value;
  }

  setValue = (value, forceValidate = false) => {
    if (forceValidate) {
      if (this.validate(value))
        this.handleChange(value, forceValidate);
    } else {
      this.handleChange(value, forceValidate);
    }
  }

  init = (forceValidate = false) => {
    if (forceValidate === false)
      this.setState({ valid: true })

    if (this.props.field.initialValue !== undefined)
      this.setValue(this.props.field.initialValue, forceValidate)
  }

  reset = () => {
    if (this.props.field.emptyValue !== undefined)
      this.setValue(this.props.field.emptyValue)
  }

  validate = (inputValue = null) => {
    let value = inputValue != null ? inputValue : this.state.value
    // if (this.props.field.trimmable) {
    //   value = value.trim();
    //   this.setState({ value: value });
    // }

    let valid = true;

    if (this.props.field.required) {
      switch (this.props.field.type) {
        case "email":
          if (value && value.length < 1)
            valid = false;
          else {

            let lastAtPos = value.lastIndexOf("@");
            let lastDotPos = value.lastIndexOf(".");

            if (!(
              lastAtPos < lastDotPos &&
              lastAtPos > 0 &&
              value.indexOf("@@") === -1 &&
              lastDotPos > 2 &&
              value.length - lastDotPos > 2
            ))
              valid = false;
          }

          break;

        case "password":
          if (value.length < 6)
            valid = false;

          break;

        case "text":
          if (value.length < 1)
            valid = false;
          // else
          //   if (!value.match(/^[a-zA-Z]+$/))
          //     valid = false;

          break;

        case "number":
          if (parseInt(value) < 1)
            valid = false;

          break;

        default:
          if (value.length < 1)
            valid = false;
          break;
      }
    }

    this.setState({ valid: valid });

    return valid;
  }

  toggleShow = () => {
    this.setState({ show: !this.state.show });
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   if (
  //     JSON.stringify(nextProps) !== JSON.stringify(this.props)
  //     || JSON.stringify(nextState) !== JSON.stringify(this.state)
  //   )
  //     return true;
  // }

  render() {
    let { field } = this.props

    return (
      <div className={`${field.className ? field.className : ""}`}> {/* space-y-1 */}
        {field.label &&
          <label
            htmlFor={field.id}
            className={
              `block text-sm 3xl:text-base text-gray-700 ${field.labelClassName ? field.labelClassName : ""}`
              + (this.props.disabled ? " text-gray-500" : "")
            }
          >
            {field.label}
          </label>
        }
        <div className="relative">
          {field.type === "search" &&
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center my-auto">
              <SearchIcon className="h-5 w-5 3xl:h-5 3xl:w-5 text-gray-500" aria-hidden="true" />
            </div>
          }
          <input
            id={field.id}
            name={field.name}
            type={field.type === "password" ? (!this.state.show ? 'password' : 'text') : field.type}
            autoComplete={field.autocomplete}
            required={field.required ? "required" : ""}
            placeholder={field.placeholder}
            className={
              "appearance-none" + (field.type === "number" ? " px-0" : " px-3") + (field.type === "search" ? " pl-10" : "") + (field.type === "password" ? " pr-10" : "") + (field.name === "price" ? " pr-6" : "") + " py-2 placeholder-gray-400 rounded focus:outline-none border w-full"
              + (field.inputClassName ? " " + field.inputClassName : "")
              + (!this.state.valid ? " border-red-500" : " border-border focus:ring-0 focus:ring-black")
              + (this.props.disabled ? " disabled:bg-gray-300 disabled:text-gray-500" : "")
            }
            value={this.state.value}

            onChange={(e) => this.handleChange(e.target.value, true)}
            onBlur={this.handleBlur}

            disabled={this.props.disabled}
          />

          {field.type === "password" &&
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center my-auto">
              <span
                onClick={this.toggleShow}
                className={"cursor-pointer text-gray-400 " + (!this.state.show ? 'block' : 'hidden')}>
                <BiShowAlt size={28} />
              </span>

              <span
                onClick={this.toggleShow}
                className={"cursor-pointer text-gray-400 " + (this.state.show ? 'block' : 'hidden')}>
                <BiHide size={28} />
              </span>
            </div>
          }
          {field.name === "price" &&
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center my-auto">
              <span className='strong'>
                €
              </span>
            </div>
          }
        </div >

        {
          field.type !== "search" &&
          <p className="h-3 flex items-center font-medium tracking-wide text-red-500 text-xs 3xl:text-base mt-1 ml-1">
            {!this.state.valid ? field.errorMessage : ""}
          </p>
        }
      </div >
    );
  }
}