import i18next from 'i18next';

import { Component } from 'react';

export default class Modal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: this.props.show
    }
  }

  toggleModal = (show) => {
    this.props.toggleModal(show);
    this.setState({ show: show });
  }

  // need when using props for initializing state
  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      show: nextProps.show
    };
  }

  onSubmit = (e) => {
    if (e)
      e.stopPropagation()

    this.toggleModal(false);

    if (this.props.onSubmit) {
      this.props.onSubmit();
    }
  }

  onClose = (e) => {
    if (e)
      e.stopPropagation()

    this.toggleModal(false);

    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  render() {
    return (
      <>
        <div id={`${this.props.id}-backdrop`} className={(this.state.show ? "flex " : "hidden ") + "bg-gray-900 bg-opacity-50 fixed inset-0 z-40"}></div>
        <div id={this.props.id} className={(this.state.show ? "flex " : "hidden ") + "overflow-y-auto overflow-x-hidden fixed right-0 left-0 top-4 z-50 justify-center items-center md:inset-0 h-modal sm:h-full"} aria-hidden="true">
          <div className="relative px-4 w-full max-w-md h-full md:h-auto">
            <div className="relative bg-white rounded shadow">
              <div className="flex justify-end p-2">
                <button onClick={this.onClose} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded text-sm p-1.5 ml-auto inline-flex items-center" data-modal-toggle={this.props.id}>
                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                </button>
              </div>
              <div className="p-6 pt-0 text-center">
                <svg className="mx-auto mb-4 w-14 h-14 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                <h3 className="mb-5 text-lg font-normal text-gray-500">
                  {this.props.text}
                </h3>
                {this.props.modalSubmitButtonText === "yes" &&
                  <button
                    onClick={this.onClose}
                    data-modal-toggle={this.props.id}
                    type="button"
                    className="text-white bg-fifth hover:bg-fifth-900 focus:ring-0 focus:ring-brand-300 font-normal w-44 h-12 rounded inline-flex px-5 py-2.5 mr-2 justify-center items-center"
                  >
                    no
                  </button>
                }
                <button
                  onClick={this.onSubmit}
                  data-modal-toggle={this.props.id}
                  type="button"
                  className="text-black bg-brand hover:bg-brand-900 focus:ring-0 focus:ring-brand-300 font-normal w-44 h-12 text-base rounded inline-flex px-5 py-2.5 mr-2 justify-center items-center"
                >
                  {this.props.modalSubmitButtonText
                    ? this.props.modalSubmitButtonText
                    : i18next.t('OK')
                  }
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}