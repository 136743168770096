import { Buy } from "react-iconly";

export default function ShoppingCartButton(props) {
  return (
    <div
      className="relative flex h-10 md:h-14 2xl:h-20 w-10 md:w-14 2xl:w-20 rounded-full bg-brand text-white cursor-pointer align-middle shadow-md"
      onClick={props.onClick ? props.onClick : () => { }}
    >
      <div className="mx-auto my-auto w-fit h-fit">
        <Buy set="light" size={36} />
      </div>
      <span
        className="absolute bottom-0 right-0 inline-block w-5 h-5 text-center bg-black text-sm font-medium border-none rounded-full"
      >
        {props.selectedAssetsCount}
      </span>
    </div>
  );
}