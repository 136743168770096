/*
  This example requires Tailwind CSS v2.0+ 
*/

import config from 'react-global-configuration';

import { useTranslation } from 'react-i18next';

import { Location, Calling, Message } from 'react-iconly';

import { Logo, SubscribeEMailForm } from '../../components'

export default function Footer(props) {
  const { t } = useTranslation();

  // const explores = [
  //   {
  //     label: t("Team"),
  //     href: `${config.get('homeUrl')}/team`,
  //   },
  // ]

  const contacts = [
    {
      label:
        <span className='flex flex-row space-x-2'>
          <div className='flex-none'>
            <Location set='light' size={22} />
          </div>
          <span>
            {config.get('orangery').address}
          </span>
        </span>
    },
    {
      label:
        <span className='flex flex-row space-x-2 items-center'>
          <div className='flex-none'>
            <Calling set='light' size={22} />
          </div>
          <span>
            <a href={`tel:${config.get('orangery').phone}`}>
              {config.get('orangery').phone}
            </a>
          </span>
        </span>
    },
    {
      label:
        <span className='flex flex-row space-x-2 items-center'>
          <div className='flex-none'>
            <Message set='light' size={22} />
          </div>
          <span>
            <a href={`mailto:${config.get('orangery').official_email}`}>
              {config.get('orangery').official_email}
            </a>
          </span>
        </span>
    },
  ]

  return (
    <footer className="bg-sixth py-6" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-md mx-auto pt-12 px-4 sm:max-w-7xl sm:px-6 lg:pt-16 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-5 xl:gap-8">
          <div className="col-span-1 md:col-span-2">
            <Logo white={true} />
            <h3 className="text-2xl font-normal text-white tracking-wider font-Roboto+Slab mt-3">
              {config.get('orangery').slogan}
            </h3>
            <p className="text-gray-500 text-base mt-8">
              {t("_footerBodyText")}
            </p>
            <div className="flex flex-col space-x-6 mt-4">
              <div className="mt-12 xl:mt-0">
                <p className="text-gray-500 text-base mt-8">
                  {t("Newsletter")}
                </p>
                <SubscribeEMailForm
                  submitButtonText={t("Subscribe newsletter")}
                />
              </div>
            </div>
          </div>
          <div className='mt-12 md:mt-0'>
            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">{t("Locations")}</h3>
            <ul className="mt-4 space-y-4">
              {props.locationOptions && props.locationOptions.map((item) => (
                <li key={item.label}>
                  <a href={`${config.get('homeUrl')}/location/${item.value}`} className="text-base text-white hover:text-gray-200" role="button">
                    {item.label}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          {/* <div className="mt-12 md:mt-0">
            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">{t("Explore")}</h3>
            <ul className="mt-4 space-y-4">
              {explores && explores.map((item) => (
                <li key={item.label}>
                  <a href={item.href} className="text-base text-white hover:text-gray-200" role="button">
                    {item.label}
                  </a>
                </li>
              ))}
            </ul>
          </div> */}
          <div className="mt-12 md:mt-0">
            <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">{t("Contact")}</h3>
            <ul className="mt-4 space-y-4">
              {contacts && contacts.map((item, index) => (
                <li key={index}>
                  <div className="text-base text-white hover:text-gray-200">
                    {item.label}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="mt-12 border-t border-gray-200 py-8 flex justify-between text-base text-gray-400">
          <p className="">{`${t("Copyright")} © 2022 orangery Coworking GmbH. ${t("All Rights Reserved")}.`}</p>
          <span className="flex space-x-4">
            <a href={config.get('orangery').termsAndConditions} className="text-base text-white hover:text-gray-200" role="button">
              {t("_Terms & Conditions")}
            </a>
            <a href={config.get('orangery').privacyPolicy} className="text-base text-white hover:text-gray-200" role="button">
              {t("privacy")}
            </a>
            <a href={config.get('orangery').imprint} className="text-base text-white hover:text-gray-200" role="button">
              {t("imprint")}
            </a>
          </span>
        </div>
      </div>
    </footer>
  )
}
