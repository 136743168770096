/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ....
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

// contexts
import config from 'react-global-configuration';
import { AuthContext } from '../../context/authContext';
import { useTranslation } from 'react-i18next';

import { useContext, useState, useEffect } from 'react'
import moment from 'moment';
import axios from 'axios'

import { HorizontalForm, Tabs, Avatar } from '../../components'

export default function SettingsTabs(props) {
  const { accessToken, isAuth, user } = useContext(AuthContext)

  const { t } = useTranslation();

  function onSettingsFormSubmit() {
    if (props.onUserDataChanged)
      props.onUserDataChanged()
  }

  function onPromotionFormSubmit() {
  }

  function onProfilePhotoChanged(file) {
    if (props.onUserDataChanged)
      props.onUserDataChanged()
  }

  const [plans, setPlans] = useState([]);
  function loadPlansByAccessToken() {
    const callbackFunction = () => {
      let url;
      url = `${config.get('apiUrl')}/getplansbytoken`;

      let configs = {
        headers: {
          'x-access-token': accessToken,
          timeout: config.get('apiCallTimeout')
        },
        params: {
          // page: f + 1
        },
      }
      axios.get(url, configs)
        .then((response) => {
          if (response.data.result) {
            const plans = response.data.data;

            if (Array.isArray(plans) && plans.length > 0)
              setPlans(plans);
            // else
            //   generals.goTo(`${config.get('landingUrl')}/home`, 0)
          } else {
            ////////!!!!!!!!
          }
        })
        .catch((error) => {
          console.error(error);
          setPlans([]);
        })
        .then(function () {
          // always executed
        });
    }

    isAuth(callbackFunction)
  }

  useEffect(() => {
    loadPlansByAccessToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fields = {
    name: {
      label: t("Name"),
      id: "name",
      name: "name",
      type: "text",
      autocomplete: "name",
      placeholder: t("name"),
      errorMessage: t("Name is not valid"),

      valid: true,
      value: user.name ? user.name : "",
      initialValue: user.name ? user.name : "",

      key: "name",
      trimmable: true,
      required: true,

      className: "basis-full md:basis-1/2 space-y-1 2xl:space-y-3 pb-1 pt-0 px-2",
      inputClassName: "h-10 2xl:h-16 text-base 2xl:text-xl",
      labelClassName: "font-medium",
    },
    last_name: {
      label: t("Last Name"),
      id: "last_name",
      name: "last_name",
      type: "text",
      autocomplete: "last_name",
      placeholder: t("last name"),
      errorMessage: t("Last name is not valid"),

      valid: true,
      value: user.last_name ? user.last_name : "",
      initialValue: user.last_name ? user.last_name : "",

      key: "last_name",
      trimmable: true,
      required: true,

      className: "basis-full md:basis-1/2 space-y-1 2xl:space-y-3 pb-1 pt-0 px-2",
      inputClassName: "h-10 2xl:h-16 text-base 2xl:text-xl",
      labelClassName: "font-medium",
    },
    gender: {
      label: t("Gender"),
      id: "gender",
      name: "gender",
      type: "select",
      autocomplete: "gender",
      placeholder: t("gender"),
      errorMessage: t("Select a gender"),

      items: config.get('genders'),

      valid: true,
      value: user.gender,
      initialValue: user.gender,

      key: "gender",
      trimmable: false,
      required: true,

      className: "basis-full md:basis-1/2 space-y-1 2xl:space-y-3 pb-1 pt-0 px-2",
      inputClassName: "h-10 2xl:h-16 text-base 2xl:text-xl",
      labelClassName: "font-medium",
    },
    company: {
      label: t("Company or Idea"),
      id: "company",
      name: "company",
      type: "text",
      placeholder: t("company or idea"),
      errorMessage: t("Not valid"),

      valid: true,
      value: user.company,
      initialValue: user.company,

      key: "company",
      trimmable: false,
      required: false,

      className: "basis-full md:basis-1/2 space-y-1 2xl:space-y-3 pb-1 pt-0 px-2",
      inputClassName: "h-10 2xl:h-16 text-base 2xl:text-xl",
      labelClassName: "font-medium",
    },
    email: {
      label: t("Email"),
      id: "email",
      name: "email",
      type: "email",
      autocomplete: "email",
      placeholder: "mail@website.com",
      errorMessage: t("Email is not valid"),

      valid: true,
      value: user.email,
      initialValue: user.email,

      key: "email",
      trimmable: true,
      required: true,

      className: "basis-full md:basis-1/2 space-y-1 2xl:space-y-3 pb-1 pt-0 px-2",
      inputClassName: "h-10 2xl:h-16 text-base 2xl:text-xl",
      labelClassName: "font-medium",
    },
    tax_number: {
      label: t("Tax Number"),
      id: "tax_number",
      name: "tax_number",
      type: "text",
      placeholder: t("tax number"),
      errorMessage: t("Tax number is not valid"),

      valid: true,
      value: user.tax_number,
      initialValue: user.tax_number,

      key: "tax_number",
      trimmable: false,
      required: false,

      className: "basis-full md:basis-1/2 space-y-1 2xl:space-y-3 pb-1 pt-0 px-2",
      inputClassName: "h-10 2xl:h-16 text-base 2xl:text-xl",
      labelClassName: "font-medium",
    },
    address: {
      label: t("Address"),
      id: "address",
      name: "address",
      type: "text",
      placeholder: t("address"),
      errorMessage: t("Address is not valid"),

      valid: true,
      value: user.address,
      initialValue: user.address,

      key: "address",
      trimmable: false,
      required: true,

      className: "basis-full space-y-1 2xl:space-y-3 pb-1 pt-0 px-2",
      inputClassName: "h-10 2xl:h-16 text-base 2xl:text-xl",
      labelClassName: "font-medium",
    },
  }

  const promotionFields = {
    promotion_code: {
      label: t("Promotion Code"),
      id: "promotion_code",
      name: "promotion_code",
      type: "text",
      autocomplete: "promotion_code",
      placeholder: t("promotion code"),
      errorMessage: t("Enter promotion code"),

      valid: true,
      value: "",
      initialValue: "",

      key: "promotion_code",
      trimmable: true,
      required: true,

      className: "basis-full space-y-1 2xl:space-y-3 pb-1 pt-0 px-2",
      inputClassName: "h-10 2xl:h-16 text-base 2xl:text-xl",
      labelClassName: "font-medium",
    },
    plan_id: {
      label: t("Monthly plan"),
      id: "plan_id",
      name: "plan_id",
      type: "select",
      autocomplete: "plan_id",
      placeholder: t("monthly plan"),
      errorMessage: t("Select a monthly plan"),

      items: plans && Array.isArray(plans)
        ? plans.filter(plan => plan && plan.officeplan && plan.officeplan.duration === "monthly").map(plan => {
          return { value: plan.id, label: plan.officeplan.name }
        })
        : [],

      valid: true,
      // value: "",
      // initialValue: "",

      key: "plan_id",
      trimmable: false,
      required: true,

      className: "basis-full space-y-1 2xl:space-y-3 pb-1 pt-0 px-2",
      inputClassName: "h-10 2xl:h-16 text-base 2xl:text-xl",
      labelClassName: "font-medium",
    },
  }

  const tabs = {
    personalInformation: {
      key: 'personalInformation',
      title: t('Personal Information'),
      href: '#',
      content: <>
        <div className='flex flex-col lg:flex-row max-w-7xl'>
          <div className='basis-full lg:basis-1/3'>
            <div className='mt-2 lg:ml-2 bg-gray-100 w-auto h-auto rounded-md p-4 mb-2'>
              <div className='flex flex-row items-center justify-items-start'>
                <Avatar
                  className="h-20 w-20 mr-4"
                  src={user.file
                    ? user.file.filename
                      ? `${config.get('cdnUrl')}/${user.file.filename}`
                      : user.file.src
                    : ""
                  }
                  size={48}

                  changeable={true}
                  onChange={onProfilePhotoChanged}
                />
                <h1 className='text-base font-medium'>
                  {`${user.name ? user.name : ""} ${user.last_name ? user.last_name : ""}`}
                </h1>
              </div>
              <div className='w-auto mt-4'>
                <div className='grid grid-cols-3 gap-3'>
                  <div className='col-span-1'>
                    {t("Location")}
                  </div>
                  <div className='col-span-2'>
                    {user.location ? user.location.name : ""}
                  </div>

                  <div className='col-span-1'>
                    {t("Start Date")}
                  </div>
                  <div className='col-span-2'>
                    {
                      moment.utc(user.created_at, 'YYYY-MM-DD').local().format("YYYY-MM-DD")
                      // plan.startDate
                    }
                  </div>

                  <div className='col-span-1'>
                    {t("Tax Number")}
                  </div>
                  <div className='col-span-2'>
                    {user.tax_number}
                  </div>

                  <div className='col-span-1'>
                    {t("Address")}
                  </div>
                  <div className='col-span-2'>
                    {user.address}
                  </div>
                </div>
              </div>
            </div>
            <div className='w-auto border border-border rounded-md p-4 mt-2 lg:ml-2'>
              <div className='flex flex-wrap'>
                <HorizontalForm
                  fields={promotionFields}

                  submitButtonText={"Apply"}
                  submitApiUrl={config.get('apiUrl') + `/applypromotion`}

                  onSubmit={onPromotionFormSubmit}

                  hasFile={false}
                />
              </div>
            </div>
          </div>
          <div className='basis-full lg:basis-2/3'>
            <div className='w-auto border border-border rounded-md p-4 mt-2 lg:ml-2'>
              <div className='flex flex-wrap'>
                <HorizontalForm
                  fields={fields}

                  submitButtonText={"Save"}
                  submitApiUrl={config.get('apiUrl') + `/updateprofile`}
                  cancellable={true}
                  cancelButtonText={"Cancel"}

                  onSubmit={onSettingsFormSubmit}

                  disabled={true}
                  editable={true}

                  hasFile={true}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    },
  }

  return (
    <Tabs
      tabs={tabs}
    />
  )
}
