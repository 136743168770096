import config from 'react-global-configuration';

import React, { Component } from 'react';
// import history from "../utils/history";
import { withTranslation } from 'react-i18next';

import { SplitScreen, A, JustifyBetween, VerticalForm, Logo } from '../components';

import backgroundImage from '../assets/images/login background.jpeg'

const generals = require("../generals")

class SignUp extends Component {
  constructor(props) {
    super(props);

    const { t } = this.props;

    this.fields = {
      firstName: {
        label: `${t("Name")} *`,
        id: "name",
        name: "firstName",
        type: "text",
        placeholder: t("Your name"),
        errorMessage: t("Only letters"),

        valid: true,
        value: "",

        key: "name",
        trimmable: true,
        required: true,

        className: "w-full 2xl:w-112 space-y-1 2xl:space-y-3",
        inputClassName: "h-10 2xl:h-16 text-base 2xl:text-xl",
        labelClassName: "font-medium",
      },
      lastName: {
        label: `${t("Last Name")} *`,
        id: "lastName",
        name: "lastName",
        type: "text",
        placeholder: t("Your last name"),
        errorMessage: t("Only letters"),

        valid: true,
        value: "",

        key: "lastName",
        trimmable: true,
        required: true,

        className: "w-full 2xl:w-112 space-y-1 2xl:space-y-3",
        inputClassName: "h-10 2xl:h-16 text-base 2xl:text-xl",
        labelClassName: "font-medium",
      },
      email: {
        label: t("Email") + " *",
        id: "email",
        name: "email",
        type: "email",
        autocomplete: "email",
        placeholder: "mail@website.com",
        errorMessage: t("Email is not valid"),

        valid: true,
        value: "",

        key: "email",
        trimmable: true,
        required: true,

        className: "w-full 2xl:w-112 space-y-1 2xl:space-y-3",
        inputClassName: "h-10 2xl:h-16 text-base 2xl:text-xl",
        labelClassName: "font-medium",
      },
      password: {
        label: t("Password") + " *",
        id: "password",
        name: "password",
        type: "password",
        autocomplete: "current-password",
        placeholder: t("Min. 6 characters"),
        errorMessage: t("Password is not valid"),

        valid: true,
        value: "",

        key: "password",
        trimmable: false,
        required: true,

        className: "w-full 2xl:w-112 space-y-1 2xl:space-y-3",
        inputClassName: "h-10 2xl:h-16 text-base 2xl:text-xl",
        labelClassName: "font-medium",
      },
      'agree-terms': {
        label: <span>{t("I agree to the")}{' '}<A href={config.get('orangery').termsAndConditions} text={t("Terms & Conditions")} />{' '}{t("_to")}</span>,
        id: "agree-terms",
        name: "agree-terms",
        type: "checkbox",
        errorMessage: t("Should checked"),

        valid: true,
        value: "",

        key: "agree-terms",
        trimmable: false,
        required: true,
      },
      url: {
        id: "url",
        name: "url",
        type: "hidden",

        valid: true,
        value: `${config.get('homeUrl')}/confirm_email`,

        key: "url",
        trimmable: false,
        required: true,
      }
    }
  }

  onSubmit = (e) => {
    if (e)
      e.preventDefault();

    generals.goTo(`${config.get('homeUrl')}/login`);
  }

  render() {
    const { t } = this.props;

    return (
      <SplitScreen
        content={
          <div className="col-span-11 md:col-span-5">
            <JustifyBetween
              className="p-6"
              elementsClassName="min-h-8"
              left=""
              right={
                <A href={`${config.get('homeUrl')}/login`} preText={t("Already have an account?")} text={t("Login")} />
              }
            />
            <div className="py-12 mx-auto w-3/5 2xl:w-112">
              <VerticalForm
                logo={<Logo />}
                title={t("Sign Up")}
                subTitle={t("Create an account to setup a workspace")}

                fields={this.fields}

                submitApiUrl={`${config.get('apiUrl')}/new`}
                submitButtonText={t("Sign Up")}

                onSubmit={this.onSubmit}

                hasFile={false}

                noCheckToken={true}
              />
            </div>
          </div>
        }

        imageSrc={backgroundImage}
        imageAlt={t("orangery Workspace")}
      />
    );
  }
}

export default withTranslation()(SignUp)