import { Spinner } from '../../components';

export default function BookingButton(props) {
  return (
    <div
      data-tip={props["data-tip"]}
      data-for={props["data-for"]}

      className={
        "relative flex shrink-0 h-10 md:h-10 2xl:h-10 min-w-10 md:min-w-10 2xl:min-w-10 rounded-full bg-white text-black align-middle"
        + (!props.disabled
          ? " cursor-pointer"
          : " cursor-not-allowed"
        )
      }
      onClick={props.onClick}
    >
      <div className="flex place-items-center mx-auto my-auto w-fit h-fit">
        {!props.waiting &&
          props.icon
        }
        {props.waiting &&
          <Spinner />
        }
      </div>
    </div>
  );
}