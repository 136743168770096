import config from 'react-global-configuration';

import { useTranslation } from 'react-i18next';
import moment from 'moment'

import { Img, Button } from "../../components"

import { People } from 'react-iconly';

export default function PlanMediumCard(props) {
  const { t } = useTranslation();

  function onChangeCurrentPlan(plan) {
    if (props.onChangeCurrentPlan) {
      props.onChangeCurrentPlan(plan)
    }
  }

  const officePlan = (props.plan && props.plan.officeplan) || props.officePlan

  // ${<EditSquare set="light" size={14} />} 
  return (
    officePlan
      ? <div className="relative">
        {!props.notShowSetCurrentPlanButton &&
          <Button
            onClick={() => { onChangeCurrentPlan(props.plan) }}
            className={`absolute px-2 py-0 top-2 left-2 rounded-full text-sm opacity-80 hover:opacity-100 ${props.isCurrentPlan ? "bg-forth" : "bg-gray-200"}`}
            text={props.isCurrentPlan ? "current plan" : "set as current"}
          />
        }
        <div className={"bg-gray-200 w-auto h-auto rounded-lg flex flex-col sm:flex-row p-4"}>
          <div className='flex-none'>
            <Img
              className='mx-auto sm:ml-0 w-32 h-32 2xl:w-48 2xl:h-48 object-cover rounded-xl min-w-14'
              src={`${config.get('cdnUrl')}/${officePlan.file && officePlan.file.filename ? officePlan.file.filename : officePlan && officePlan.file ? officePlan.file.src : ""}`}
              alt=""
            />
          </div>
          <div className='grid grid-cols-1 ml-0 sm:ml-4'>
            <div>
              <div className='text-center sm:text-left justify-items-start'>
                <p className='hidden sm:block text-sm text-gray-500'>{t("Plan Name")}</p>
                <h3 className="text-base 2xl:text-xl font-medium text-gray-900 mb-2 flex flex-row items-baseline space-x-2 sm:block sm:space-x-0 justify-center sm:justify-start">
                  <span>{officePlan.name}</span>
                  <p className="text-xs 2xl:text-base text-gray-500">
                    {officePlan.location && officePlan.location.name}
                  </p>
                </h3>
              </div>
              <div className="flex space-x-0 sm:space-x-2 2xl:space-x-3">
                <p className="mx-auto sm:ml-0 text-center sm:text-left text-xs 2xl:text-base text-gray-500">
                  {officePlan.description}
                </p>
              </div>
            </div>
            <div className='flex flex-row space-x-2 mt-2 sm:mt-0 mx-auto sm:ml-0'>
              <div className='my-auto'>
                <People set="light" size={20} />
              </div>
              <span>
                {`${officePlan.capacity} Person${parseInt(officePlan.capacity) !== 1 ? "en" : ""}`}
              </span>
            </div>
          </div>
          <div className='grid grid-cols-1 content-between basis-auto mt-2 sm:mt-0 ml-0 sm:ml-auto flex-none pl-3'>
            <div className='hidden sm:block text-right'>
              <p className='text-sm text-gray-500'>{t("Price")}</p>
              <h3 className="text-base 2xl:text-xl font-medium text-gray-900">
                {`${officePlan.price} €`}
              </h3>
            </div>
            <div className='flex flex-row sm:flex-col text-center sm:text-right space-x-2 sm:space-x-0 justify-center sm:justify-end'>
              <p className='hidden sm:block text-sm text-gray-500'>{t("Duration")}</p>
              <h3 className="text-sm 2xl:text-base font-normal text-gray-900">
                {props.plan
                  ? props.plan.started_at ? moment.utc(props.plan.started_at, 'YYYY-MM-DD').local().format("YYYY-MM-DD") : "-"
                  : props.date
                    ? props.date
                    : officePlan.duration === "monthly"
                      ? moment(new Date()).format("YYYY-MM-DD")
                      : "-"
                }
              </h3>
              <p className='block sm:hidden text-sm text-gray-500'>{t("_DurationTo")}</p>
              <h3 className="text-sm 2xl:text-base font-normal text-gray-900">
                {props.plan
                  ? props.plan.expired_at ? moment.utc(props.plan.expired_at, 'YYYY-MM-DD').local().format("YYYY-MM-DD") : "-"
                  : props.date
                    ? props.date
                    : "-"
                }
              </h3>
            </div>
          </div>
        </div>
      </div>
      : ""
  )
}