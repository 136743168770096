/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/

import config from 'react-global-configuration';
import { AuthContext } from "../../context/authContext";
import i18next from 'i18next';

import { createRef, Component } from 'react'
import ReactTooltip from "react-tooltip";

import { JustifyBetween, BookingButton, Modal, Accordion, MyDatePicker, ModalContainer } from '../../components';

import { Calendar, ChevronRight } from "react-iconly";
import SubscribeEMailForm from './subscribeEMailForm';

const generals = require("../../generals")
class OfficePlanCard extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props);
    this.accessToken = context.accessToken;
    this.user = context.user;

    this.state = {
      modalShow: false,
      modalText: "",
      waiting: false,

      modalSubmitButtonText: '',
      modalOnSubmit: () => { },

      pickedDate: null,

      informMeModalShow: false,
    }

    this.available = this.props.officePlan.available
      ? this.props.officePlan.available > 0
      : false

    this.myDatePickerRef = createRef();
    this.accordionRef = createRef();
  }

  toggleModal = (show) => {
    this.setState({ modalShow: show });
  }

  onPickedDateChange = (value) => {
    this.setState({ pickedDate: value })
  }

  handleSubmit = (e = null) => {
    if (e !== null) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (!this.available) {
      this.toggleInformMeModal(true)
    } else
      if (!this.props.disabled)
        if (this.props.officePlan.duration === "daily" && !this.state.pickedDate) {
          this.accordionRef.current.triggerClick();
          this.myDatePickerRef.current.setValue(new Date())
        } else
          if (this.props.active) { ////////!!!!!!!!
            this.setState({ waiting: true });

            if (this.props.onSubmitting)
              this.props.onSubmitting(true);

            // if (!this.accessToken || this.accessToken === "undefined") {
            //   generals.goTo(`${config.get('appUrl')}/login?dest=location&id=${this.props.locationId}`, 0)
            //   return false;
            // }

            this.props.officePlan && generals.goTo(`${config.get('homeUrl')}/checkout?id=${this.props.officePlan.id}${this.props.officePlan.duration === "daily" ? `&date=${this.myDatePickerRef.current.getValue()}` : ""}`)
          } else {
          }
  }

  toggleInformMeModal = (show) => {
    this.setState({ informMeModalShow: show });
  }

  render() {
    let thisRand = Math.random()

    let { officePlan } = this.props

    return (
      <div
        key={officePlan.id}
        className={
          `flex flex-col space-y-3 grow group relative py-1 px-5 pb-3  text-black rounded-lg overflow-hidden select-none 
          ${officePlan.duration !== "daily"
            ? !this.props.disabled
              ? "cursor-pointer"
              : "cursor-not-allowed"
            : ""}`
          + (!this.props.disabled ? " bg-brand" : " bg-gray-200")
        }
      // onClick={officePlan.duration !== "daily" ? this.handleSubmit : () => { }}
      >
        {this.props.ribbon
          && this.props.ribbon
        }
        <Modal
          id="modal"
          text={this.state.modalText}
          show={this.state.modalShow}
          toggleModal={this.toggleModal}

          modalSubmitButtonText={this.state.modalSubmitButtonText}
          onSubmit={this.state.modalOnSubmit}
        // onClose={this.state.modalOnSubmit}
        />
        <ModalContainer
          id="modal"
          show={this.state.informMeModalShow}
          toggleShow={this.toggleInformMeModal}

          className={"max-w-6xl"}
        >
          <div className='flex flex-col overflow-y-auto max-w-md'>
            Momentan wächst und wächst unsere Community. Wir sind schon auf Hochtouren dabei, dass wir neue Workspaces eröffnen und planen. Trage Dich einfach auf unsere Warteliste ein
            <SubscribeEMailForm
              officePlanId={this.props.officePlan ? this.props.officePlan.id : ""}
              email={this.user ? this.user.email : ""}
              submitButtonText={i18next.t("Send")}
            />
          </div>
        </ModalContainer>
        <JustifyBetween
          elementsClassName="flex items-start"
          left={<>
            <h3 className="text-base font-semibold text-gray-900">
              {officePlan.name}
            </h3>
          </>
          }
          right={<div className="text-sm">
            {this.available
              ? i18next.t("available now")
              : i18next.t("unavailable")
            }
          </div>
          }
        />
        <p className="w-full text-sm font-normal text-gray-600 text-ellipsis">
          {officePlan.description}
        </p>
        <JustifyBetween
          elementsClassName="flex items-center"
          left={<>
            <p className="text-base font-medium text-gray-900">{`${officePlan.price} €`}
              {' '}
              <span className='text-gray-500 text-sm'>
                ({i18next.t(officePlan.duration === "monthly"
                  ? "per month"
                  : officePlan.duration === "daily"
                    ? "per day"
                    : ""
                )})
              </span>
            </p>
          </>
          }
          right={<>
            <BookingButton
              data-tip
              data-for={"bookingButtonTooltip" + thisRand}

              icon={this.available
                ? officePlan.duration === "daily"
                  ? this.state.pickedDate
                    ? <div className='pl-3 pr-2 flex flex-row items-center place-items-center'>
                      <div className='my-auto'>
                        {i18next.t("continue")}
                      </div>
                      <div className='my-auto'>
                        <ChevronRight set="light" size={22} />
                      </div>
                    </div>
                    : <Calendar set="light" size={22} />
                  : <ChevronRight set="light" size={22} />
                : <div className='pl-3 pr-2 flex flex-row items-center place-items-center'>
                  <div className='my-auto'>
                    {i18next.t("wait list")}
                  </div>
                </div>
              }

              disabled={this.props.disabled}

              onClick={this.handleSubmit}

              waiting={this.state.waiting}
            />
            {!this.available
              && <ReactTooltip id={"bookingButtonTooltip" + thisRand} place="top" effect="solid">
                {i18next.t("put me on the waiting list")}
              </ReactTooltip>
            }
          </>
          }
        />
        <Accordion
          ref={el => { this.accordionRef.current = el; }}
          noButton={true}

          className="-mt-8 md:-mt-8"
          id={`OfficePlanDatePicker${officePlan.id}`}
        >
          <MyDatePicker
            ref={el => { this.myDatePickerRef.current = el; }}

            onChange={this.onPickedDateChange}

            field={{}}
          />
        </Accordion>
      </div >
    )
  }
}

export default OfficePlanCard;