module.exports = {
  goTo: (url, timeOut = 2000) => {
    setTimeout(() => { window.location.href = url }, timeOut);
  },
  toList: (inputArray, valueTag, labelTag) => {
    return inputArray.map((item) => (
      {
        value: item[valueTag],
        label: item[labelTag],
      }
    ))
  },
  classNames: (...classes) => {
    return classes.filter(Boolean).join(' ')
  }
};
