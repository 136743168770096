/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/

// import config from 'react-global-configuration';

// import { AuthContext } from "../../context/authContext";
import i18next from 'i18next';

import { Component } from 'react'

import { Button } from '../../components';

import { Buy, Location, People, TimeCircle } from "react-iconly";
class MeetingRoomCard extends Component {
  handlePickATimeButtonClick = (selectedMeetingRoom) => {
    if (this.props.onPickATimeButtonClick)
      this.props.onPickATimeButtonClick(1, selectedMeetingRoom)
  }

  render() {
    let { meetingRoom } = this.props

    return (
      <div
        key={this.props.key}
        className={`text-left flex flex-col w-full h-auto space-y-3 py-2 px-5 border border-border bg-white text-black rounded-lg overflow-hidden select-none ${this.props.className ? this.props.className : ""}`}
      >
        <h3 className="text-base font-semibold text-gray-900 mb-3">
          {meetingRoom.name}
        </h3>
        <div className='flex flex-row space-x-2'>
          <div className='basis-1/5 text-gray-500 flex space-x-2 items-center'>
            <Location set='light' size={20} /><span>Location</span>
          </div>
          <div className='flex-1 font-medium'>
            {this.props.locationName}
          </div>
        </div>
        <div className='flex flex-row space-x-2'>
          <div className='basis-1/5 text-gray-500 flex space-x-2 items-center'>
            <People set='light' size={20} /><span>Capacity</span>
          </div>
          <div className='flex-1 font-medium'>
            {meetingRoom.capacity}
          </div>
        </div>
        <div className='flex flex-row space-x-2'>
          <div className='basis-1/5 text-gray-500 flex space-x-2 items-center'>
            <Buy set='light' size={20} /><span>Price</span>
          </div>
          <div className='flex-1 font-medium'>
            <span>
              {`${meetingRoom.price} €`}
            </span>
            {' '}
            <span className='text-gray-500 text-sm'>
              ({i18next.t("per hour")})
            </span>
          </div>
        </div>
        {!this.props.noButton
          ? <div className='basis-full flex justify-end pt-3'>
            <Button
              onClick={(e) => this.handlePickATimeButtonClick(meetingRoom)}
              className="w-32 h-8 text-black bg-brand hover:bg-brand-900 font-normal text-sm rounded"
              text={<div className='flex items-center space-x-2'><TimeCircle set="light" size={16} /><span>{i18next.t("pick a time")}</span></div>}
            />
          </div>
          : ""
        }
      </div>
    )
  }
}

export default MeetingRoomCard;