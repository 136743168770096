import { Component } from 'react';

export default class ModalContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: this.props.show
    }
  }

  toggleShow = (show) => {
    if (this.props.toggleShow)
      this.props.toggleShow(show);

    this.setState({ show: show });
  }

  // need when using props for initializing state
  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      show: nextProps.show
    };
  }

  render() {
    return (
      <div className='absolute z-100 place-items-center'>
        <div id={`${this.props.id}-backdrop`} className={(this.state.show ? "flex " : "hidden ") + "bg-gray-900 bg-opacity-50 fixed inset-0 z-20"}></div>
        <div
          id={this.props.id}
          className={(this.state.show ? "flex " : "hidden ")
            + "overflow-y-auto overflow-x-hidden my-auto mx-auto fixed right-0 left-0 top-4 z-30 justify-center items-center md:inset-0 h-modal sm:h-full"
            + (this.props.className ? " " + this.props.className : "")}
          aria-hidden="true"
        >
          <div className="relative px-4 h-full md:h-auto">
            <div className="relative bg-white rounded shadow">
              <div className="flex justify-end p-2">
                <button onClick={e => this.toggleShow(false)} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded text-sm p-1.5 ml-auto inline-flex items-center" data-modal-toggle={this.props.id}>
                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                </button>
              </div>
              <div className="p-6 pt-0 text-center">
                {this.props.title && <h1 className="text-lg font-semibold text-gray-900">{this.props.title}</h1>}
                {this.props.children}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}