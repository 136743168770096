import config from 'react-global-configuration';

/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/

import i18next from 'i18next';

import { Component } from 'react'

import { StatusBadge, Img } from '..';

import { Plus } from 'react-iconly'

class AssetVerticalCard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      valid: true,
      value: this.props.value ? this.props.value : false
    }
  }

  handleChange = (value) => {
    if (parseInt(this.props.asset.available_stock, 10) >= 1) {
      this.setState({ value: value });

      // next line is needed while value of this.state.value before this.setState completed
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.value = value;

      if (this.props.onChange)
        this.props.onChange()

      this.validate(value)
    }
  }

  getValue = () => {
    let value = this.state.value;

    if (value) {
      return this.props.asset;
    }
    else
      return "";
  }

  setValue = (value, forceValidate = false) => {
    if (forceValidate) {
      if (this.validate(value))
        this.handleChange(value);
    } else {
      this.handleChange(value);
    }
  }

  init = () => {
    // if (this.props.asset.initialValue !== undefined)
    //   this.setValue(this.props.asset.initialValue)
  }

  reset = () => {
    // if (this.props.asset.emptyValue !== undefined)
    //   this.setValue(this.props.asset.emptyValue)
  }

  // function handleBlur(e) {
  //   validate()
  // }

  validate = (inputValue = null) => {
    let value = inputValue != null ? inputValue : this.state.value
    let valid = true;

    if (this.props.asset.required)
      if (value === false)
        valid = false;

    this.setState({ valid: valid });

    return valid;
  }

  render() {
    let { asset } = this.props

    return (
      <div
        key={asset.key}
        className={`group h-full relative m-3 bg-white border rounded-lg flex flex-col w-60 overflow-hidden select-none ${this.state.value ? "border-green-200 shadow-lg shadow-green-200" : "border-gray-200"}`}
      >
        <div className="absolute right-1 top-1">
          <StatusBadge
            status={asset.status}
          />
        </div>
        <div className="aspect-w-3 aspect-h-4 bg-white group-hover:opacity-75 sm:aspect-none h-64 w-64 rounded-lg select-none">
          <Img
            src={`${config.get('cdnUrl')}/${asset.file ? asset.file.filename : ""}`}
            alt={asset.name}
            className="w-full h-full object-center object-cover sm:w-full sm:h-full rounded-lg px-4 pt-4 pb-3"
          />
        </div>
        <div className="flex-1 px-4 pb-4 pt-1 space-y-2 flex flex-col">
          <h3 className="text-sm font-medium text-gray-900">
            {asset.name}
          </h3>
          <p className="text-sm text-gray-500">{asset.description}</p>
          <div className="flex-1 flex flex-row justify-between items-end">
            <div>
              <p className="text-base font-medium text-gray-900">{`${asset.price} €`}
                {' '}
                <span className='text-gray-500 text-sm'>
                  ({i18next.t("per month")})
                </span>
              </p>
            </div>
            <div
              className={`${parseInt(asset.available_stock, 10) >= 1 ? "text-brand cursor-pointer" : "text-gray-400 cursor-not-allowed"}`}
              onClick={() => this.handleChange(!this.state.value)}
            >
              <Plus size='medium' />
            </div>
          </div>
        </div>
      </div >
    )
  }
}

export default AssetVerticalCard