/* This example requires Tailwind CSS v2.0+ */
import { Component, Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'

const generals = require("../../generals")

export default class DropDown extends Component {
  constructor(props) {
    super(props)

    this.items = this.props.items
      ? this.props.items
      : this.props.field.items
        ? this.props.field.items
        : [];

    this.state = {
      selectedItem: this.props.field.value
        ? this.items
          ? this.items.filter(item => item.value === this.props.field.value)[0]
          : {}
        : {},
      valid: this.props.field.valid ? this.props.field.valid : true,
      value: this.props.field.value ? this.props.field.value : -1
    }
  }

  handleChange = (item, forceValidate = false) => {
    this.setState({
      selectedItem: item,
      value: item && item.value ? item.value : null
    })
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.selectedItem = item;
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.value = item && item.value ? item.value : null;

    if (this.props.onChange)
      this.props.onChange(this.props.field.name, item.value)

    if (forceValidate)
      this.validate(item.value)
  }

  // eslint-disable-next-line no-unused-vars
  getValue = () => {
    let value = this.state.value;

    if (this.props.field.exportLabel) {
      // eslint-disable-next-line eqeqeq
      let thisItem = this.items.filter(item => item.value == value)[0];
      return (thisItem ? thisItem.label : null);
    }

    return value;
  }

  setValue = (value, forceValidate = false, importLabel = false) => {
    let items = this.props.items
      ? this.props.items
      : this.props.field.items
        ? this.props.field.items
        : [];

    if (forceValidate) {
      if (this.validate(value))
        this.handleChange(items.filter(item => item.value === value)[0], forceValidate);
    } else {
      this.handleChange(items.filter(item => item.value === value)[0], forceValidate);
    }
  }

  init = (forceValidate = false) => {
    if (forceValidate === false)
      this.setState({ valid: true })

    if (this.props.field.initialValue !== undefined)
      this.setValue(this.props.field.initialValue, forceValidate)
  }

  reset = () => {
    // if (this.props.field.emptyValue !== undefined)
    //   this.setValue(this.props.field.emptyValue)
  }

  // function handleBlur(e) {
  //   validate()
  // }

  validate = (inputValue = null) => {
    let value = inputValue != null ? inputValue : this.state.value
    let valid = true;

    if (this.props.field.required)
      // eslint-disable-next-line eqeqeq
      if (value == -1)
        valid = false;

    this.setState({ valid: valid });

    return valid;
  }

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.field) !== JSON.stringify(this.props.field)) {
      if (nextProps.field.items)
        this.items = nextProps.field.items

      this.setState({
        selectedItem: this.items.filter(item => item.value === nextProps.field.value)[0],
        value: nextProps.field.value
      })
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.selectedItem = this.items.filter(item => item.value === nextProps.field.value)[0];
      // eslint-disable-next-line react/no-direct-mutation-state
      this.state.value = nextProps.field.value;
    }
  }

  render() {
    this.items = this.props.items
      ? this.props.items
      : this.props.field.items
        ? this.props.field.items
        : [];

    let { field } = this.props

    return (
      <div className={`align-baseline ${field.className ? field.className : ""}`}>
        {field.label &&
          <label
            htmlFor={field.id}
            className={
              `block text-sm 3xl:text-base text-gray-700 ${field.labelClassName ? field.labelClassName : ""}`
              + (this.props.disabled ? " text-gray-500" : "")
            }
          >
            {field.label}
          </label>
        }
        <Menu as="div" className={`relative text-left ${field.inputClassName ? field.inputClassName : ""}`}>
          <div>
            <Menu.Button
              className={
                `relative w-full rounded border text-left ${this.state.valid ? "border-border" : "border-red-500"} shadow-sm pl-4 pr-9 py-2 text-sm font-normal hover:bg-gray-50 focus:outline-none overflow-hidden ${field.inputClassName ? field.inputClassName : ""}`
                + (this.props.disabled ? " disabled:bg-gray-300 disabled:text-gray-500" : " bg-white text-gray-700")
              }

              disabled={this.props.disabled}
            >
              <span className='whitespace-nowrap max-w-full'>{this.state.selectedItem && this.state.selectedItem.label ? this.state.selectedItem.label : field.placeholder}</span>
              <ChevronDownIcon className="absolute inset-y-0 right-3 flex items-center my-auto h-5 w-5" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-10">
              <div className="relative py-1">
                {this.items.map(item => (
                  <Menu.Item
                    key={item.value}
                  >
                    {({ active }) => (
                      <div
                        aria-valuetext={item.value}
                        className={generals.classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm cursor-pointer'
                        )}
                        onClick={() => { this.handleChange(item, true) }}
                      >
                        {item.label}
                      </div>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu >
        {
          field.required &&
          <p className="h-3 flex items-center font-medium tracking-wide text-red-500 text-xs 3xl:text-base mt-1 ml-1">
            {!this.state.valid ? field.errorMessage : ""}
          </p>
        }
      </div >
    )
  }
}