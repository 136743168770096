import config from 'react-global-configuration';

import { AuthContext } from "../context/authContext";
import { useTranslation } from 'react-i18next';

import { useContext, useState } from "react";
// import Cookie from 'js-cookie';
import axios from 'axios'

import { SplitScreen, JustifyBetween, VerticalForm, Logo, Modal } from '../components';

import backgroundImage from '../assets/images/login background.jpeg'

const generals = require("../generals")

export default function PaymentResult(props) {
  const { accessToken, login } = useContext(AuthContext)
  const { t } = useTranslation();

  let urlObj = new URL(window.location.href);
  let result = urlObj.searchParams.get("result");
  let returnUrl = urlObj.searchParams.get("return_url");

  let paymentId = urlObj.searchParams.get("payment_id");
  let email = urlObj.searchParams.get("email");

  const [modalShow, setModalShow] = useState(false);
  const [modalText, setModalText] = useState("");

  function toggleModal(show) {
    setModalShow(show);
  }

  function onSubmit(e) {
    if (e)
      e.preventDefault();

    let fullReturnUrl = "";
    let dest = returnUrl.split("_")[0]
    let values = {};
    for (let i = 1; i < returnUrl.split("_").length; i++) {
      let key = returnUrl.split("_")[i].split("=")[0]
      let value = returnUrl.split("_")[i].split("=")[1]
      switch (key) {
        case "n":
          values["nav"] = value;
          break;

        case "t":
          values["tab"] = value;
          break;

        case "l":
          values["locationId"] = value;
          break;

        default:
      }
    }

    if (accessToken && accessToken !== "undefined" && accessToken !== "null") {
      fullReturnUrl = `${config.get('homeUrl')}/dashboard?`
        + Object.keys(values).map((key) => (`${key}=${values[key]}`)).join("&");

      generals.goTo(fullReturnUrl, 0)
    } else {
      switch (dest) {
        case "dashboard":
          fullReturnUrl = `${config.get('homeUrl')}/dashboard?`
            + Object.keys(values).map((key) => (`${key}=${values[key]}`)).join("&");

          if (paymentId) {
            let url = `${config.get('apiUrl')}/nopasslogin`;
            let data = {
              payment_id: paymentId,
              email: email
            };
            let headers = {
              'x-access-token': accessToken,
              timeout: config.get('apiCallTimeout')
            }
            axios.post(url, data, { headers })
              .then(res => {
                if (res.data.result) {
                  // const cookieAccessTokenWildCard = `orangery-app-access-token`;
                  // Cookie.set(cookieAccessTokenWildCard, res.data.access_token);
                  const callbackFunction = () => {
                    generals.goTo(fullReturnUrl, 0)
                  }
                  login(res.data.data.access_token, res.data.data.refresh_token, true, true, callbackFunction)


                  // let url = `${config.get('apiUrl')}/getplansbytoken`;
                  // let configs = {
                  //   headers: {
                  //     'x-access-token': res.data.access_token,
                  //     timeout: config.get('apiCallTimeout')
                  //   },
                  //   params: {
                  //     // page: f + 1
                  //   },
                  // }
                  // axios.get(url, configs)
                  //   .then((response) => {
                  //     const plans = response.data.data;

                  //     // if (plans && Array.isArray(plans) && plans.length > 0)
                  //     //   generals.goTo(fullReturnUrl, 0)
                  //     // else
                  //     //   generals.goTo(`${config.get('landingUrl')}/home`, 0)
                  //   })
                  //   .catch((error) => {
                  //     console.error(error);
                  //   })
                  //   .then(function () {
                  //     // always executed
                  //   });
                } else {
                  setModalText(res.data.message);
                  setModalShow(true);

                  generals.goTo(`${config.get('homeUrl')}/login`)
                }
              })
              .catch(err => {
                setModalText(err + "");
                setModalShow(true);

                generals.goTo(`${config.get('homeUrl')}/login`)
              });
          } else {
            generals.goTo(`${config.get('homeUrl')}/login`)
          }

          break;

        case "login":
          fullReturnUrl = `${config.get('homeUrl')}/login?`
            + Object.keys(values).map((key) => (`${key}=${values[key]}`)).join("&");

          generals.goTo(fullReturnUrl)

          break;

        case "locationPage":
          fullReturnUrl = `${config.get('landingUrl')}/location/${values["locationId"]}`

          generals.goTo(fullReturnUrl, 0)

          break;

        default:
          fullReturnUrl = `${config.get('homeUrl')}/login`;

          generals.goTo(fullReturnUrl, 0)
      }
    }
  }

  return (
    <>
      <Modal
        id="modal"
        text={modalText}
        show={modalShow}
        toggleModal={toggleModal}

      // modalSubmitButtonText={this.state.modalSubmitButtonText}
      // onSubmit={this.state.modalOnSubmit}
      // onClose={this.state.modalOnSubmit}
      />
      <SplitScreen
        content={
          <div className="col-span-11 md:col-span-5">
            <JustifyBetween
              className="p-6"
              elementsClassName="min-h-8"
              left=""
              right=""
            />
            <div className="py-12 mx-auto w-3/5 2xl:w-112">
              <VerticalForm
                logo={<Logo />}
                title={t("Payment Result")}
                subTitle={result === "true" ? t("Your payment has been successful.") : t("Your payment failed!")}

                fields={{}}

                submitButtonText={t("Continue")}
                submitApiUrl=""

                onSubmit={onSubmit}

                hasFile={false}
              />
            </div>
          </div>
        }

        imageSrc={backgroundImage}
        imageAlt={t("orangery Workspace")}
      />
    </>
  );
}