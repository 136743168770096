import config from 'react-global-configuration';

import { createRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { JustifyBetween, VerticalForm, Logo, Img } from '../components';

import backgroundImage from '../assets/images/login background.jpeg'

const generals = require("../generals")

// let autoConfirm = false;

export default function ConfirmEmail(props) {
  const { t } = useTranslation();

  let urlObj = new URL(window.location.href);
  let key = urlObj.searchParams.get("key");

  const formRef = createRef();

  if (!key)
    generals.goTo(`${config.get('homeUrl')}/login`, 0);

  let fields = {
  }

  function onSubmit() {
    generals.goTo(`${config.get('homeUrl')}/login`);
  }

  // if (!autoConfirm) {
  //   handleResetPasswordButtonClick(null);
  //   autoConfirm = true
  // }

  // If the second argument is an empty array, like the example above, it will behave exactly like the componentDidMount, only executing on the first rendering.
  useEffect(() => {
    formRef.current.handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="h-screen bg-white grid grid-cols-11">
        <div className="col-span-11 md:col-span-5">
          <JustifyBetween
            className="p-6"
            elementsClassName="min-h-8"
            left=""
            right=""
          />
          <div className="py-12 mx-auto w-3/5 2xl:w-112">
            <VerticalForm
              ref={el => { formRef.current = el; }}

              logo={<Logo />}
              title={t("Confirm email")}
              subTitle={t("Please wait, your email is confirming...")}
              fields={fields}

              submitButtonText={t("Click to Confirm")}
              submitButtonWaitingText={t("Confirming")}
              submitApiUrl={config.get('apiUrl') + `/confirm_email/${key}`}

              onSubmit={onSubmit}

              hasFile={false}

              noCheckToken={true}
            />
          </div>
        </div>
        <div className="hidden md:block container col-span-6 p-0 m-0">
          <Img className="object-cover min-w-full min-h-full max-h-screen" src={backgroundImage} alt={t("orangery Workspace")} />
        </div>
      </div>
    </>
  );
}