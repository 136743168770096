import config from 'react-global-configuration';

/* This example requires Tailwind CSS v2.0+ */

import { Img } from "../../components"

export default function PlanSmallCard(props) {
  const { plan } = props
  return (
    <div className="relative bg-[#E2EAFF] mx-4 p-2 w-auto h-auto rounded-lg">
      <h3 className="md:hidden text-base 2xl:text-xl font-medium text-gray-900 mb-2">
        {plan.officeplan.name}
      </h3>
      <div className="flex space-x-2 2xl:space-x-3">
        <Img
          className='2xl:flex-shrink-0 h-16 w-14 2xl:h-24 2xl:w-23 object-cover rounded-lg'
          src={plan.officeplan.file && plan.officeplan.file.filename ? `${config.get('cdnUrl')}/${plan.officeplan.file.filename}` : plan.officeplan.file ? plan.officeplan.file.src : ""}
          alt=""
        />
        <div>
          <h3 className="hidden mt-2 md:block text-base 2xl:text-xl font-medium text-gray-900 mb-2">
            {plan.officeplan.name}
          </h3>
          <p className="text-xs 2xl:text-base text-gray-500">
            {plan.officeplan.description}
          </p>
        </div>
      </div>
    </div>
  )
}
