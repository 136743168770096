/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

import config from 'react-global-configuration';
import { AuthContext } from "../../context/authContext";
import i18next from 'i18next';

import { useState, useContext } from 'react'
import axios from 'axios';
import moment from 'moment';

import {
  Modal, MyDatePicker, DropDownContainer,
  PlaceHolder, MeetingRoomCard,
  PaginationContainer,
  Button,
  SelectableBadges
} from '../../components';

import { Calendar, ChevronLeft } from "react-iconly";  // , ChevronRight

const generals = require("../../generals")

export default function BookAMeetingRoomForm(props) {
  const { accessToken, isAuth } = useContext(AuthContext)

  const [modalShow, setModalShow] = useState(false);
  const [modalText, setModalText] = useState("");
  const [modalSubmitButtonText,] = useState("");
  const [modalOnSubmit, setModalOnSubmit] = useState(() => { });

  const [waiting, setWaiting] = useState(false);

  const [pickedDate, setPickedDate] = useState(moment(new Date()).format("YYYY-MM-DD"))

  const [selectedPageIndex, setSelectedPageIndex] = useState(0)

  const [selectedMeetingRoom, setSelectedMeetingRoom] = useState(null)

  const [selectedHours, setSelectedHours] = useState([])

  const [meetingRooms, setMeetingRooms] = useState(props.meetingRooms || [])

  const [dropDownContainerShow, setDropDownContainerShow] = useState(false)

  // let myDatePickerRef = createRef();
  // let dropDownContainerRef = createRef();

  function dropDownContainerSetShow(show) {
    // dropDownContainerRef.current.setShow(show)
    setDropDownContainerShow(show)
    // eslint-disable-next-line react/no-direct-mutation-state
    // setDropDownContainerShow(show);
  }
  function onPickedDateChange(value) {
    setPickedDate(value)

    // dropDownContainerRef.current.setShow(false)
    // dropDownContainerSetShow(false)

    loadMeetingRooms(value)
  }

  function toggleModal(show) {
    setModalShow(show);
  }

  function loadMeetingRooms(pickedDate) {
    // if (!accessToken || accessToken === "undefined") {
    //   generals.goTo(`${config.get('homeUrl')}/login?dest=location&id=${props.locationId}`, 0)
    //   return false;
    // }

    const callbackFunction = () => {
      let url = `${config.get('apiUrl')}/getmeetinghours`;
      let configs = {
        headers: {
          'x-access-token': accessToken,
          timeout: config.get('apiCallTimeout')
        },
        params: {
          // page: f + 1
          location_id: props.locationId,
          date: pickedDate
        },
      }
      axios.get(url, configs)
        .then((response) => {
          let data = response.data.data;
          if (response.data.result) {
            setMeetingRooms(data)
          }
        })
        .catch((error) => {
          // setmeetingRooms([])
        })
        .then(function () {
          // always executed
        });
    }

    isAuth(callbackFunction)
  }

  function handleSubmit(e = null) {
    if (e !== null) {
      e.preventDefault();
      e.stopPropagation();
    }

    setWaiting(true)

    // if (!accessToken || accessToken === "undefined") {
    //   generals.goTo(`${config.get('homeUrl')}/login?dest=location&id=${props.locationId}`, 0)
    //   return false;
    // }

    const callbackFunction = () => {
    let url = `${config.get('apiUrl')}/bookmeeting`;

    let returnUrl = `${config.get('homeUrl')}/payment_result`;
    let data = {
      location_id: props.locationId,
      meetingroom_id: selectedMeetingRoom.id,
      url: returnUrl,
      date: pickedDate,
      hours: selectedHours
    };

    let headers = {
      'x-access-token': accessToken,
      timeout: config.get('apiCallTimeout')
    }

    axios.post(url, data, { headers })
      .then(res => {
        setWaiting(false)

        if (props.onSubmit)
          props.onSubmit(res.data.data);

        if (res.data && res.data.data && res.data.data.url && (typeof res.data.data.url === 'string' || res.data.data.url instanceof String) && res.data.data.url !== '')
          generals.goTo(res.data.data.url, 0)
        else {
          setWaiting(false)

          setModalText(res.data.message);
          setModalShow(true);

          switch (res.data.error_code) {
            case 201:
            case '201':
              // setModalSubmitButtonText("Go to dashboard")
              break;

            default:
          }

          setModalOnSubmit(() => {
            if (res.data && !res.data.result) {
              switch (res.data.error_code) {
                case 201:
                case '201':
                  if (props.onSubmitting)
                    props.onSubmitting(false);

                  // generals.goTo(`${config.get('homeUrl')}/dashboard`, 0)
                  break;

                case undefined:
                  if (props.onSubmitting)
                    props.onSubmitting(false);

                  // if (res.data.result === false && res.data.data === false)   // token is invalid
                  //   generals.goTo(`${config.get('homeUrl')}/login?dest=location&id=${props.locationId}`, 0)

                  break;

                default:
                  if (props.onSubmitting)
                    props.onSubmitting(false);
              }
            }
          })
        }
      })
      .catch(err => {
        setWaiting(false)

        setModalText(err + "");
        setModalShow(true);

        setModalOnSubmit(() => {
          let res = {
            data: {
              result: true,
              error_code: 201
            }
          }
          if (res.data && !res.data.result) {
            switch (res.data.error_code) {
              case 201:
              case '201':
                if (props.onSubmitting)
                  props.onSubmitting(false);

                generals.goTo(`${config.get('homeUrl')}/dashboard`, 0)
                break;

              case undefined:
                if (props.onSubmitting)
                  props.onSubmitting(false);

                // if (res.data.result === false && res.data.data === false)   // token is invalid
                //   generals.goTo(`${config.get('homeUrl')}/login?dest=location&id=${props.locationId}`, 0)

                break;

              default:
                if (props.onSubmitting)
                  props.onSubmitting(false);
            }
          }
        })
      })
    }

    isAuth(callbackFunction)
  }


  function onPickATimeButtonClick(pageIndex, value) {
    setSelectedPageIndex(pageIndex)
    setSelectedMeetingRoom(value)
  }

  function onSelectedHoursChange(values) {
    setSelectedHours(values)
  }

  const pages = [
    <div>
      <div className='px-4'>
        <DropDownContainer
          // ref={el => { dropDownContainerRef.current = el; }}

          show={dropDownContainerShow}
          setShow={dropDownContainerSetShow}

          icon={<Calendar set="bold" size={22} />}

          field={{
            label: "",
            id: "date",
            name: "date",
            type: "select",
            placeholder: pickedDate ? pickedDate : i18next.t("Select date"),

            valid: true,
            value: "",


            key: "date",
            trimmable: false,
            required: false,

            inputClassName: 'text-left w-72 h-12 rounded-lg pl-3 pr-1 border border-border',
            menuClassName: 'w-72 h-12 rounded-lg',
            // w-32 2xl:w-44
            className: "w-72 h-12",
          }}
        >
          <MyDatePicker
            // ref={el => { myDatePickerRef.current = el; }}

            onChange={onPickedDateChange}

            field={{
              value: pickedDate
            }}
          />
        </DropDownContainer>
      </div>
      <div className='flex flex-wrap w-full h-full'>
        {meetingRooms && Array.isArray(meetingRooms) && meetingRooms.length > 0
          ? meetingRooms.map((meetingRoom, index) => (
            <div
              key={index + moment().toString()}
              className={"basis-full sm:basis-1/2 2xl:basis-1/3 p-4"}
            >
              <MeetingRoomCard
                meetingRoom={meetingRoom}

                locationName={props.locationName}

                onPickATimeButtonClick={onPickATimeButtonClick}
              />
            </div>
          ))
          : <PlaceHolder
            text={i18next.t("No meeting room available")}
          />
        }
      </div>
    </div>,
    <div>
      {selectedPageIndex > 0 &&
        <Button
          onClick={(e) => setSelectedPageIndex(selectedPageIndex - 1)}
          className="w-8 h-8 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded text-sm p-1.5 ml-auto inline-flex items-center absolute left-2 top-2"
          text={<ChevronLeft set="light" size={22} />}
        />
      }
      <div className='px-4 text-left flex flex-wrap'>
        <div className='flex w-full flex-col space-y-3 basis-full md:basis-1/3 p-2'>
          <h3 className="text-base font-semibold">
            You select:
          </h3>
          <div className={""}>
            <MeetingRoomCard
              meetingRoom={selectedMeetingRoom}

              locationName={props.locationName}

              noButton={true}
            />
          </div>
        </div>
        <div className='flex w-full max-w-full space-y-3 flex-col basis-full md:basis-2/3 p-2 pl-10'>
          <h3 className="text-base font-semibold">
            {i18next.t("Please select your time")}
          </h3>
          <div className={"flex flex-1"}>
            {selectedMeetingRoom &&
              <SelectableBadges
                items={config.get('defaults').hours}
                disables={
                  selectedMeetingRoom.occupied_hours && Array.isArray(selectedMeetingRoom.occupied_hours)
                    ? selectedMeetingRoom.occupied_hours
                    : []
                }
                className={"flex flex-wrap flex-1 gap-6"}
                badgeClassName={"h-8 w-24 text-center align-middle font-medium text-sm mx-3"}

                onChange={onSelectedHoursChange}
              />
            }
          </div>
          <div className='basis-full mt-3 px-2 flex justify-end'>
            <Button
              onClick={handleSubmit}
              className="w-44 h-12 2xl:w-64 2xl:h-14 2xl:text-2xl text-black bg-brand hover:bg-brand-900 font-medium rounded"
              text={i18next.t("Submit")}
              waiting={waiting}
            />
          </div>
        </div>
      </div>
    </div>,
  ]

  return (<>
    <Modal
      id="modal"
      text={modalText}
      show={modalShow}
      toggleModal={toggleModal}

      modalSubmitButtonText={modalSubmitButtonText}
      onSubmit={modalOnSubmit}
      onClose={modalOnSubmit}
    />
    <PaginationContainer
      pages={pages}
      selectedPageIndex={selectedPageIndex}
    />
  </>
  )
}
