// contexts
import config from 'react-global-configuration';
import { AuthContext } from '../../context/authContext';
import { useTranslation } from 'react-i18next';

import { useContext, useState, useEffect } from 'react'
import axios from 'axios';

import { Modal } from "../../components"

export default function SubscribeEMailForm(props) {
  const { accessToken } = useContext(AuthContext)
  const { t } = useTranslation();

  const [modalShow, setModalShow] = useState(false);
  const [modalText, setModalText] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [waiting, setWaiting] = useState(false);

  function toggleModal(show) {
    setModalShow(show);
  }

  const [email, setEmail] = useState(props.email ? props.email : "");

  useEffect(() => {
    setEmail(props.email ? props.email : "")
  }, [props.email])

  function onSubscribeClick(e) {
    if (e !== null)
      e.preventDefault();

    if (email) {
      setWaiting(true)

      let data = {
        officeplan_id: props.officePlanId,
        email: email
      };
      let url = `${config.get('apiUrl')}/subscribe`;
      let headers = {
        'x-access-token': accessToken,
        timeout: config.get('apiCallTimeout')
      }
      axios.post(url, data, { headers })
        .then(res => {
          setWaiting(false)

          setEmail("")

          setModalText(res.data.message);
          setModalShow(true);
        })
        .catch(err => {
          setWaiting(false)

          setModalText(err + " ");
          setModalShow(true);
        });
    } else {
    }
  }

  function onEmailChange(e) {
    setEmail(e.target.value)
  }

  return (
    <form className="mt-4 sm:flex sm:max-w-md">
      <Modal
        id="modal"
        text={modalText}
        show={modalShow}
        toggleModal={toggleModal}
      />
      <label htmlFor="email-address" className="sr-only">
        {t("Email address")}
      </label>
      <input
        type="email"
        name="email-address"
        id="email-address"
        autoComplete="email"
        required
        className="appearance-none min-w-0 w-full bg-sixth border text-white border-gray-300 rounded-md  py-2 px-4 text-base placeholder-white focus:outline-none focus:ring-brand focus:border-brand focus:placeholder-gray-400"
        placeholder={`${t("Email")} *`}
        value={email}
        onChange={onEmailChange}
      />
      <div className="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
        <button
          type="submit"
          className="w-full flex items-center justify-center text-sixth bg-brand px-4 py-3 border border-transparent text-base font-medium rounded-md hover:bg-brand-900"
          onClick={onSubscribeClick}
        >
          {props.submitButtonText}
        </button>
      </div>
    </form>
  );
}
