import { useTranslation } from 'react-i18next';

export default function StatusBadge(props) {
  const { t } = useTranslation();

  let thisStatus = "";
  let colors = "";

  if (props.status)
    switch (props.status.toLowerCase()) {
      case "available":
      case "completed":
      case "paid":
      case "active":
      case "succeeded":
        colors = "bg-green-100 text-green-800";
        thisStatus = t(props.status.toLowerCase())

        break;

      case "draft":
      case "open":
        colors = "bg-yellow-100 text-yellow-800"

        thisStatus = t(props.status.toLowerCase())

        break;

      case "in use":
      case "failed":
      case "uncollectible":
      case "void":
        colors = "bg-red-100 text-red-800";
        thisStatus = t(props.status.toLowerCase())

        break;

      case "inactive":
        colors = "bg-gray-100 text-gray-800";
        thisStatus = t(props.status.toLowerCase())

        break;

      default:
        colors = "bg-gray-100 text-gray-800";
        thisStatus = props.status.toLowerCase()
    }
  else {
    colors = "bg-gray-100 text-gray-800";
    thisStatus = ""
  }

  return (
    <span
      className={`w-fit inline-flex items-center px-2 py-0.5 2xl:mb-3 rounded-full text-xs font-medium ${colors}`}
    >
      {thisStatus}
    </ span>
  );
}