import config from 'react-global-configuration';

import { AuthContext } from "../context/authContext";
import { withTranslation } from 'react-i18next';

import React, { Component, createRef } from 'react';
import { withRouter } from 'react-router-dom';
// import history from "../utils/history";

import { SplitScreen, CheckBox, A, JustifyBetween, VerticalForm, Logo, Back } from '../components';

import backgroundImage from '../assets/images/login background.jpeg'

const generals = require("../generals")

class Login extends Component {
  static contextType = AuthContext;

  constructor(props, context) {
    super(props);

    this.accessToken = context.accessToken;
    this.login = context.login;
    const { t } = this.props;

    let urlObj = new URL(window.location.href);
    this.dest = urlObj.searchParams.get("dest");
    this.destId = urlObj.searchParams.get("id");

    this.rememberMeRef = createRef();

    this.loginMode = {
      title: t("Login"),
      subTitle: t("Login to setup a workspace"),
      submitButtonCaption: t("Login"),
      fields: {
        email: {
          label: t("Email"),
          id: "email",
          name: "email",
          type: "email",
          autocomplete: "email",
          placeholder: "mail@website.com",
          errorMessage: t("Email is not valid"),

          valid: true,
          value: "",

          key: "email",
          trimmable: true,
          required: true,

          className: "w-full 2xl:w-112 space-y-1 2xl:space-y-3",
          inputClassName: "h-10 2xl:h-16 text-base 2xl:text-xl",
          labelClassName: "font-medium",
        },
        password: {
          label: t("Password"),
          id: "password",
          name: "password",
          type: "password",
          autocomplete: "current-password",
          placeholder: t("password"),
          errorMessage: t("Password is not valid"),

          valid: true,
          value: "",

          key: "password",
          trimmable: false,
          required: true,

          className: "w-full 2xl:w-112 space-y-1 2xl:space-y-3",
          inputClassName: "h-10 2xl:h-16 text-base 2xl:text-xl",
          labelClassName: "font-medium",
        },
        url: {
          id: "url",
          name: "url",
          type: "hidden",

          valid: true,
          value: `${config.get('homeUrl')}/confirm_email`,

          key: "url",
          trimmable: false,
          required: true,
        }
      },
      apiUrl: `${config.get('apiUrl')}/login`,
      preErrorMessage: "Error while loging up: "
    }

    this.forgotPasswordMode = {
      title: t("Reset Password"),
      subTitle: t("Enter your email to send password reset link"),
      submitButtonCaption: t("Send Link"),
      fields: {
        email: {
          label: t("Email"),
          id: "email",
          name: "email",
          type: "email",
          autocomplete: "email",
          placeholder: "mail@website.com",
          errorMessage: t("Email is not valid"),

          valid: true,
          value: "",

          key: "email",
          trimmable: true,
          required: true,

          className: "w-full 2xl:w-112 space-y-1 2xl:space-y-3",
          inputClassName: "h-10 2xl:h-16 text-base 2xl:text-xl",
          labelClassName: "font-medium",
        },
        url: {
          id: "url",
          name: "url",
          type: "hidden",

          valid: true,
          value: `${config.get('homeUrl')}/reset_password`,

          key: "url",
          trimmable: false,
          required: true,
        },
      },
      apiUrl: `${config.get('apiUrl')}/reset_password`,
      // eslint-disable-next-line no-useless-concat
      preErrorMessage: t("Error while sending reset password link") + ": "
    }

    this.pageModes = {
      login: this.loginMode,
      forgotPassword: this.forgotPasswordMode
    }

    this.state = {
      pageMode: "login",

      accessToken: this.accessToken,
    };

    // if (this.state.accessToken && this.state.accessToken !== 'undefined')
  }

  onSubmit = (data) => {
    switch (this.state.pageMode) {
      case "login":
        // window.location.protocol + "//" + window.location.host +
        // history.push({
        //   pathname: config.get('homeUrl'),
        //   // search: '?query=abc',
        //   state: { 
        //     user: user
        //   }
        // });

        const callbackFunction = () => {
          generals.goTo(`${config.get('homeUrl')}/dashboard`, 2000)
        }
        this.login(data.access_token, data.refresh_token, this.rememberMeRef.current.getValue(), true, callbackFunction)

        break;

      case "forgotPassword":
        this.setState({ pageMode: "login" });
        break;

      default:
        break;
    }
  }

  switchPageMode = (mode) => {
    this.setState({ pageMode: mode });
  }

  render() {
    const { t } = this.props;

    return (!this.accessToken
      ? <>
        {/* {this.state.redirect &&
          <Redirect
            to={{
              pathname: returnUrl,
            }}
          />
        } */}

        <SplitScreen
          content={
            <div className="col-span-11 md:col-span-5">
              <JustifyBetween
                className="p-6"
                elementsClassName='min-h-8'
                left={this.state.pageMode === "forgotPassword" &&
                  <Back onClick={(e) => this.switchPageMode("login")} />
                }
                right={
                  this.state.pageMode === "login" &&
                  <A href={`${config.get('homeUrl')}/signup`} preText={t("Don't have an account?")} text={t("Create one.")} />
                }
              />
              <div className="py-12 mx-auto w-3/5 2xl:w-112">
                <VerticalForm
                  logo={<Logo />}
                  title={this.pageModes[this.state.pageMode].title}
                  subTitle={this.pageModes[this.state.pageMode].subTitle}
                  otherFields={this.state.pageMode === "login" &&
                    <JustifyBetween
                      left={
                        <CheckBox
                          ref={(el) => (this.rememberMeRef.current = el)}

                          field={{
                            id: "remember-me",
                            name: "remember-me",
                            label: t("Remember me"),
                          }}
                        />}

                      right={
                        <div className="text-sm">
                          <A onClick={(e) => this.switchPageMode("forgotPassword")} text={t("Forgot password?")} />
                        </div>
                      }
                    />
                  }

                  fields={this.pageModes[this.state.pageMode].fields}

                  submitButtonText={this.pageModes[this.state.pageMode].submitButtonCaption}
                  submitApiUrl={this.pageModes[this.state.pageMode].apiUrl}

                  onSubmit={this.onSubmit}

                  hasFile={false}

                  noCheckToken={true}
                />
              </div>
            </div>
          }

          imageSrc={backgroundImage}
          imageAlt={t("orangery Workspace")}
        />
      </>
      : ""
    );
  }
}

export default withTranslation()(withRouter(Login));