import config from 'react-global-configuration';
import AuthContextProvider from "./context/authContext";
import { withTranslation } from 'react-i18next';
// import history from "./utils/history";


import { Component, Suspense } from 'react';
import {
  // Router,
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import {
  Login,
  SignUp,
  ResetPassword,
  ConfirmEmail,
  Dashboard,
  PaymentResult,
  Checkout,
} from './pages';
import { DefaultLoader } from './components'

class App extends Component {
  render() {
    const { t } = this.props;

    console.log(t("_welcomeMessage"));

    return (
      <Suspense fallback={<DefaultLoader />}>
        <AuthContextProvider>
          {/* Warning: <BrowserRouter> ignores the history prop. To use a custom history, use `import {Router}` instead of `import {BrowserRouter as Router}`. */}
          <Router> {/* history={history} // only workes for Router */}  {/* basename={config.get('baseUrl')} // only workes for BrowserRouter */}
            <Switch>
              <Route path={`${config.get('baseUrl')}/signup`}
                render={(props) =>
                  <SignUp {...this.props} {...props} />
                }
              />
              <Route path={`${config.get('baseUrl')}/login`}
                render={(props) =>
                  <Login {...this.props} {...props} />
                }
              />
              <Route path={`${config.get('baseUrl')}/reset_password`}
                render={(props) =>
                  <ResetPassword {...this.props} {...props} />
                }
              />
              <Route path={`${config.get('baseUrl')}/confirm_email`}
                render={(props) =>
                  <ConfirmEmail {...this.props} {...props} />
                }
              />
              <Route path={`${config.get('baseUrl')}/checkout`}
                render={(props) =>
                  <Checkout {...this.props} {...props} />
                }
              />
              <Route path={`${config.get('baseUrl')}/payment_result`}
                render={(props) =>
                  <PaymentResult {...this.props} {...props} />
                }
              />
              <Route path={`${config.get('baseUrl')}/dashboard`}
                render={(props) =>
                  <Dashboard {...this.props} {...props} />
                }
              />
              <Route exact path="/"
                render={() => {
                  return (
                    <Redirect to={`${config.get('baseUrl')}/login`} />
                  )
                }
                }
              />
              <Route exact path={`${config.get('publicUrl')}/`}
                render={() => {
                  return (
                    <Redirect to={`${config.get('baseUrl')}/login`} />
                  )
                }
                }
              />
              <Route
                render={() => {
                  return (
                    <Redirect to={`${config.get('baseUrl')}/login`} />
                  )
                }
                }
              />
            </Switch>
          </Router>
        </AuthContextProvider>
      </Suspense>
    );
  }
}

export default withTranslation()(App);