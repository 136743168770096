// import config from 'react-global-configuration';

// contexts
import { AuthContext } from '../context/authContext';

import { useContext } from 'react'
// import { Redirect } from 'react-router-dom';

import { CoworkerDashboard } from '../implementedComponents'

export default function Dashboard() {
  const { accessToken } = useContext(AuthContext)

  let urlObj = new URL(window.location.href);
  let currentNav = urlObj.searchParams.get("nav");
  let currentTab = urlObj.searchParams.get("tab");
  let selectedLocationId = urlObj.searchParams.get("locationId");

  return (accessToken
    ? <CoworkerDashboard
      currentNav={currentNav}
      currentTab={currentTab}
      selectedLocationId={selectedLocationId}
    />
    : ""
  )
}
